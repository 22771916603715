import React, { Component } from "react";
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import { Col, Row, Container, Card, CardBody } from "reactstrap";

import AddResource from "./AddResource";
import ListResource from "./ListResource";

import { deleteResource, updateResource } from "../../actions/resource";

class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddModal: false,
      resourceToUpdate: null
    };
  }

  componentWillMount() {
    // this.props.getAdminList()
  }

  toggleAddResource = (row = null) => {
    console.log(row, " from toggle Add resource");
    this.setState(prevState => ({
      showAddModal: !prevState.showAddModal,
      resourceToUpdate: row
    }));
  };

  handleDelete = row => {
    this.props.deleteResource(row._id);
  };

  // handleUpdate = (row) => {
  //     console.log("handleUPdate called");
  //     this.setState({resourceToUpdate: row});
  // }

  componentWillReceiveProps(nextProps) {}
  render() {
    if (this.state.isLoading) {
      return (
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text="Please wait!"
        />
      );
    } else {
      return (
        <React.Fragment>
          <Container className="dashboard">
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xs="12" md={12} lg={8}>
                        <h4>Blogs </h4>
                      </Col>
                      <Col xs="1" md={12} lg={4}>
                        <button
                          className="btn btn-primary btn-small float-right"
                          onClick={() => this.toggleAddResource(null)}
                        >
                          Add new Blog
                        </button>
                      </Col>
                    </Row>
                    <ListResource
                      handleDelete={this.handleDelete}
                      handleUpdate={this.handleUpdate}
                      toggleModal={this.toggleAddResource}
                    />
                    <AddResource
                      modalOpen={this.state.showAddModal}
                      closeModal={this.toggleAddResource}
                      update={this.state.resourceToUpdate}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </React.Fragment>
      );
    }
  }
}
const mapStateToProps = state => ({
  resourceProp: state.resource
});
const mapDispatchToProps = dispatch => ({
  deleteResource: resourceId => deleteResource(resourceId, dispatch),
  updateResource: resourceId => updateResource(resourceId, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Blog);
