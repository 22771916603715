import React, { PureComponent } from 'react';
import { Row, Col, Card, Container, CardBody } from 'reactstrap';
import { connect } from 'react-redux';

import AssetListing from './AssetListing';
import UpdateAsset from './UpdateAsset'

class Asset extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      showUpdateAsset: false,
      selectedAsset: {},
      // showImportTransaction: false,
      // showImportFromBank: false,
      // showSelectBank: false,
      // showBankImport: false,
      // assetSlug: props.match.params.asset,
      // // accountsLinked: [],
      // // setBankImportActive: false,
      // assetAccountDetail: {},
    };
  }

  toggleUpdateAsset = (selectedAsset) => {
    console.log("toggle called", selectedAsset)
    this.setState({ showUpdateAsset: !this.state.showUpdateAsset, selectedAsset });
  }


  render() {
    // const { assetSlug, showAddTransaction, showImportTransaction, assetAccountDetail, showBankImport } = this.state;
    // const { isShare } = this.props;
    if (this.state.isLoading) {
      // return (<LoadingOverlay active={this.state.isLoading} spinner text='Please wait!' />)
    } else {
      return (
        <React.Fragment>
          <Container className="dashboard">
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xs="12" md={12} lg={4}>
                        <h4>Assets </h4>
                      </Col>
                    </Row>

                    <AssetListing toggleUpdateAsset={this.toggleUpdateAsset} />
                    <UpdateAsset modalOpen={this.state.showUpdateAsset} toggleUpdateAsset={this.toggleUpdateAsset} asset={this.state.selectedAsset} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </React.Fragment>
      );
    }
  }
}

export default connect(null, null)(Asset);