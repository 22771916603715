import React from "react";
import { connect } from "react-redux";
import "../../components/Dashboard/Landing.css";
import LoadingOverlay from "react-loading-overlay";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import { Column } from "primereact/column";
import {
  getInitialFeedback,
  deleteSelectedFeedback,
  changeFeedbackImp
} from "../../actions/admin/cmsActions";
import "./feedback.css";
import ReplyFeedback from "./ReplyFeedback";
import Feedbackinfo from "./Feedbackinfo";
import { Col, Row, CardBody, Card, Container } from "reactstrap";
class Feedback extends React.Component {
  constructor() {
    super();
    this.state = {
      feedbacks: [],
      serverResp: "",
      feedbacksSelected: [],
      replyFeedbackModal: false,
      selectedFeedback: "",
      allFeedbackSelected: false,
      page: 0,
      first: 0,
      rows: 5,
      isLoading: true,
      Feedbackinfo: false,
      infoFeedback: ""
    };
  }
  handleOnChange = event => {
    this.setState({ [event.target.name]: event.target.value }, () => {});
  };

  componentDidMount() {
    this.props.getInitialFeedback({ pageNo: 0 });
  }

  componentWillReceiveProps(nextProps) {
    const feedbacks = nextProps.adminUser.feedbacks;
    if (feedbacks) {
      this.setState({ feedbacks, isLoading: false });
    }
  }

  selectAllFeedback = () => {
    this.setState(
      { allFeedbackSelected: !this.state.allFeedbackSelected },
      () => {
        if (this.state.allFeedbackSelected) {
          const feedbacks = [...this.state.feedbacks];
          const feedbackToSelect = [];
          feedbacks.forEach(feedback => {
            feedbackToSelect.push(feedback._id);
          });

          this.setState({
            feedbacksSelected: feedbackToSelect
          });
        } else {
          this.setState({
            feedbacksSelected: []
          });
        }
      }
    );
  };
  selectedFeedback = data => {
    console.log("sssssss", data, this.state.feedbacksSelected);
    this.setState({ allFeedbackSelected: false });
    if (this.state.feedbacksSelected.some(datum => datum === data._id)) {
      let array = [...this.state.feedbacksSelected]; // make a separate copy of the array
      let index = array.indexOf(data._id);
      if (index !== -1) {
        array.splice(index, 1);
        this.setState({ feedbacksSelected: array }, () => {
          if (
            this.state.feedbacksSelected.length === this.state.feedbacks.length
          ) {
            this.setState({ allFeedbackSelected: false });
          }
        });
      }
    } else {
      this.setState(
        { feedbacksSelected: [...this.state.feedbacksSelected, data._id] },
        () => {
          if (
            this.state.feedbacksSelected.length === this.state.feedbacks.length
          ) {
            this.setState({ allFeedbackSelected: true });
          }
        }
      );
    }
  };
  //delete selected feedbacks
  deleteSelectedFeedback = () => {
    this.props.deleteSelectedFeedback(this.state.feedbacksSelected);
    this.setState({
      feedbacksSelected: [],
      allFeedbackSelected: false
    });
  };
  action(rowData, column) {
    return (
      <div>
        <div className="btn-group" role="group" aria-label="Basic example">
          {!rowData.important && (
            <button
              type="button"
              className="btn btn-sm btn-secondary"
              tooltip="mark unfavourite"
              onClick={() => this.markImportant(rowData)}
            >
              <i className="fa fa-star" aria-hidden="true" />
            </button>
          )}
          {rowData.important && (
            <button
              type="button"
              className="btn btn-sm btn-secondary"
              onClick={() => this.markImportant(rowData)}
            >
              <i className="fa fa-star-o" aria-hidden="true" />
            </button>
          )}
          {/* <input type="checkbox" defaultChecked={this.state.feedbacksSelected.some(feedbackid => feedbackid == rowData._id)} onClick={() => this.selectedFeedback(rowData)} /> */}
          <input
            type="checkbox"
            checked={this.state.feedbacksSelected.some(
              feedbackid => feedbackid === rowData._id
            )}
            onClick={() => this.selectedFeedback(rowData)}
          />
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.toggleReplyModal(rowData)}
          >
            Reply
          </button>
          <button
            type="button"
            className="btn btn-sm btn-secondary"
            onClick={() => this.toggleFeedbackModalwithInfo(rowData)}
          >
            <i className="fa fa-eye" aria-hidden="true" />
          </button>
        </div>
      </div>
    );
  }

  markImportant = feedback => {
    this.props.changeFeedbackImp(feedback);
  };

  getNewFeedbacks = () => {
    this.props.getInitialFeedback({ pageNo: 0 });
    this.setState({ feedbacksSelected: [] });
  };
  toggleReplyModal = feedback => {
    this.setState({
      replyFeedbackModal: !this.state.replyFeedbackModal,
      selectedFeedback: feedback
    });
  };
  toggleReplyOff = () => {
    this.setState({
      replyFeedbackModal: !this.state.replyFeedbackModal,
      selectedFeedback: ""
    });
  };

  toggleFeedbackModal = () => {
    this.setState({ Feedbackinfo: !this.state.Feedbackinfo });
  };
  toggleFeedbackModalwithInfo = info => {
    this.setState({ infoFeedback: info }, () => {
      this.setState({ Feedbackinfo: !this.state.Feedbackinfo });
    });
  };

  render() {
    if (this.state.isLoading) {
      return (
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text="Please wait!"
        />
      );
    } else {
      return (
        <React.Fragment>
          <Feedbackinfo
            isActive={this.state.Feedbackinfo}
            toggleFeedbackModal={this.toggleFeedbackModal}
            feedback={this.state.infoFeedback}
          />
          <Container className="dashboard">
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xs="12" md={12} lg={4}>
                        <h4>Feedback ({this.state.feedbacks.length})</h4>
                      </Col>

                      <Col xs="1" md={12} lg={4} className="text-right">
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic example"
                        >
                          <input
                            type="checkbox"
                            className="btn btn-secondary"
                            checked={this.state.allFeedbackSelected}
                            onClick={this.selectAllFeedback}
                          />
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={this.deleteSelectedFeedback}
                          >
                            <i className="fa fa-trash-o" />
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={this.getNewFeedbacks}
                          >
                            <i className="fa fa-refresh" />
                          </button>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <DataTable
                        value={this.state.feedbacks}
                        sortMode="multiple"
                        /* paginator={true} */ rows={this.state.rows}
                        first={this.state.first}
                        onPage={e => this.setState({ first: e.first })}
                        paginatorTemplate="RowsPerPageDropdown PageLinks FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                        style={{ textAlign: "center" }}
                      >
                        <Column
                          field="name"
                          header="Full Name"
                          style={{ width: "15em" }}
                          sortable
                        />
                        <Column
                          field="subject"
                          header="Subject"
                          sortable
                          style={{ width: "10em" }}
                        />
                        <Column
                          field="createdAt"
                          header="Dated"
                          sortable
                          style={{ width: "10em" }}
                        />
                        <Column
                          body={this.action.bind(this)}
                          header="Actions"
                          style={{ textAlign: "center", width: "14em" }}
                        />
                      </DataTable>
                      <Paginator
                        first={this.state.first}
                        rows={this.state.rows}
                        totalRecords={this.state.feedbacks.length}
                        rowsPerPageOptions={[5, 10, 20, 30]}
                        onPageChange={e =>
                          this.setState({ first: e.first, rows: e.rows })
                        }
                      />
                      <ReplyFeedback
                        isActive={this.state.replyFeedbackModal}
                        toggleReplyModal={this.toggleReplyOff}
                        feedback={this.state.selectedFeedback}
                      />
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = state => ({
  auth: state.login,
  adminUser: state.adminUser
});

const mapDispatchToProps = dispatch => ({
  getInitialFeedback: data => getInitialFeedback(data, dispatch),
  deleteSelectedFeedback: data => deleteSelectedFeedback(data, dispatch),
  changeFeedbackImp: data => changeFeedbackImp(data, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Feedback);
