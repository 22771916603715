import {
  GET_PARTNER_DETAIL,
  CHANGE_PARTNER_DEAL_STATUS,
  FETCH_PARTNERS_LIST,
  GET_PARTNER_INTEGRATIONS,
  ADD_PARTNER_INTEGRATION
} from "../actionTypes";

export const getPartnerDetail = (data, dispatch) => {
  console.log("dispaaa");
  dispatch({
    type: GET_PARTNER_DETAIL,
    payload: data
  });
};

export const changeApproveStatus = (data, dispatch) => {
  console.log("dispaaa", data);
  dispatch({
    type: CHANGE_PARTNER_DEAL_STATUS,
    payload: data
  });
};

export const getPartnersList = dispatch => {
  dispatch({
    type: FETCH_PARTNERS_LIST
  });
};

export const getPartnerIntegrations = (userId, dispatch) => {
  dispatch({
    type: GET_PARTNER_INTEGRATIONS,
    payload: userId
  });
};

export const addPartnerIntegration = (data, dispatch) => {
  dispatch({
    type: ADD_PARTNER_INTEGRATION,
    payload: data
  });
};
