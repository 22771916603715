import { toast } from 'react-toastify';

const toastConfig = {
  position: "top-right",
  autoClose: 4000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  };

export default (type, message) => {
  switch(type){
    case 's':
      toast.success(message, toastConfig);
      break;
    case 'e':
      toast.error(message, toastConfig);
      break;
    default:
      toast.info(message, toastConfig);
  }
}
