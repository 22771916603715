import { 
  GET_ASSET_LOGS, 
  REVIEW_ASSET_LOG,
  SEND_ASSET_EMAIL,
} from '../actionTypes';

export const getAssetLogs = (dispatch) => {
  dispatch({
      type: GET_ASSET_LOGS,
  });
};

export const reviewAssetLog = (logID, dispatch) => {
  dispatch({
    type: REVIEW_ASSET_LOG,
    payload: logID,
  });
}

export const sendAssetEmail = (logID, dispatch) => {
  dispatch({
    type: SEND_ASSET_EMAIL,
    payload: logID
  });
}
