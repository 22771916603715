import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Col, Container, Row, CardBody, Card, Button } from 'reactstrap';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';

import holderImage from '../../images/property_placeholder.jpg';
import { getUserAssets } from '../../actions/asset';
import { getClientDetail } from '../../actions/user';
import AddAsset from '../AddAsset';

class User extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      showAddAsset: false,
    }
  }

  componentWillMount() {
    const { match, getUserAssets, getClientDetail } = this.props;

    const userId = match.params.userId;

    getUserAssets(userId);
    getClientDetail(userId)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.assetProp.getUserAssetsSuccess === true)
      this.setState({ loading: false, error: null });
    else if (nextProps.assetProp.getUserAssetsSuccess === false)
      this.setState({ loading: false, error: "Problem while loading assets" });

  }

  toggleAddAsset = (data) => {
    this.setState({ showAddAsset: !this.state.showAddAsset, userDetails: data });
  }

  getUserId = () => {
    const { match } = this.props;
    return match.params.userId;
  };

  render() {
    // console.log(this.props.assetProp.userAssets);
    const { userAssets } = this.props.assetProp;
    const { clientDetail } = this.props.userDetailProp;
    const { showAddAsset } = this.state;
    const userId = this.getUserId();
    console.log(clientDetail,"ccccccccc")
    return (
      <Container className="dashboard">
        {/*     <Row>
      <Col md={12}>
        <h3 className="page-title">Asset Title</h3>
      </Col>
    </Row> */}
        <Row>

          <Col md={12} lg={12} xl={12}>
            <Card>
              <CardBody className="profile__card">
                <div className="profile__information">
                  <div className="profile__avatar">
                    <img src={holderImage} alt="avatar" />
                  </div>
                  <div className="profile__data">
                    <p className="profile__name">{clientDetail.fullName || ""}</p>
                    <p className="profile__contact">Email: {clientDetail.email || ""}</p>
                    <p className="profile__contact">Phone: {clientDetail.phone || "NA"}</p>
                    <p className="profile__contact">Total Assets: {userAssets.length || "0"}</p>
                    <p className="profile__contact">User Since: { clientDetail.createdAt }</p>

                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* User assets listing */}
        {!this.state.loading && !this.state.error &&
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <div className="card__title">

                    <Row>
                      <Col md={8}>
                        <h5 className="bold-text">Assets</h5>
                      </Col>
                      <Col md={4}><Button color="primary" className="float-right" onClick={this.toggleAddAsset}>Add asset</Button></Col>
                    </Row>

                  </div>
                  {userAssets.length === 0 &&
                    <p className="nodata-paragraph">No Assets Added yet</p>

                  }
                  {userAssets.map((a, i) => (
                    <Link to={`/assets/${a.slug}`} key={i}>
                      <div className="panel-with-thumbnail" key={i}>
                        <div className="panel-thumbnail">
                          <div className="panel-thumbnail-wrapper">
                            <img src={a.images} alt="Asset" onError={e => {
                              e.target.src = holderImage;
                            }} />
                          </div>
                        </div>
                        <div className="panel-body">
                          <h4>{a.asset_name}</h4>
                          <p>{a.address}</p>
                        </div>
                        <ChevronRightIcon className="panel-more-info-icon" />
                      </div>
                    </Link>
                  ))}
                  <AddAsset modalOpen={showAddAsset} closeModal={this.toggleAddAsset} userId={userId} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        }
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  assetProp: state.asset,
  userDetailProp: state.userDetail
});
const mapDispatchToProps = (dispatch) => ({
  getUserAssets: (userId) => getUserAssets(userId, dispatch),
  getClientDetail: (userId) => getClientDetail(userId, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(User);