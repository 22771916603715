import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import {
    Col,
    FormGroup,
    Label,
    Input,
} from 'reactstrap';
import './UserInfoModal.css'
const customStyles = {
    content: {
        width: '30em',
        height: '25em',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
    overlay: {
        zIndex: '5'
    },
};
class AdminInfoModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            adminInfo: "",
        };
    }

    componentDidMount() {

    }
    toggleAdminInfoModal = () =>{
        this.props.toggleAdminInfoModal();
    } 

    componentWillReceiveProps(nextProps) {
        // this.setState({})
    if (nextProps.adminInfo) {
        this.setState({adminInfo: nextProps.adminInfo})
    }

    }
    render() {
        
        if (this.state.adminInfo) {
            var { email, fullName,
                 isActive,
                 phone } = this.state.adminInfo;
                // var totalAsset = this.state.adminInfo
        }
            return (

                <Modal ariaHideApp={false}
                    isOpen={this.props.isActive || false}
                    style={customStyles}
                    onClose={this.props.toggleAdminInfoModal} center
                >
                    <h4>Admin Information</h4>
                    <hr />
                    <form>
                        <FormGroup row>
                            <Label sm={2}>Email:</Label>
                            <Col sm={10}>
                                <Input type="text" name="email" value={email} disabled={true} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={2}>Name:</Label>
                            <Col sm={10}>
                                <Input type="text" name="email" value={fullName} disabled={true} />
                            </Col>
                        </FormGroup>
                        {/* <FormGroup row>
                            <Label sm={2}>Assets</Label>
                            <Col sm={10}>
                                <Input type="text" name="text" value={totalAsset} disabled={true} />
                            </Col>
                        </FormGroup> */}
                        <FormGroup row>
                            <Label sm={2}>phone</Label>
                            <Col sm={10}>
                                <Input type="text" name="email" value={phone} disabled={true} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label sm={2}>Active:</Label>
                            <Col sm={10}>
                                <Input type="text" name="email" value={isActive ? 'Yes':'No'} disabled={true} />
                            </Col>
                        </FormGroup>
   
                    </form>

                    <div className="btn-toolbar float-right" role="toolbar" style={{ marginTop: 1 + 'em' }}>
                        <div className="btn-group mr-2 text-right" role="group" >
                            <button className="btn btn-primary btn-sm"  onClick={(e) => { e.preventDefault(); this.props.toggleAdminInfoModal() }} >Cancel</button>
                        </div>
                    </div>
                </Modal>
            )
        }

}

const mapStateToProps = state => ({
    adminUser: state.adminUser
});

export default connect(mapStateToProps, null)(AdminInfoModal);