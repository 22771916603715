import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import moment from 'moment';

// import defaultImage from '../../../images/streetview.jpeg';
import { getResources } from '../../../actions/resource';
import LinesEllipsis from 'react-lines-ellipsis'
class ListResource extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddModal: false,
      resources: [],
      loading: true,
    };
  }

  componentWillMount() {
    const { getResources } = this.props;
    getResources();
  }

  
  getActions = (rowData) => {
    return( 
    <div>
      <Button type="button" icon="pi pi-eye" className="p-button-secondary" onClick={() => this.props.toggleModal(rowData)} style={{ marginRight: '5px' }}></Button>
      <Button type="button" icon="pi pi-times-circle" className="p-button-danger" onClick={() => this.props.handleDelete(rowData)}></Button>
    </div>
    )
  }
  
  showLink(rowData) {
    return <a href={rowData.url} target="_blank" rel="noopener noreferrer">View Url</a>
  }
  

  getLineElipsis = (text) => {
    return (<LinesEllipsis
      text={text}
      maxLine='3'
      ellipsis='...'
      trimRight
      basedOn='letters'
    />);
  }

  componentWillReceiveProps(nextProps) {
    if(this.state.loading && nextProps !== this.props) {
      const { resourceProp } = nextProps;
      if(resourceProp.getResourcesSuccess) {
        this.setState({ loding: false, resources: resourceProp.resources });
      } else {
        this.setState({ loading: false, error: null });
      }
    }
  }

  render() {
    const filterByApproval = this.props.approvalType;
    const resources = this.state.resources.filter((r)=> (r.type === this.props.filter && r.isPending !== filterByApproval));
    if(this.props.filter === 'webinar')
    return (
      <div className="content-section implementation">
        <DataTable value={resources} sortMode="multiple" paginator={true} rows={5}
          first={this.state.first}
          onPage={(e) => this.setState({ first: e.first })}
          style={{ textAlign: 'center' }}>
          <Column body={(body)=>this.getLineElipsis(body.title)} header="Title" />
          <Column body={(body)=>this.getLineElipsis(body.description)} header="Description" />
          <Column body={this.showLink.bind(this)} header="URL" />
          <Column body={(body) => (<img src={body.displayUrl} alt="thumbnail" height="90" width="90" />)} header="Thumbnail" />
          <Column body={(body) => {
          if(body.expiry) {
            const e = new Date(body.expiry);
            return e.toLocaleDateString("en-US") +  "\n" + moment(body.startTime).format("hh:mm") + " -" +moment(body.endTime).format("hh:mm")
          } 
          return ''
          }} field="expiry" header="Date" />
          <Column body={this.getActions} header="Actions" style={{ textAlign: 'center', width: '14em' }} />
        </DataTable>
      </div>
    );

    else if(this.props.filter === 'video')
    return (
      <div className="content-section implementation">
        <DataTable value={resources} sortMode="multiple" paginator={true} rows={5}
          first={this.state.first}
          onPage={(e) => this.setState({ first: e.first })}
          style={{ textAlign: 'center' }}>
          <Column body={(body)=>this.getLineElipsis(body.title)} header="Title"  />
          <Column body={(body)=>this.getLineElipsis(body.description)} header="Description"  />
          <Column body={this.showLink.bind(this)} header="URL" />
          <Column body={(body) => (<img src={body.displayUrl} alt="thumbnail" height="90" width="90" />)} header="Thumbnail" />
          <Column body={this.getActions} header="Actions" style={{ textAlign: 'center', width: '14em' }} />
        </DataTable>
      </div>
    );

    else
    return (
      <div className="content-section implementation">
        <DataTable value={resources} sortMode="multiple" paginator={true} rows={5}
          first={this.state.first}
          onPage={(e) => this.setState({ first: e.first })}
          style={{ textAlign: 'center' }}>
          <Column body={(body)=>this.getLineElipsis(body.title)} header="Title" />
          <Column body={(body)=>this.getLineElipsis(body.description)} header="Description" />
          <Column body={this.showLink.bind(this)} header="URL" />
          <Column body={(body) => (<img src={body.displayUrl} alt="thumbnail" height="90" width="90" />)} header="Thumbnail" />
          <Column field="owner" header="Owner" />
          <Column body={this.getActions} header="Actions" style={{ textAlign: 'center', width: '14em' }} />
        </DataTable>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  resourceProp: state.resource,
});
const mapDispatchToProps = dispatch => ({
  getResources: () => getResources({}, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ListResource);