import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import { connect } from 'react-redux';
import {
    Col,
    Row,
    Button,
    FormGroup,
    Label,
    Input,
    Form,
    FormText,
} from 'reactstrap';
import { addUser } from '../../../actions/admin/userActions';

class AddUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: {
                isValid: false,
                isTouched: false,
                value: ''
            },
            email: {
                isValid: false,
                isTouched: false,
                value: ""
            },
            phone: {
                isValid: false,
                isTouched: false,
                value: ""
            },
            password: {
                isValid: false,
                isTouched: false,
                value: ""
            },
            loading: false,
            serverResp: ''
        };
    }

    handleSubmit = (e) => {
        const { name, email, password, phone } = this.state;
        e.preventDefault();
        let isFormValid = name.isValid &&
            email.isValid;
        if (isFormValid) {
            let dataToUpdate = {
                name: name.value,
                email: email.value,
                phone: phone.value,
                password: password.value
            };

            this.props.addUser(dataToUpdate);
            this.setState({ loading: true })
        } else {
            this.setState({
                name: {
                    ...this.state.name,
                    isValid: this.state.name.isValid,
                    isTouched: true,
                },
                email: {
                    ...this.state.email,
                    isValid: this.state.email.isValid,
                    isTouched: true,
                },
                password: {
                    ...this.state.password,
                    isValid: this.state.password.isValid,
                    isTouched: true
                },
                phone: {
                    ...this.state.phone,
                    isValid: this.state.phone.isValid,
                    isTouched: true
                }
            });

        }
    }

    handleOnChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ ...this.state, [name]: { ...this.state[name], 'value': value, isTouched: true } }, () => {
            this.validateField(name, value);
        });

    }

    validateField(fieldName, value) {
        const currentFieldState = this.state[fieldName];
        switch (fieldName) {
            case 'name':
                currentFieldState.isValid = value ? value.trim().length > 0 : true;
                break;
            case 'email':
                currentFieldState.isValid = RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i).test(value);
                break;
            case 'phone':
                currentFieldState.isValid = value ? value.trim().length >= 0 : true;
                break;
            default:
                break;
        }

        this.setState({
            [fieldName]: currentFieldState,
        });
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.loading) {
            if (nextProps.adminUser.addUserSuccess) {
                this.setState({
                    loading: false,
                    name: {
                        isValid: true,
                        isTouched: false,
                        value: ''
                    },
                    email: {
                        isValid: true,
                        isTouched: false,
                        value: ""
                    },
                    phone: {
                        isValid: true,
                        isTouched: false,
                        value: ""
                    },
                    password: {
                        isValid: true,
                        isTouched: false,
                        value: ""
                    },
                });
                this.props.getUserList();
                this.props.toggleFxn();
            }
            if (!nextProps.adminUser.addUserSuccess) {
                // this.props.

                this.setState({
                    loading: false,
                    name: {
                        isValid: false,
                        isTouched: false,
                        value: ''
                    },
                    email: {
                        isValid: false,
                        isTouched: false,
                        value: ''
                    },
                    password: {
                        isValid: false,
                        isTouched: false,
                        value: ''
                    },
                    phone: {
                        isValid: false,
                        isTouched: false,
                        value: ''
                    },

                    serverResp: nextProps.adminUser.serverResp
                }, () => {
                });
            }
        }
    }
    closeModal = () =>{
        
        this.setState({
            name: {
                isValid: false,
                isTouched: false,
                value: ''
            },
            email: {
                isValid: false,
                isTouched: false,
                value: ""
            },
            password: {
                isValid: false,
                isTouched: false,
                value: ""
            },
            phone: {
                isValid: false,
                isTouched: false,
                value: ''
            },
            loading: false,
            serverResp: ''
        },()=>{
            

        })
        this.props.toggleFxn()
    }
    render() {
        const {
            email,
            phone,
            name,
            password
        } = this.state;
        return (
            <Modal open={this.props.isActive || false} onClose={this.closeModal} center>
                <h4>Add User</h4>
                <hr />
                <Form>
                    <Row form>
                        <Col md={12} xs={12}>
                            {this.state.serverResp &&
                                <div className="error-msg text-center">
                                    <p className="error-msg">{this.state.serverResp}</p>
                                </div>
                            }
                            <FormGroup>
                                <Label for="exampleState">Name:</Label>
                                <Input type="text" name="name" onChange={this.handleOnChange} value={name.value} />
                                {(name.isTouched && !name.isValid) && <FormText color="danger">Provide a Name</FormText>}
                            </FormGroup>
                        </Col>
                        <Col md={12} xs={12}>
                            <FormGroup>
                                <Label>Email:</Label>
                                <Input type="text" name="email" onChange={this.handleOnChange} value={email.value} />
                                {(email.isTouched && !email.isValid) && <FormText color="danger">Provide a valid email</FormText>}
                            </FormGroup>
                        </Col>
                        <Col md={12} xs={12}>
                            <FormGroup>
                                <Label>Phone:</Label>
                                <Input type="phone" name="phone" onChange={this.handleOnChange} value={phone.value} />
                                {(phone.isTouched && !phone.isValid) && <FormText color="danger">Provide a valid phone</FormText>}
                            </FormGroup>
                        </Col>
                        <Col md={12} xs={12}>
                            <FormGroup>
                                <Label>Password:</Label>
                                <Input type="password" name="password" onChange={this.handleOnChange} value={password.value} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Button className="float-right" onClick={this.handleSubmit} disabled={this.state.loading}>{this.state.loading ? 'Loading' : 'Add'}</Button>
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    // auth: state.login,
    asset: state.asset,
    adminUser: state.adminUser

});

const mapDispatchToProps = dispatch => {
    return {
        addUser: (data) => addUser(data, dispatch),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(AddUser);