import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from '../../actions/auth/authAction';
import { googleLogin } from '../../actions/auth/authAction';

import LoadingOverlay from 'react-loading-overlay';
import logo from '../../images/logo.png';



import './Login.css';
class Login extends Component {
    constructor(props) {

        super(props);
        this.state = {
            isLoading: false,
            email: {
                value: '',
                isValid: false,
                isTouched: false,
                error: 'Enter valid email'
            },
            password: {
                value: '',
                isValid: false,
                isTouched: false,
                error: 'Password is required'
            },
            isFormValid: false,
            serverResp: '',
        };
    }

    componentDidMount() {
        if (this.props.auth.isAuthenticated) {
            this.props.history.push('/users');
        }
    }
    componentWillReceiveProps(nextProps) {
        this.setState({ isLoading: false });
        if (nextProps.auth.isAuthenticated) {
            return this.props.history.push('/users');
        }
        //checking server error response
        if (nextProps.auth.error) {
            this.setState({ serverResp: nextProps.auth.error.message });
        }
    }
    onChange = (e) => {
        this.handleUserInput(e)
    }
    handleUserInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        let fieldState = this.state[name];

        this.setState({ [name]: { ...fieldState, value: value, isTouched: true } },
            () => { this.validateField(name, value) });
    }
    validateField(fieldName, value) {
        const currentFieldState = this.state[fieldName];
        switch (fieldName) {
            case 'email':
                currentFieldState.isValid = RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i).test(value);
                break;
            case 'password':
                currentFieldState.isValid = value.trim().length > 0;
                break;
            default:
                break;
        }
        this.setState({
            [fieldName]: currentFieldState
        }, () => { this.validateForm() });
    }

    validateForm() {
        this.setState({ isFormValid: this.state.email.isValid && this.state.password.isValid });


    }
    errorClass(error) {
        return (error.length === 0 ? '' : 'has-error');
    }

    onSubmit = (e) => {
        e.preventDefault();
        if (this.state.isFormValid) {
            this.setState({ isLoading: true });

            this.props.login({
                email: this.state.email.value,
                password: this.state.password.value
            });
        } else {
            let emailState = this.state.email;
            emailState = { ...emailState, isTouched: true }
            let passwordState = this.state.password;
            passwordState = { ...passwordState, isTouched: true }

            this.setState({ email: emailState, password: passwordState });
        }
    }


    googleResponse = (response) => {
        this.setState({ isLoading: true });
        this.props.googleLogin(response.Zi.id_token);
    }

    onFailure = (error) => {

    }

    hasError = (fieldname) => {

        return (this.state[fieldname].isTouched && !this.state[fieldname].isValid) ? { borderColor: 'red' } : {};
    }


    render() {
        return (

            <LoadingOverlay active={this.props.auth.loading} spinner text='Please wait!'>
                <div className="app">

                    <div style={{ marginTop: '10em' }}>

                        <div className="loginBox">
                            <div style={{ marginBottom: '25px' }}>

                                <img src={logo} alt="BetterCapital.US" />
                            </div>
                            <form className="loginform">

                                {this.state.serverResp &&
                                    <div className="error-msg">
                                        <p>{this.state.serverResp}</p>
                                    </div>
                                }

                                <div className="form-group">
                                    <input type="text" name="email" value={this.state.email.value} placeholder="Email" onChange={this.onChange} className={`form-control`} style={this.hasError('email')} />
                                    {this.state.email.isTouched && !this.state.email.isValid &&

                                        <div className="feedback">
                                            {this.state.email.error}
                                        </div>
                                    }
                                </div>

                                <div className="form-group">
                                    <input type="password" name="password" value={this.state.password.value} placeholder="Password" onChange={this.onChange} className={`form-control`} style={this.hasError('password')} />
                                    {this.state.password.isTouched && !this.state.password.isValid &&

                                        <div className="feedback">
                                            {this.state.password.error}
                                        </div>
                                    }
                                </div>
                                <div className="form-group">
                                    <button className="btn btn-primary btn-block" onClick={this.onSubmit}>
                                        Log in
                          </button>
                                </div>

                                <div className="login-form-bottom text-right" style={{ justifyContent: 'flex-end' }}>
                                    {/* <label className="form-check-label">
                            <input  className="form-check-input" type="checkbox" />
                            <div className="form-checkbox"></div>
                            Remember me
                        </label> */}
                                    <Link to="/forgotpassword">
                                        Forgot password
                        {/* <a   href="/forgot_password"></a> */}
                                    </Link>
                                </div>
                            </form>

                        </div>

                    </div>
                </div>
            </LoadingOverlay>


        );
    }
}

const mapStateToProps = state => ({
    auth: state.login,
});

const mapDispatchToProps = dispatch => {
    return {
        login: (userData) => login(userData, dispatch),
        googleLogin: (token) => {
            googleLogin(token, dispatch)
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login)
