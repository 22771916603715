import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik, Field } from "formik";
import * as Yup from "yup";

import { Modal } from "reactstrap";

// import { addAsset } from '../../actions/asset';
import FormField from "../../shared/components/form/FormField";
import CurrencyField from "../../shared/components/form/CurrencyField";
import renderDatePickerField from "../../shared/components/form/DatePicker";

let initialValues = {
  asset_name: "",
  purchase_price: "",
  market_rent: "",
  market_value: "",
  down_payment: "",
  purchase_date: ""
};

const validationSchema = Yup.object({
  address: Yup.string().required("Address is required")
});

class UpdateAsset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null
    };
  }

  resetForm;
  handleSubmit = values => {
    // this.setState({ loading: true, error: null }, () => {
    //   let v = values;
    //   // const addr = v.addresses[0];
    //   // v.address = addr;
    //   this.props.addAsset(v);
    // });
  };

  closeModal = () => {
    this.resetForm({});
    this.setState({ error: null });
    this.props.toggleUpdateAsset({});
  };

  render() {
    const purchase_date =
      this.props.asset && this.props.asset.purchase_date
        ? new Date(this.props.asset.purchase_date)
        : "";
    initialValues = { ...initialValues, ...this.props.asset, purchase_date };

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
        onReset={this.closeModal}
        render={props => {
          const { handleSubmit, handleReset, resetForm, errors } = props;
          const { modalOpen } = this.props;
          const { loading } = this.state;
          this.resetForm = resetForm;
          return (
            <Modal
              isOpen={modalOpen}
              className={"modal-dialog--primary modal-dialog--header"}
            >
              <div className="modal__header">
                <h4 className="bold-text  modal__title">Update Asset</h4>
              </div>
              <div className="modal__body">
                {this.state.error && (
                  <p style={{ color: "red" }}>{this.state.error}</p>
                )}
                <form onSubmit={handleSubmit} onReset={handleReset}>
                  {errors.address && <p> All fields are required </p>}

                  <div className="form__form-group">
                    <span className="form__form-group-label">Asset Name</span>
                    <div className="form__form-group-field">
                      <Field
                        name="asset_name"
                        component={FormField}
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Purchase Price
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="purchase_price"
                        component={CurrencyField}
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Purchase Date
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="purchase_date"
                        component={renderDatePickerField}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Market Rent</span>
                    <div className="form__form-group-field">
                      <Field
                        name="market_rent"
                        component={CurrencyField}
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Market Value</span>
                    <div className="form__form-group-field">
                      <Field
                        name="market_value"
                        component={CurrencyField}
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Down Payment</span>
                    <div className="form__form-group-field">
                      <Field
                        name="down_payment"
                        component={CurrencyField}
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="btn-toolbar float-right" role="toolbar">
                    <div className="btn-group mr-2 text-right" role="group">
                      <button
                        className="btn btn-danger"
                        type="reset"
                        disabled={loading}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="btn-group" role="group">
                      <button
                        className="btn btn-primary"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? "Saving" : "Save"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Modal>
          );
        }}
      />
    );
  }
}

/* const mapStateToProps = state => ({
  assetProp: state.asset,
});

const mapDispatchToProps = dispatch => ({
  addAsset: (data) => addAsset(data, dispatch),
}); */

export default connect(
  null,
  null
)(UpdateAsset);
