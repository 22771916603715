import {
  GET_ASSET_FINANCIALS_SUCCESS,
  GET_ASSET_FINANCIALS_FAILURE,

  ADD_FINANCIAL_TO_ASSET_SUCCESS,
  ADD_FINANCIAL_TO_ASSET_FAILURE,
} from '../actions/actionTypes';

const initialState = {
  financials: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ASSET_FINANCIALS_SUCCESS:
      return {
        getAssetFinancialsSuccess: true,
        financials: action.payload.financials,
      }
    case GET_ASSET_FINANCIALS_FAILURE:
      return {
        getAssetFinancialsSuccess: false,
        financials: state.financials,
      }

    case ADD_FINANCIAL_TO_ASSET_SUCCESS:
        state.financials.push(action.payload.financial);
      return {
        addFinancialSuccess: true,
        financials: state.financials,
      }
    case ADD_FINANCIAL_TO_ASSET_FAILURE:
      return {
        addFinancialSuccess: false,
        financials: state.financials,
      }

    default:
      return state;
  }
}