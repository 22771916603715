import { ADD_RESOURCE, GET_RESOURCES, UPDATE_RESOURCE, DELETE_RESOURCE, UPLOAD_IMAGE } from '../actionTypes';

export const addResource = (userData, dispatch) => {
  dispatch({
      type: ADD_RESOURCE,
      payload: userData
  });
};

export const getResources = (params, dispatch) => {
  dispatch({
    type: GET_RESOURCES,
    payload: params
});
}

export const deleteResource = (resourceId, dispatch) => {
  dispatch({
    type: DELETE_RESOURCE,
    payload: resourceId,
});
}


export const updateResource = (resource, dispatch) => {
  dispatch({
    type: UPDATE_RESOURCE,
    payload: resource,
});
}


export const uploadImage = (resource, dispatch) => {
  dispatch({
    type: UPLOAD_IMAGE,
    payload: resource,
});
}