import React, { Component } from "react";
import { Button, ButtonToolbar } from "reactstrap";
import { connect } from "react-redux";
import { Formik, Field } from "formik";
import * as Yup from "yup";

import { unmaskCurrency } from "../../../helpers";
import CurrencyField from "../../../shared/components/form/CurrencyField";
import {
  getInvestmentInfo,
  updateInvestmentInfo
} from "../../../actions/asset";

let initialValues = {
  purchaseValue: "",
  marketValue: "",
  marketRent: "",
  downPayment: ""
};

const validationSchema = Yup.object({
  purchaseValue: Yup.string().max(16, "Purchase value too large"),
  marketValue: Yup.string().max(16, "Market value too large"),
  marketRent: Yup.string().max(16, "Market rent too large"),
  downPayment: Yup.string().max(16, "Down payment too large")
});

class InvestmentInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null
    };
  }

  handleSubmit = values => {
    const { updateInvestmentInfo } = this.props;
    values.purchaseValue = unmaskCurrency(values.purchaseValue);
    values.marketRent = unmaskCurrency(values.marketRent);
    values.marketValue = unmaskCurrency(values.marketValue);
    values.downPayment = unmaskCurrency(values.downPayment);
    this.setState({ loading: true, error: null }, () => {
      values.slug = this.getSlug();
      updateInvestmentInfo(values);
    });
  };

  componentWillMount() {
    const { getInvestmentInfo } = this.props;
    const slug = this.getSlug();
    getInvestmentInfo(slug);
  }

  getSlug = () => {
    const { match } = this.props;
    return match.params.slug;
  };

  componentWillReceiveProps(nextProps) {
    const { assetProp } = nextProps;

    if (assetProp.getAssetInvestmentInfoSuccess === true) {
      this.setState({ loading: false, error: null });
    } else if (assetProp.getAssetInvestmentInfoSuccess === false) {
      this.setState({ loading: false, error: "Error while updating" });
    }

    if (assetProp.updateAssetInvestmentInfoSuccess === true) {
      this.setState({ loading: false, error: null });
    } else if (assetProp.updateAssetInvestmentInfoSuccess === false) {
      this.setState({ loading: false, error: "Error while updating" });
    }
  }

  render() {
    const { investmentInfo } = this.props.assetProp;
    initialValues = { ...initialValues, ...investmentInfo };
    const { loading } = this.state;
    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
        onReset={this.closeModal}
        render={props => {
          const { handleSubmit, handleReset } = props;
          return (
            <form
              className="form"
              onSubmit={handleSubmit}
              onReset={handleReset}
            >
              <div className="form__half">
                <div className="form__form-group">
                  <span className="form__form-group-label">Purchase Price</span>
                  <div className="form__form-group-field">
                    <Field
                      name="purchaseValue"
                      component={CurrencyField}
                      type="text"
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Market Rent</span>
                  <div className="form__form-group-field">
                    <Field
                      name="marketRent"
                      component={CurrencyField}
                      type="text"
                    />
                  </div>
                </div>
                {/*                       <div className="form__form-group">
                      <span className="form__form-group-label">Purchase Date</span>
                        <div className="form__form-group-field">
                        <Field
                            name="purchase_date"
                            component={renderDatePickerField}
                          />

                        </div>
                      </div> */}
              </div>
              <div className="form__half">
                <div className="form__form-group">
                  <span className="form__form-group-label">Market Value</span>
                  <div className="form__form-group-field">
                    <Field
                      name="marketValue"
                      component={CurrencyField}
                      type="text"
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Down Payment</span>
                  <div className="form__form-group-field">
                    <Field
                      name="downPayment"
                      component={CurrencyField}
                      type="text"
                    />
                  </div>
                </div>
                <ButtonToolbar className="form__button-toolbar">
                  <Button color="primary" type="submit" disabled={loading}>
                    {loading ? "Updating" : "Update"}
                  </Button>
                </ButtonToolbar>
              </div>
            </form>
          );
        }}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getInvestmentInfo: slug => getInvestmentInfo(slug, dispatch),
  updateInvestmentInfo: data => updateInvestmentInfo(data, dispatch)
});
const mapStateToProps = state => ({
  assetProp: state.asset
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvestmentInfo);
