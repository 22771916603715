import {
    GET_CMS,
    PUT_CMS,
    GET_INITIAL_FEEDBACK,
    DELETE_SELECTED_FEEDBACK,
    CHANGE_FEEDBACK_IMP,
    REPLY_FEEDBACK
} from "../actionTypes";

export const getCMS = (dispatch) => {
    dispatch({
        type: GET_CMS,
    })
}
export const putCMS = (content, dispatch) => {
    dispatch({
        type: PUT_CMS,
        payload: content,
    })
}
export const getInitialFeedback = (content, dispatch) => {
    dispatch({
        type: GET_INITIAL_FEEDBACK,
        payload: content,
    })
}
export const deleteSelectedFeedback = (content, dispatch) => {
    dispatch({
        type: DELETE_SELECTED_FEEDBACK,
        payload: content,
    })
}

//change feedback importance
export const changeFeedbackImp = (content, dispatch) => {
    dispatch({
        type: CHANGE_FEEDBACK_IMP,
        payload: content,
    })
}
//reply to feedback
export const replyFeedback = (content, dispatch) => {
    dispatch({
        type: REPLY_FEEDBACK,
        payload: content,
    })
}