import {
  ADD_ASSET_SUCCESS,
  ADD_ASSET_FAILURE,
  GET_ALL_ASSETS_SUCCESS,
  GET_ALL_ASSETS_FAILURE,
  GET_USER_ASSETS_FAILURE,
  GET_USER_ASSETS_SUCCESS,
  GET_ASSET_DETAIL_SUCCESS,
  GET_ASSET_DETAIL_FAILURE,
  GET_ASSET_BASIC_INFO_SUCCESS,
  GET_ASSET_INVESTMENT_INFO_FAILURE,
  GET_ASSET_INVESTMENT_INFO_SUCCESS,
  GET_ASSET_BASIC_INFO_FAILURE,
  UPDATE_ASSET_INVESTMENT_INFO_SUCCESS,
  UPDATE_ASSET_INVESTMENT_INFO_FAILURE,
  UPDATE_ASSET_BASIC_INFO_SUCCESS,
  UPDATE_ASSET_BASIC_INFO_FAILURE
} from "../actions/actionTypes";

const initialState = {
  assets: [],
  userAssets: [],
  assetDetail: {},
  investmentInfo: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    // add asset resucers
    case ADD_ASSET_SUCCESS:
      state.userAssets.unshift(action.payload.asset);
      return {
        ...state,
        // userAssets
        addAssetSuccess: true
      };
    case ADD_ASSET_FAILURE:
      return {
        ...state,
        addAssetSuccess: false,
        error: action.err.message
      };

    // all assets reducers
    case GET_ALL_ASSETS_SUCCESS:
      return {
        ...state,
        getAllAssetSuccess: false,
        assets: action.payload.assets
      };
    case GET_ALL_ASSETS_FAILURE:
      return {
        ...state,
        getAllAssetSuccess: false,
        assets: state.assets,
        error: action.err.message
      };
    case GET_USER_ASSETS_SUCCESS:
      return {
        ...state,
        getUserAssetsSuccess: true,
        userAssets: action.payload.data
      };
    case GET_USER_ASSETS_FAILURE:
      return {
        ...state,
        getUserAssetsSuccess: false,
        userAssets: state.userAssets
      };

    // asset detail reducer
    case GET_ASSET_DETAIL_SUCCESS:
      return {
        ...state,
        getAssetDetailSuccess: true,
        assetDetail: action.payload.data,
        investmentInfo: state.investmentInfo
      };
    case GET_ASSET_DETAIL_FAILURE:
      return {
        ...state,
        getAssetDetailSuccess: false,
        assetDetail: state.assetDetail,
        investmentInfo: state.investmentInfo
      };

    // basic info reducers
    case GET_ASSET_BASIC_INFO_SUCCESS:
      return {
        ...state,
        getAssetBasicInfoSuccess: true,
        basicInfo: action.payload.basicInfo,
        assetDetail: state.assetDetail
      };
    case GET_ASSET_BASIC_INFO_FAILURE:
      return {
        ...state,
        getAssetBasicInfoSuccess: false,
        basicInfo: action.payload.basicInfo,
        assetDetail: state.assetDetail
      };

    case UPDATE_ASSET_BASIC_INFO_SUCCESS:
      return {
        ...state,
        updateAssetBasicInfoSuccess: true,
        basicInfo: action.payload.basicInfo
      };

    case UPDATE_ASSET_BASIC_INFO_FAILURE:
      return {
        ...state,
        updateAssetBasicInfoSuccess: false,
        basicInfo: action.payload.basicInfo
      };

    // investment info reducers
    case GET_ASSET_INVESTMENT_INFO_SUCCESS:
      return {
        ...state,
        getAssetInvestmentInfoSuccess: true,
        investmentInfo: action.payload.investmentInfo,
        assetDetail: state.assetDetail
      };
    case GET_ASSET_INVESTMENT_INFO_FAILURE:
      return {
        ...state,
        getAssetInvestmentInfoSuccess: false,
        investmentInfo: action.payload.investmentInfo,
        assetDetail: state.assetDetail
      };

    case UPDATE_ASSET_INVESTMENT_INFO_SUCCESS:
      return {
        ...state,
        updateAssetInvestmentInfoSuccess: true,
        investmentInfo: action.payload.investmentInfo
      };

    case UPDATE_ASSET_INVESTMENT_INFO_FAILURE:
      return {
        ...state,
        updateAssetInvestmentInfoSuccess: false,
        investmentInfo: action.payload.investmentInfo
      };

    default:
      return state;
  }
}
