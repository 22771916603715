import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import FinancialListing from "./FinancialListing";
import AddFinancial from "./AddFinancial";
import { getAssetFinancials } from "../../../actions/financial";

class Financial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      showAddFinancialModal: false
    };
  }

  componentWillMount() {
    const { getAssetFinancials } = this.props;
    const slug = this.getSlug();
    getAssetFinancials(slug);
  }

  getSlug = () => {
    const { match } = this.props;
    return match.params.slug;
  };

  toggleAddFinancialModal = () => {
    this.setState(prevState => ({
      showAddFinancialModal: !prevState.showAddFinancialModal
    }));
  };

  render() {
    console.log(this.props, "proooooo");
    const { financialProp } = this.props;
    const { showAddFinancialModal } = this.state;
    const slug = this.getSlug();
    return (
      <div>
        <div className="card__title">
          <Button
            color="primary"
            className="float-right"
            onClick={this.toggleAddFinancialModal}
          >
            Add Financial
          </Button>
        </div>
        <FinancialListing financials={financialProp.financials} />
        <AddFinancial
          modalOpen={showAddFinancialModal}
          toggleModal={this.toggleAddFinancialModal}
          assetSlug={slug}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getAssetFinancials: slug => getAssetFinancials(slug, dispatch)
});

const mapStateToProps = state => ({
  financialProp: state.financial
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Financial);
