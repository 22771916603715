import React, { PureComponent } from 'react';
import DatePicker from 'react-datepicker';

class DatePickerField extends PureComponent {
  render() {
    const {
      field, form: { touched, errors, setFieldValue }, placeholder
    } = this.props;
    return (
      <div className="date-picker form__form-group-input-wrap form__form-group-input-wrap--error-above">
          <DatePicker
            className="form__form-group-datepicker form-control"
            dateFormat="dd/MM/yyyy"
            onChange={e => setFieldValue(field.name, e)}
            selected={field.value ? new Date(field.value) : null}
            placeholderText={placeholder}
          />
          {touched[field.name] &&
            errors[field.name] && <span className="form__form-group-error">{errors[field.name]}</span>}
        </div>
    );
  }
}

export default DatePickerField;
