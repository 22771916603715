import React, { Component } from "react";
import { InputGroup, InputGroupAddon, InputGroupText, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import queryString from "query-string";

class CustomSearch extends Component {
  constructor(props) {
    super(props);
    let searchText = null;

    if (props.location && props.location.search) {
      searchText = queryString.parse(props.location.search).search;
    }
    this.timeout = 0;
    this.state = {
      resetQueryParams: false,
      searchText: searchText
    };
  }

  doSearch(evt) {
    var searchText = evt.target.value;
    if (!searchText && this.state.searchText) {
      this.props.resetQueryParams();
    }

    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({ searchText: searchText }, () => {
        this.props.onSearch(searchText);
      });
    }, 300);
  }

  render() {
    const { placeholder, className } = this.props;

    return (
      <InputGroup className="input-group-seamless mb-1">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <FontAwesomeIcon icon={faSearch} className="mx-auto" />
          </InputGroupText>
        </InputGroupAddon>
        <Input
          placeholder={placeholder}
          type="search"
          className={className}
          defaultValue={this.state.searchText}
          onChange={e => this.doSearch(e)}
        />
      </InputGroup>
    );
  }
}

export default CustomSearch;
