import {
    SET_CURRENT_USER_DETAIL,
    UPDATE_USER_DETAIL_SUCCESS,
    GET_USER_DETAIL,
} from '../actions/auth/authActionTypes';

import {
    GET_CLIENT_DETAIL_SUCCESS,
    GET_CLIENT_DETAIL_FAILURE,
} from '../actions/actionTypes';

const initialState = {
    loading: false,
    clientDetail:{}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_USER_DETAIL:
            return {
                loading: true
            }
        case SET_CURRENT_USER_DETAIL:
            return {
                ...state,
                user_detail: action.payload,
                loading: false
            }
        case UPDATE_USER_DETAIL_SUCCESS:

            return {
                ...state,
                loading: false,
                user_detail: action.payload.data,
                serverResp: action.payload.message,
                userUpdateSuccess: true
            }

        case GET_CLIENT_DETAIL_SUCCESS:
            return {
                ...state,
                getClientDetailSuccess: true,
                clientDetail: action.payload.user,
            }
        case GET_CLIENT_DETAIL_FAILURE:
            return {
                ...state,
                getClientDetailSuccess: false,
                clientDetail: state.clientDetail,
            }
        default:
            return state;
    }
}