import React from "react";
import { Card, CardBody, Col } from "reactstrap";
import Collapse from "./Collapse";

const Questions = ({ questions }) => (
  <Col md={12} lg={12}>
    <Card>
      <CardBody>
        {questions
          ? questions.map((q, i) => (
              <Collapse index={i} className="with-shadow" title={q.question}>
                <p>{q.answer}</p>
              </Collapse>
            ))
          : null}
      </CardBody>
    </Card>
  </Col>
);

export default Questions;
