import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { replyFeedback } from '../../actions/admin/cmsActions';
const customStyles = {
    content: {
        width: '50em',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
    overlay: {
        zIndex: '5'
    },
};
class ReplyFeedback extends Component {

    constructor(props) {
        super(props);
        this.state = {
            subject: "",
            message: '',
            feedback: '',
            loading: false
        };
    }

    componentDidMount() {

    }

  
    handleOnChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ ...this.state, [name]: value }, () => {
            this.validateField(name, value);
        });
    }
    validateField(fieldName, value) {
        const currentFieldState = this.state[fieldName];
        switch (fieldName) {
            case 'email':
                currentFieldState.isValid = RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i).test(value);
                break;
            default:
                break;
        }
        this.setState({
            [fieldName]: currentFieldState,
        });
    }
    componentWillReceiveProps(nextProps) {
        
        if (nextProps.feedback) {
            this.setState({feedback: nextProps.feedback, })
        }
        if (this.state.loading && nextProps.adminUser.replyFeedbackSuccess) {
            
            this.setState({loading: false, feedback: '',subject:"", message:"" })
            this.props.toggleReplyModal();
        }
    }
    sendReply = () => {
        this.setState({loading: true})
        const data = {
            email: this.state.feedback.email,
            subject: this.state.subject,
            message: this.state.message,
        }
        this.props.replyFeedback(data);
    }
    render() {
        // if (this.props.feedback) {
            let { email } = this.state.feedback;
            
        // }
        return (

            <Modal ariaHideApp={false}
                isOpen={this.props.isActive || false}
                style={customStyles}
                onClose={this.props.toggleReplyModal} center
            >
                <h4>Reply Feedback to</h4>
                <hr />
                <form>
                    <div className="form-group">
                        <label>to:</label>
                        <input className="form-control" type="email" name="email" value={email} disabled={true} />
                    </div>
                    <div className="form-group">
                        <label>Subject:</label>
                        <input className="form-control" type="text" name="subject" value={this.state.subject} onChange={this.handleOnChange} />
                    </div>
                    <div className="form-group">
                        <label>Subject:</label>
                        <textarea className="form-control" type="text" name="message" value={this.state.message} onChange={this.handleOnChange} />
                    </div>

                </form>

                <div className="btn-toolbar float-right" role="toolbar" style={{ marginTop: 1 + 'em' }}>
                    <div className="btn-group mr-2 text-right" role="group" >
                        <button className="btn btn-primary btn-sm" onClick={(e) => { e.preventDefault(); this.props.toggleReplyModal() }} >Cancel</button>
                    </div>
                    <div className="btn-group" role="group" >
                        <button className="btn btn-danger btn-sm" onClick={this.sendReply}>Send</button>
                    </div>
                </div>
            </Modal>
        );
    }

}

const mapStateToProps = state => ({
    // auth: state.login,
    asset: state.asset,
    adminUser: state.adminUser
});

const mapDispatchToProps = dispatch => {
    return {
        replyFeedback: (data) => replyFeedback(data, dispatch),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(ReplyFeedback);