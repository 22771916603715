import {
  GET_ASSET_LOGS_SUCCESS,
  GET_ASSET_LOGS_FAILURE,

  REVIEW_ASSET_LOG_SUCCESS,
  REVIEW_ASSET_LOG_FAILURE,

  // SEND_ASSET_EMAIL_SUCCESS,
  // SEND_ASSET_EMAIL_FAILURE,

} from '../actions/actionTypes';

const initialState = {
  assetLogs: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ASSET_LOGS_SUCCESS:
      return {
        assetLogs: action.payload.logs,
        getAssetLogsSuccess: true,
      }
    case GET_ASSET_LOGS_FAILURE:
      return {
        getAssetLogsSuccess: false,
        assetLogs: state.assetLogs,
        error: action.err.message
      }
    case REVIEW_ASSET_LOG_SUCCESS:
      const newLogs = state.assetLogs.filter((l) => l._id.toString() !== action.payload.log._id.toString());
      return {
        assetLogs: newLogs,
        reviewAssetLogSuccess: true,
      }
    case REVIEW_ASSET_LOG_FAILURE:
      return {
        assetLogs: state.assetLogs,
        reviewAssetLogSuccess: false,
        error: action.err.message
      }

/*     case SEND_ASSET_EMAIL_SUCCESS:
      const remLogs = state.assetLogs.filter((l) => l._id.toString() !== action.payload.log._id.toString());
      return {
        assetLogs: remLogs,
        sendAssetEmailSuccess: true,
        getAssetLogsSuccess:true,
      }
    case SEND_ASSET_EMAIL_FAILURE:
      return {
        assetLogs: state.assetLogs,
        sendAssetEmailSuccess: false,
        getAssetLogsSuccess: false,
      } */

    default:
      return state;
  }
}