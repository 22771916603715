import React from 'react';
import { /* Badge, */ Table } from 'reactstrap';
import {formatCurrency} from '../../../helpers';
const getCategory = function(f){
  if(f.category === 'LEASE' && f.meta && f.meta.leaseUnitName)
    return `${f.category}(${f.meta.leaseUnitName})`;
  return `${f.category}`;
}

const FinancialListing = function ({ financials }) {
  return (
    <Table responsive className="table--bordered">
      <thead>
        <tr>
          <th>#</th>
          <th>Category</th>
          <th>Frequency</th>
          <th>Type</th>
          <th>Amount</th>
          <th>Due</th>
          <th>Contact</th>
          {/* <th></th> */}
        </tr>
      </thead>
      <tbody>
         {financials.length === 0 &&
          <tr>
            <td colSpan="4"> <p className="nodata-paragraph">No Financials added yet</p></td>
          </tr>
        }
        {financials.map((f, index) => (
          <tr key={index}>
            <td>{++index}</td>
            <td>{getCategory(f)}</td>
            <td>{f.frequency}</td>
            <td>{f.type}</td>
            <td>{formatCurrency.format(f.amount)}</td>
            <td>{(new Date(f.dueDate)).toLocaleDateString("en-US")}</td>
            <td>{f.contact || "NA"}</td>

            {/* <td>
              <Badge color="success" style={{ 'cursor': 'pointer' }}>Update</Badge> &nbsp;&nbsp;
              <Badge color="danger" style={{ 'cursor': 'pointer' }} >Delete</Badge>
            </td> */}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default FinancialListing;