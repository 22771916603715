import { put, call, takeEvery } from 'redux-saga/effects';
import { Deal } from '../api/agent';
import {
  GET_SUBMITTED_DEALS,
  GET_SUBMITTED_DEALS_SUCCESS,
  GET_SUBMITTED_DEALS_FAILURE,
  APPROVE_DEAL,
  APPROVE_DEAL_FAILURE,
  APPROVE_DEAL_SUCCESS,

  DENY_DEAL,
  DENY_DEAL_FAILURE,
  DENY_DEAL_SUCCESS,
  UPDATE_MONTHLY_DEAL,
  UPDATE_MONTHLY_DEAL_SUCCESS,
  UPDATE_MONTHLY_DEAL_FAILURE,
} from '../actions/actionTypes';

function* getSubmittedDeals(action) {
  try {
    const payload = yield call(Deal.getSubmittedDeals);
    yield put({ type: GET_SUBMITTED_DEALS_SUCCESS, payload });
  } catch (error) {
      const err = error.response.body;
      yield put({ type: GET_SUBMITTED_DEALS_FAILURE, err });
  }
}

export function* watchGetSubmittedDeals() {
  yield takeEvery(GET_SUBMITTED_DEALS, getSubmittedDeals);
}

function* approveDeal(action) {
  try {
    const payload = yield call(Deal.approveDeal, action.payload);
    yield put({ type: APPROVE_DEAL_SUCCESS, payload });
  } catch (error) {
    console.log(error);
      const err = error.response.body;
      yield put({ type: APPROVE_DEAL_FAILURE, err });
  }
}

export function* watchApproveDeal() {
  yield takeEvery(APPROVE_DEAL, approveDeal);
}

function* denyDeal(action) {
  try {
    const payload = yield call(Deal.denyDeal, action.payload);
    yield put({ type: DENY_DEAL_SUCCESS, payload });
  } catch (error) {
    console.log(error);
      const err = error.response.body;
      yield put({ type: DENY_DEAL_FAILURE, err });
  }
}

export function* watchDenyDeal() {
  yield takeEvery(DENY_DEAL, denyDeal);
}

function* updateMonthlyDeal(action) {
  try {
    const payload = yield call(Deal.updateMonthlyDeal, action.payload);
    yield put({ type: UPDATE_MONTHLY_DEAL_SUCCESS, payload });
  } catch (error) {
    const err = error.response.body;
    yield put({ type: UPDATE_MONTHLY_DEAL_FAILURE, err });
  }
}

export function* watchUpdateMonthlyDeal() {
  yield takeEvery(UPDATE_MONTHLY_DEAL, updateMonthlyDeal);
}

