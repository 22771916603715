import React from 'react';
import classNames from "classnames";

const RadioButton = ({
  field: { name, value, onChange, onBlur },
  form: { touched, errors },
  id,
  label,
  className,
  disabled,
  ...props
}) => {
  const RadioButtonClass = classNames({
    'radio-btn': true,
    disabled,
  });
  return (
    <label className={`${RadioButtonClass}${className ? ` radio-btn--${className}` : ''}`}>
      <input
        name={name}
        id={id}
        type="radio"
        value={value}
        checked={props.value === value}
        onChange={onChange}
        onBlur={onBlur}
        className={classNames("radio-btn__radio")}
        {...props}
      />
      <span className="radio-btn__radio-custom" />
      <span className="radio-btn__label">{label}</span>
    </label>
  );
};


export default RadioButton;
