import { put, call, takeEvery } from "redux-saga/effects";
import { Partner } from "../api/agent";
import {
  GET_PARTNER_DETAIL_SUCCESS,
  GET_PARTNER_DETAIL_FAILURE,
  GET_PARTNER_DETAIL,
  CHANGE_PARTNER_DEAL_STATUS,
  CHANGE_PARTNER_DEAL_STATUS_SUCCESS,
  CHANGE_PARTNER_DEAL_STATUS_FAILURE,
  FETCH_PARTNERS_LIST,
  FETCH_PARTNERS_LIST_SUCCESS,
  FETCH_PARTNERS_LIST_FAILURE,
  GET_PARTNER_INTEGRATIONS,
  GET_PARTNER_INTEGRATIONS_SUCCESS,
  GET_PARTNER_INTEGRATIONS_FAILURE,
  ADD_PARTNER_INTEGRATION,
  ADD_PARTNER_INTEGRATION_SUCCESS,
  ADD_PARTNER_INTEGRATION_FAILURE
} from "../actions/actionTypes";
// get user assets
function* getPartnerDetail(action) {
  try {
    const payload = yield call(Partner.getPartnerDetail, action.payload);
    yield put({ type: GET_PARTNER_DETAIL_SUCCESS, payload });
  } catch (error) {
    const err = error.response.body;
    yield put({ type: GET_PARTNER_DETAIL_FAILURE, err });
  }
}

export function* watchGetPartnerDetail() {
  yield takeEvery(GET_PARTNER_DETAIL, getPartnerDetail);
}

function* changeApproveStatus(action) {
  try {
    const payload = yield call(
      Partner.changePartnersApproveStatus,
      action.payload
    );
    yield put({ type: CHANGE_PARTNER_DEAL_STATUS_SUCCESS, payload });
  } catch (error) {
    const err = error.response.body;
    yield put({ type: CHANGE_PARTNER_DEAL_STATUS_FAILURE, err });
  }
}

export function* watchChangePartnersApproveStatus() {
  yield takeEvery(CHANGE_PARTNER_DEAL_STATUS, changeApproveStatus);
}

function* getPartnersList(action) {
  try {
    const payload = yield call(Partner.getPartnersList);
    yield put({ type: FETCH_PARTNERS_LIST_SUCCESS, payload });
  } catch (error) {
    const err = error.response.body;
    yield put({ type: FETCH_PARTNERS_LIST_FAILURE, err });
  }
}

export function* watchFetchPartnersList() {
  yield takeEvery(FETCH_PARTNERS_LIST, getPartnersList);
}

function* getPartnerIntegrations(action) {
  try {
    const payload = yield call(Partner.getPartnerIntegrations, action.payload);
    yield put({ type: GET_PARTNER_INTEGRATIONS_SUCCESS, payload });
  } catch (error) {
    const err = error.response.body;
    yield put({ type: GET_PARTNER_INTEGRATIONS_FAILURE, err });
  }
}

export function* watchGetPartnerIntegrations() {
  yield takeEvery(GET_PARTNER_INTEGRATIONS, getPartnerIntegrations);
}

function* addPartnerIntegration(action) {
  try {
    console.log("::222@>>>", action.payload);
    const payload = yield call(Partner.addPartnerIntegration, action.payload);
    yield put({ type: ADD_PARTNER_INTEGRATION_SUCCESS, payload });
  } catch (error) {
    const err = error.response.body;
    yield put({ type: ADD_PARTNER_INTEGRATION_FAILURE, err });
  }
}

export function* watchAddPartnerIntegration() {
  yield takeEvery(ADD_PARTNER_INTEGRATION, addPartnerIntegration);
}
