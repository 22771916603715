import { put, call, takeEvery } from "redux-saga/effects";
import { Asset, Financial } from "../api/agent";
import {
  ADD_ASSET,
  ADD_ASSET_SUCCESS,
  ADD_ASSET_FAILURE,
  GET_ALL_ASSETS_SUCCESS,
  GET_ALL_ASSETS_FAILURE,
  GET_ALL_ASSETS,
  GET_USER_ASSETS,
  GET_USER_ASSETS_SUCCESS,
  GET_USER_ASSETS_FAILURE,
  GET_ASSET_DETAIL_SUCCESS,
  GET_ASSET_DETAIL_FAILURE,
  GET_ASSET_DETAIL,
  GET_ASSET_INVESTMENT_INFO_SUCCESS,
  GET_ASSET_INVESTMENT_INFO_FAILURE,
  GET_ASSET_INVESTMENT_INFO,
  UPDATE_ASSET_INVESTMENT_INFO_SUCCESS,
  UPDATE_ASSET_INVESTMENT_INFO_FAILURE,
  UPDATE_ASSET_INVESTMENT_INFO,
  GET_ASSET_BASIC_INFO_SUCCESS,
  GET_ASSET_BASIC_INFO_FAILURE,
  GET_ASSET_BASIC_INFO,
  UPDATE_ASSET_BASIC_INFO_SUCCESS,
  UPDATE_ASSET_BASIC_INFO,
  UPDATE_ASSET_BASIC_INFO_FAILURE,
  GET_ASSET_FINANCIALS,
  GET_ASSET_FINANCIALS_FAILURE,
  GET_ASSET_FINANCIALS_SUCCESS,
  ADD_FINANCIAL_TO_ASSET,
  ADD_FINANCIAL_TO_ASSET_FAILURE,
  ADD_FINANCIAL_TO_ASSET_SUCCESS
} from "../actions/actionTypes";

//add asset
function* addAsset(action) {
  try {
    const payload = yield call(Asset.addAsset, action.payload);
    yield put({ type: ADD_ASSET_SUCCESS, payload });
  } catch (error) {
    const err = error.response.body;
    yield put({ type: ADD_ASSET_FAILURE, err });
  }
}

export function* watchAddAsset() {
  yield takeEvery(ADD_ASSET, addAsset);
}

// get all assets
function* getAllAssets(action) {
  try {
    const payload = yield call(Asset.getAllAssets);
    yield put({ type: GET_ALL_ASSETS_SUCCESS, payload });
  } catch (error) {
    const err = error.response.body;
    yield put({ type: GET_ALL_ASSETS_FAILURE, err });
  }
}

export function* watchGetAllAssets() {
  yield takeEvery(GET_ALL_ASSETS, getAllAssets);
}

// get user assets
function* getUserAssets(action) {
  try {
    const payload = yield call(Asset.getUserAssets, action.payload);
    yield put({ type: GET_USER_ASSETS_SUCCESS, payload });
  } catch (error) {
    const err = error.response.body;
    yield put({ type: GET_USER_ASSETS_FAILURE, err });
  }
}

export function* watchGetUserAssets() {
  yield takeEvery(GET_USER_ASSETS, getUserAssets);
}

// get user assets
function* getAssetDetail(action) {
  try {
    const payload = yield call(Asset.getAssetDetail, action.payload);
    console.log("payload", payload);
    yield put({ type: GET_ASSET_DETAIL_SUCCESS, payload });
  } catch (error) {
    const err = error.response.body;
    yield put({ type: GET_ASSET_DETAIL_FAILURE, err });
  }
}

export function* watchGetAssetDetail() {
  yield takeEvery(GET_ASSET_DETAIL, getAssetDetail);
}

// get asset investment info
function* getInvestmentInfo(action) {
  try {
    const payload = yield call(Asset.getInvestmentInfo, action.payload);
    yield put({ type: GET_ASSET_INVESTMENT_INFO_SUCCESS, payload });
  } catch (error) {
    const err = error.response.body;
    yield put({ type: GET_ASSET_INVESTMENT_INFO_FAILURE, err });
  }
}

export function* watchGetInvestmentInfo() {
  yield takeEvery(GET_ASSET_INVESTMENT_INFO, getInvestmentInfo);
}

// update asset investment info
function* updateInvestmentInfo(action) {
  try {
    const payload = yield call(Asset.updateInvestmentInfo, action.payload);
    yield put({ type: UPDATE_ASSET_INVESTMENT_INFO_SUCCESS, payload });
  } catch (error) {
    const err = error.response.body;
    yield put({ type: UPDATE_ASSET_INVESTMENT_INFO_FAILURE, err });
  }
}

export function* watchUpdateInvestmentInfo() {
  yield takeEvery(UPDATE_ASSET_INVESTMENT_INFO, updateInvestmentInfo);
}

// get asset basic info
function* getBasicInfo(action) {
  try {
    const payload = yield call(Asset.getBasicInfo, action.payload);
    yield put({ type: GET_ASSET_BASIC_INFO_SUCCESS, payload });
  } catch (error) {
    const err = error.response.body;
    yield put({ type: GET_ASSET_BASIC_INFO_FAILURE, err });
  }
}

export function* watchGetBasicInfo() {
  yield takeEvery(GET_ASSET_BASIC_INFO, getBasicInfo);
}

// update asset basic info
function* updateBasicInfo(action) {
  try {
    const payload = yield call(Asset.updateBasicInfo, action.payload);
    yield put({ type: UPDATE_ASSET_BASIC_INFO_SUCCESS, payload });
  } catch (error) {
    const err = error.response.body;
    yield put({ type: UPDATE_ASSET_BASIC_INFO_FAILURE, err });
  }
}

export function* watchUpdateBasicInfo() {
  yield takeEvery(UPDATE_ASSET_BASIC_INFO, updateBasicInfo);
}

//financial sagas

function* getAssetFinancials(action) {
  try {
    const payload = yield call(Financial.getAssetFinancials, action.payload);
    yield put({ type: GET_ASSET_FINANCIALS_SUCCESS, payload });
  } catch (error) {
    const err = error.response.body;
    yield put({ type: GET_ASSET_FINANCIALS_FAILURE, err });
  }
}

export function* watchGetAssetFinancials() {
  yield takeEvery(GET_ASSET_FINANCIALS, getAssetFinancials);
}

function* addFinancial(action) {
  try {
    const payload = yield call(Financial.addFinancial, action.payload);
    yield put({ type: ADD_FINANCIAL_TO_ASSET_SUCCESS, payload });
  } catch (error) {
    const err = error.response.body;
    yield put({ type: ADD_FINANCIAL_TO_ASSET_FAILURE, err });
  }
}

export function* watchGetAddFinancial() {
  yield takeEvery(ADD_FINANCIAL_TO_ASSET, addFinancial);
}
