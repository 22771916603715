import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//datatable primereact
import { Column } from "primereact/column";

import LoadingOverlay from "react-loading-overlay";
import { DataTable } from "primereact/datatable";
import {
  Col,
  Row,
  FormGroup,
  Input,
  CardBody,
  Card,
  Container
} from "reactstrap";
import {
  getUserList,
  deactivateUser,
  getUserInfo,
  deleteUser
} from "../../actions/admin/userActions";
import { getPartnersList } from "../../actions/partner";
import "../Dashboard/Users/AssetListing.css";

class Partners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      users: [],
      first: 0,
      emailInAction: "",
      status: false,
      openAddUserModel: false,
      addUserToggle: false,
      userOnSearchClear: [],
      notSearching: false,
      searchTogglesActivated: false,
      userInfoModal: false,
      userinfo: "",
      userInfoRequired: true
    };
  }

  componentWillMount() {
    const { getPartnersList } = this.props;
    // const { userId } = match.params
    getPartnersList();
  }
  componentWillReceiveProps(nextProps) {
    let { partnersList } = nextProps.partner;
    const decoded = this.props.auth.user;
    //setting user for the first time
    if (partnersList !== null) {
      this.setState({
        users: partnersList ? [...partnersList] : [],
        userOnSearchClear: partnersList ? [...partnersList] : [],
        isLoading: false,
        currentUser: decoded,
        notSearching: true
      });
    }

    //deleting user
    // if (this.state.isLoading) {
    //     this.setState({ isLoading: false })
    //     this.props.getUserList();

    //     // var o = this.state.users.findIndex((user, i) => {
    //     //     return user.email === this.state.emailInAction
    //     // }
    //     // )
    //     // this.setState({
    //     //     users: this.state.users.slice(o)
    //     // })
    // }
    if (nextProps.adminUser.userInfoSuccess && this.state.userInfoRequired) {
      this.setState({
        userinfo: nextProps.adminUser.userInfo,
        userInfoModal: !this.state.userInfoModal,
        userInfoRequired: false
      });
    }
  }
  componentWillUnmount() {
    this.setState({ userInfoRequired: false }, () => {});
  }

  //search user
  searchParticularUser = e => {
    let searchedUser = e.target.value;
    let totalUsers = this.state.userOnSearchClear;

    //resetting asset back to normal
    if (searchedUser.length < 1) {
      this.setState({
        ...this.state,
        users: totalUsers,
        notSearching: true
      });
    }
    //when there is data
    if (searchedUser.trim()) {
      this.setState(
        {
          ...this.state,
          users: [],
          searchTogglesActivated: true,
          notSearching: false
        },
        async () => {
          var totalSearchedUsers = [];
          //checking each asset
          await totalUsers.forEach((user, i) => {
            if (user.email.toLowerCase().includes(searchedUser)) {
              totalSearchedUsers.push(user);
            }
          });
          this.setState({
            ...this.state,
            users: totalSearchedUsers ? totalSearchedUsers : []
          });
        }
      );
    }
  };

  userInfo = ({ email }) => {
    this.setState({ userInfoRequired: true });
    // this.toggleUserInfoModal();
    this.props.getUserInfo(email);
  };
  // statusToggle(rowData) {
  //     return <div>
  //     { rowData.status === 'Active' && <p onClick={() => this.deactivateUser({ rowData, flag: false })}>Active</p>  }
  //     { rowData.status === 'Inactive' && <p onClick={() => this.deactivateUser({ rowData, flag: true })}>InActive</p>}
  //     </div>

  // }
  // action(rowData, column) {
  //     return <div>
  //         {/* <Button type="button" icon="pi pi-pencil" tooltip="Edit" className="p-button-primary" onClick={() => this.editUser(rowData)} ></Button> */}
  //         <Button type="button" icon="pi pi-info" className="p-button-warning" onClick={() => this.userInfo({ email: rowData.email })} ></Button>
  //         {rowData.status === 'Active' && <Button type="button" icon="pi pi-eye" className="p-button-primary" onClick={() => this.deactivateUser({ rowData, flag: false })} ></Button>}
  //         {rowData.status === 'Inactive' && <Button type="button" icon="pi pi-times-circle" className="p-button-secondary" onClick={() => this.deactivateUser({ rowData, flag: true })} ></Button>}
  //         {!rowData.isDeleted && <Button type="button" icon="pi pi-minus" tooltip="Delete" className="p-button-danger" onClick={() => this.deleteUser(rowData)} ></Button>}
  //         {rowData.isDeleted && <Button type="button" icon="pi pi-refresh" tooltip="Restore" className="p-button-success" onClick={() => this.deleteUser(rowData)} ></Button>}
  //     </div>
  // }

  getActions = rowData => {
    return (
      <div className="btn-group" role="group" aria-label="First group">
        {/* <Link className="btn btn-link" to={`/partners/${rowData._id}`}>View</Link> */}
        <Link className="btn btn-link" to={`/partners/${rowData.userId}`}>
          View
        </Link>
      </div>
    );
  };

  deactivateUser = rowData => {
    // this.handleOnClick();
    this.setState({
      userInfoRequired: false,
      emailInAction: rowData.rowData.email
    });
    this.props.deactivateUser(rowData);
    this.props.getUserList();
  };

  // ONHOLD: do not delete
  deleteUser = rowData => {
    this.setState({ emailInAction: rowData.email, isLoading: true });
    this.props.deleteUser(rowData);
    console.log(rowData);
  };

  toggleAddUserModal = () =>
    this.setState({ openAddUserModel: !this.state.openAddUserModel });
  toogleAddUser = () =>
    this.setState({ addUserToggle: !this.state.addUserToggle });

  toggleUserInfoModal = () => {
    this.setState({
      userInfoModal: !this.state.userInfoModal,
      userInfoRequired: true
    });
  };

  getUserUrl = row => {
    return <Link to={`/users/${row.userId}`}>User</Link>;
  };
  render() {
    console.log(this.state.users, "usrs");
    if (this.state.isLoading) {
      return (
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text="Please wait!"
        />
      );
    } else {
      return (
        <React.Fragment>
          {/* <UserInfoModal isActive={this.state.userInfoModal}
                        toggleUserInfoModal={this.toggleUserInfoModal}
                        userinfo={this.state.userinfo}
                    /> */}
          <Container className="dashboard">
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xs="12" md={12} lg={4}>
                        <h4>Partners List({this.state.users.length})</h4>
                      </Col>
                      <Col xs="12" md={12} lg={4}>
                        <FormGroup row>
                          <Col sm={8}>
                            <Input
                              type="text"
                              name="units"
                              placeholder="Search User's Email"
                              onChange={this.searchParticularUser}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                      {/*
                                            <Col xs="1" md={12} lg={4} className="text-right">
                                                <button className="btn btn-primary btn-small" onClick={this.toogleAddUser}>Add new user</button>
                                            </Col> */}
                    </Row>
                    {/* <AddUser isActive={this.state.addUserToggle} getUserList={this.props.getUserList} toggleFxn={this.toogleAddUser} /> */}

                    <DataTable
                      value={this.state.users}
                      sortMode="multiple"
                      paginator={true}
                      rows={5}
                      first={this.state.first}
                      onPage={e => this.setState({ first: e.first })}
                      paginatorTemplate="RowsPerPageDropdown PageLinks FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                      style={{ textAlign: "center" }}
                    >
                      <Column
                        field="name"
                        header="Full Name"
                        style={{ width: "15em" }}
                        sortable
                      />
                      <Column
                        field="email"
                        header="Email"
                        sortable
                        style={{ width: "20em" }}
                      />
                      {/* <Column field="status" header="Status" sortable /> */}
                      {/* <Column  body={this.statusToggle.bind(this)}  field="status" header="Status" sortable />  */}
                      <Column
                        field="status"
                        sortable
                        header="Status"
                        style={{ width: "5em" }}
                      />
                      {/* <Column body={this.getUserUrl} header="Visit user" /> */}
                      <Column
                        body={this.getActions}
                        header="Actions"
                        style={{ textAlign: "center", width: "14em" }}
                      />

                      {/* <Column body={this.action.bind(this)} header="Actions" style={{ textAlign: 'center' }} /> */}
                    </DataTable>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </React.Fragment>
      );
    }
  }
}
const mapStateToProps = state => ({
  auth: state.login,
  asset: state.assetList,
  adminUser: state.adminUser,
  partner: state.partner
});
const mapDispatchToProps = dispatch => {
  return {
    getUserList: () => getUserList(dispatch),
    getPartnersList: () => getPartnersList(dispatch),
    getUserInfo: email => getUserInfo(email, dispatch),
    deactivateUser: userData => deactivateUser(userData, dispatch),
    deleteUser: userData => deleteUser(userData, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Partners);
