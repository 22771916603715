import React, { Component } from "react";
import { Button, ButtonToolbar, Modal } from "reactstrap";
import { connect } from "react-redux";
import { Formik, Field } from "formik";
import * as Yup from "yup";

import renderDatePickerField from "../../../shared/components/form/DatePicker";
import RadioButton from "../../../shared/components/form/RadioButton";
import RadioButtonGroup from "../../../shared/components/form/RadioButtonGroup";
import CalendarBlankIcon from "mdi-react/CalendarBlankIcon";

import FormField from "../../../shared/components/form/FormField";
import CurrencyField from "../../../shared/components/form/CurrencyField";
import Alert from "../../../shared/components/Alert";

import { addFinancialToAsset } from "../../../actions/financial";
// import { saveAttachments } from '../../../actions/attachments/attachmentAction';
// import FileInput from './FileInput';
import { unmaskCurrency } from "../../../helpers";

const initialValues = {
  category: "TAX",
  frequency: "Yearly",
  amount: "",
  dueDate: "",
  details: "",
  contact: "",
  document: "",
  leaseUnitName: ""
};

class AddFinancial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      uploading: false,
      saving: false,
      fileError: null
    };
  }

  fomrikResetForm;

  checkAndSubmit = (values, { resetForm }) => {
    this.fomrikResetForm = resetForm;
    // const { assetSlug, /* uploadFiles */ } = this.props;
    values.amount = unmaskCurrency(values.amount);

    // if(values.document){

    //   this.setState({uploading: true, saving:false, error: null, values: values},() => {
    //     console.log('yes doc')
    //     uploadFiles({slug: assetSlug, fileName: 'attachments', files: Object.values(values.document)})
    //   });

    // } else {
    // save the financial directly
    this.setState(
      { uploading: false, saving: true, error: null, values },
      () => {
        console.log("no document");
        this.saveFinancial();
      }
    );
    // }
  };

  /*  addFinancial = (values, { resetForm }) => {
    console.log(values,"submittes");
    // return;
    this.setState({ loading: true, error: null, values: values }, () => {
      this.fomrikResetForm = resetForm;
      const { assetSlug, uploadFiles } = this.props;
      // values.slug = assetSlug;
      // if(values.category === 'LEASE')
      //   values.frequency = 'Monthly';
      uploadFiles({slug: assetSlug, fileName: 'attachments', files: Object.values(values.document)})
      // addFinancialToAsset(values);
    });
  } */

  saveFinancial = () => {
    const { assetSlug, addFinancialToAsset } = this.props;
    const { values, attachmentsToAdd } = this.state;
    console.log(this.state, "from state");
    values.slug = assetSlug;
    if (values.category === "LEASE") values.frequency = "Monthly";
    if (attachmentsToAdd) values.document = attachmentsToAdd;
    else values.document = [];
    addFinancialToAsset(values);
  };

  handleFileError = err => {
    this.setState({ fileError: err });
  };

  closeModal = () => {
    const { toggleModal } = this.props;
    if (this.fomrikResetForm) this.fomrikResetForm(initialValues);
    this.setState(
      { uploading: false, saving: false, error: null, fileError: null },
      () => {
        toggleModal({});
      }
    );
  };

  handleReset = ({ resetForm }) => {
    resetForm(initialValues);
    this.closeModal();
  };

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      const { financial } = nextProps;
      if (financial.addFinancialSuccess === true && this.state.saving) {
        this.closeModal();
      } else if (financial.addFinancialSuccess === false && this.state.saving)
        this.setState({ saving: false, error: financial.error });
    }
  }

  render() {
    const validationSchema = Yup.object({
      category: Yup.string("").required("Category is required"),
      frequency: Yup.string("").required("Frequency is required"),
      amount: Yup.string()
        .max(16, "Enter valid amount")
        .required("Amount is required"),
      dueDate: Yup.date().required("Due Date is required"),
      contact: Yup.string("Enter contact email")
        .email("Enter a valid contact email")
        .max(30, "Enter a valid contact email")
    });

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={this.checkAndSubmit}
        onReset={this.closeModal}
        render={props => {
          const {
            handleSubmit,
            handleReset,
            values,
            errors,
            touched /* setFieldValue */
          } = props;
          const { modalOpen } = this.props;
          const { error, uploading, saving, fileError } = this.state;
          const loading = uploading || saving;
          return (
            <Modal
              isOpen={modalOpen}
              className={
                "theme-light modal-dialog--primary modal-dialog--header"
              }
            >
              <form className="form" onSubmit={handleSubmit}>
                <div className="modal__header">
                  <h4 className="bold-text  modal__title">Add Financial</h4>
                </div>
                <div className="modal__body">
                  {error && (
                    <Alert color="danger" className="alert--bordered" icon>
                      <p>
                        <span className="bold-text">Error! </span>
                        {error}
                      </p>
                    </Alert>
                  )}

                  <RadioButtonGroup
                    label={"Category"}
                    value={values.category}
                    error={errors.category}
                    touched={touched.category}
                  >
                    <div className="form__form-group-field">
                      <Field
                        id="tax"
                        name="category"
                        component={RadioButton}
                        label="Tax"
                        value="TAX"
                      />
                      <Field
                        id="insurance"
                        name="category"
                        component={RadioButton}
                        label="Insurance"
                        value="INSURANCE"
                      />
                      <Field
                        id="Mortgage"
                        name="category"
                        component={RadioButton}
                        label="Mortgage"
                        value="MORTGAGE"
                      />
                      <Field
                        id="Lease"
                        name="category"
                        component={RadioButton}
                        label="Lease"
                        value="LEASE"
                      />
                      <Field
                        id="HOA"
                        name="category"
                        component={RadioButton}
                        label="HOA"
                        value="HOA"
                      />
                      {/* <Field id="other" name="category" component={RadioButton}
                        label="Other" value="OTHER" /> */}
                      <Field
                        id="Deposit"
                        name="category"
                        component={RadioButton}
                        label="Deposit"
                        value="DEPOSIT"
                      />
                    </div>
                  </RadioButtonGroup>
                  {values.category !== "LEASE" && (
                    <RadioButtonGroup
                      label={"Frequency"}
                      value={values.frequency}
                      error={errors.frequency}
                      touched={touched.frequency}
                    >
                      <div className="form__form-group-field">
                        <Field
                          id="Yearly"
                          name="frequency"
                          component={RadioButton}
                          label="Yearly"
                          value="Yearly"
                        />
                        <Field
                          id="half-yearly"
                          name="frequency"
                          component={RadioButton}
                          label="Half-Yearly"
                          value="Half-Yearly"
                        />
                        <Field
                          id="quarterly"
                          name="frequency"
                          component={RadioButton}
                          label="Quarterly"
                          value="Quarterly"
                        />
                        <Field
                          id="monthly"
                          name="frequency"
                          component={RadioButton}
                          label="Monthly"
                          value="Monthly"
                        />
                      </div>
                    </RadioButtonGroup>
                  )}
                  {/* <RadioButtonGroup label={"Type"}
                    value={values.type}
                    error={errors.type}
                    touched={touched.type}>
                    <div className="form__form-group-field">
                      <Field id="INCOME" name="type" component={RadioButton}
                        label="Income" value="INCOME" />
                      <Field id="EXPENSE" name="type" component={RadioButton}
                        label="Expense" value="EXPENSE" />
                    </div>
                  </RadioButtonGroup> */}
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {values.category === "LEASE"
                        ? "Lease End Date"
                        : "Due Date"}
                    </span>
                    <div className="form__form-group-field">
                      <Field
                        name="dueDate"
                        component={renderDatePickerField}
                        placeholder="Select Date"
                      />
                      <div className="form__form-group-icon">
                        <CalendarBlankIcon />
                      </div>
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Amount</span>
                    <div className="form__form-group-field">
                      <Field
                        name="amount"
                        component={CurrencyField}
                        type="text"
                        placeholder="Amount"
                      />
                    </div>
                  </div>
                  {values.category === "LEASE" && (
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        Lease Unit Name
                      </span>
                      <div className="form__form-group-field">
                        <Field
                          name="leaseUnitName"
                          component={FormField}
                          type="text"
                          placeholder="Lease unit Name"
                        />
                      </div>
                    </div>
                  )}
                  <div className="form__form-group">
                    <span className="form__form-group-label">Contact</span>
                    <div className="form__form-group-field">
                      <Field
                        name="contact"
                        component={FormField}
                        type="email"
                        placeholder="Contact Email"
                      />
                    </div>
                  </div>
                  {/*                   <div className="form__form-group">
                    <span className="form__form-group-label">Attachment</span>
                    <div className="form__form-group-field">
                        <FileInput
                        onSuccess={(files)=>{
                          this.setState({fileError: null})
                          const newValues = { ...values };
                          newValues['document'] = files;
                          setValues(newValues);
                        }}
                        onError={this.handleFileError}
                        extensions={['jpg','png','jpeg','csv','docx', 'pdf', 'doc', 'xlxs']}
                        />
                    </div>
                    {
                      fileError &&
                        <p style={{fontSize:'12px', color:'red'}}>{fileError}</p>
                    }
                  </div> */}
                </div>
                <ButtonToolbar className="modal__footer">
                  <Button
                    type="button"
                    onClick={handleReset}
                    disabled={loading}
                  >
                    Cancel
                  </Button>{" "}
                  <Button
                    outline={false}
                    color={"primary"}
                    type="submit"
                    disabled={loading || fileError}
                  >
                    {loading ? "Saving" : "Save"}
                  </Button>
                </ButtonToolbar>
              </form>
            </Modal>
          );
        }}
      />
    );
  }
}

const mapStateToProps = state => ({
  financial: state.financial
  // attachments: state.attachments,
});

const mapDispatchToProps = dispatch => ({
  addFinancialToAsset: data => addFinancialToAsset(data, dispatch)
  // uploadFiles: (files) => saveAttachments(files, dispatch),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddFinancial);
