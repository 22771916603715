import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from './containers/Layout/index';
import MainWrapper from './MainWrapper';
import Activateuser from './components/Activation/activation';
import NotFound from './components/NotFound/NotFound';
import Login from './components/Login/Login';
import Users from './views/Dashboard/Users/Users';
import Admins from './views/Dashboard/Users/Admins';
import Settings from './views/Settings/Settings';
import Resource from './views/Resource';
import Blog from './views/Blogs';
import Video from './views/Videos';
import Webinar from './views/Webinars';


import User from './views/User';
import ControlledEditor from './views/CMS/addCMS';
// import Mailbox from '../Mailbox/Mailbox';
import Feedback from './views/StaticPages/Feedback';
import AssetLog from './views/AssetLog';
import SubmittedDeals from './views/SubmittedDeals';

import Assets from './views/Assets';
import Asset from './views/Asset';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import Partners from './views/Partners';
import PartnerInfo from './views/Partners/PartnerInfo';
// routes config

const Pages = () => (
  <Switch>
    <Route exact path="/admins" component={Admins} />
    <Route exact path="/cms" component={ControlledEditor} />
    <Route exact path="/resources" component={Resource} />
    <Route exact path="/submitted-deals" component={SubmittedDeals} />

    <Route exact path="/blogs" component={Blog} />
    <Route exact path="/videos" component={Video} />
    <Route exact path="/webinars" component={Webinar} />

    <Route exact path="/feedbacks" component={Feedback} />
    <Route exact path="/asset-logs" component={AssetLog} />
    <Route exact path="/partners" component={Partners} />
    <Route exact path="/partners/:userId" component={PartnerInfo} />
    <Route exact path="/assets/:slug" component={Asset} />
    <Route exact path="/assets" component={Assets} />
    {/* <Route path="/users/:userId/:slug" component={Asset}/> */}
    <Route exact path="/users/:userId" component={User}/>
    <Route exact path="/users" component={Users} />
    <Route exact path="/" component={Users} />
    <Route exact path="/settings" component={Settings} />
    <Route path="*" component={NotFound}/>   
  </Switch>
);

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/" component={Pages} />
    </div>
  </div>
);

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/activateuser" component={Activateuser} />
        <Route exact path="/resetpassword" component={ResetPassword} />
        <Route exact path="/forgotpassword" component={ForgotPassword}/>
        <Route path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;


