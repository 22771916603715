import React, { Component } from "react";
import { connect } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import LoadingOverlay from "react-loading-overlay";
import {
  Col,
  Row,
  FormGroup,
  Input,
  Container,
  Card,
  CardBody
} from "reactstrap";
import {
  getAdminList,
  deactivateAdmin
} from "../../../actions/admin/userActions";
import "./AssetListing.css";
import AddAdmin from "./AddAdmin";
import AdminInfoModal from "./AdminInfoModal";

class Admins extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      users: [],
      first: 0,
      emailInAction: "",
      status: false,
      openAddAdminModel: false,
      addAdminToggle: false,
      userOnSearchClear: [],
      notSearching: false,
      searchTogglesActivated: false,
      adminInfo: ""
    };
  }

  componentWillMount() {
    this.props.getAdminList();
  }
  editAdmin = adminInfo => {
    this.setState({ adminInfoModal: true, adminInfo: adminInfo });
  };

  toggleAdminInfoModal = () => {
    this.setState({ adminInfoModal: !this.state.adminInfoModal });
  };
  //search user
  searchParticularUser = e => {
    let searchedUser = e.target.value;
    let totalUsers = this.state.userOnSearchClear;

    //resetting asset back to normal
    if (searchedUser.length < 1) {
      this.setState({
        ...this.state,
        users: totalUsers,
        notSearching: true
      });
    }
    //when there is data
    if (searchedUser.trim()) {
      this.setState(
        {
          ...this.state,
          users: [],
          searchTogglesActivated: true,
          notSearching: false
        },
        async () => {
          var totalSearchedUsers = [];
          //checking each asset
          await totalUsers.forEach((user, i) => {
            if (user.email.toLowerCase().includes(searchedUser)) {
              totalSearchedUsers.push(user);
            }
          });
          this.setState({
            ...this.state,
            users: totalSearchedUsers ? totalSearchedUsers : []
          });
        }
      );
    }
  };
  action(rowData, column) {
    return (
      <div>
        <Button
          type="button"
          icon="pi pi-pencil"
          className="p-button-primary"
          onClick={() => this.editAdmin(rowData)}
        />
        {rowData.status === "Active" && (
          <Button
            type="button"
            icon="pi pi-eye"
            className="p-button-secondary"
            onClick={() => this.deactivateUser({ rowData, flag: false })}
          />
        )}
        {rowData.status === "Inactive" && (
          <Button
            type="button"
            icon="pi pi-times-circle"
            className="p-button-warning"
            onClick={() => this.deactivateUser({ rowData, flag: true })}
          />
        )}
        {/* <Button type="button" icon="pi pi-minus" pTooltip="Delete" className="p-button-danger" onClick={() => this.deleteUser(rowData)} ></Button> */}
      </div>
    );
  }
  deactivateUser = rowData => {
    this.setState({ emailInAction: rowData.rowData.email });
    this.props.deactivateAdmin(rowData);
    this.props.getAdminList();
  };
  // deleteUser = (rowData) => {
  //     this.setState({ emailInAction: rowData.email })
  //     this.props.deleteUser(rowData);
  // }
  componentWillReceiveProps(nextProps) {
    let { adminList, deleteSuccess } = nextProps.adminUser;

    if (adminList) {
      this.setState(
        {
          users: adminList,
          userOnSearchClear: adminList ? [...adminList] : [],
          isLoading: false,
          notSearching: true
        },
        () => {}
      );
    }
    if (deleteSuccess) {
      var o = this.state.users.findIndex(
        (user, i) => {
          return user.email === this.state.emailInAction;
        }
        // else return false
      );

      this.setState({ users: this.state.users.slice(o) });
    }
  }

  toggleAddUserModal = () =>
    this.setState({ openAddAdminModel: !this.state.openAddAdminModel });
  toogleAddAdmin = () =>
    this.setState({ addAdminToggle: !this.state.addAdminToggle });
  // openAddAdminModel = () => {
  //     return (
  //     )

  // }

  render() {
    if (this.state.isLoading) {
      return (
        <LoadingOverlay
          active={this.state.isLoading}
          spinner
          text="Please wait!"
        />
      );
    } else {
      return (
        <React.Fragment>
          <AdminInfoModal
            isActive={this.state.adminInfoModal}
            toggleAdminInfoModal={this.toggleAdminInfoModal}
            adminInfo={this.state.adminInfo}
          />
          <AddAdmin
            isActive={this.state.addAdminToggle}
            getAdminList={this.props.getAdminList}
            toggleFxn={this.toogleAddAdmin}
          />
          <Container className="dashboard">
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody>
                    {/* <div className="card__title">
                                            <h5 className="bold-text">Admins</h5>
                                        </div> */}
                    <Row>
                      <Col xs="12" md={12} lg={4}>
                        <h4>Admins </h4>
                      </Col>
                      <Col xs="12" md={12} lg={4}>
                        <FormGroup row>
                          <Col sm={7}>
                            <Input
                              type="text"
                              name="units"
                              placeholder="Search User"
                              onChange={this.searchParticularUser}
                            />
                          </Col>
                        </FormGroup>
                      </Col>

                      <Col xs="1" md={12} lg={4} className="text-right">
                        <button
                          className="btn btn-primary btn-small"
                          onClick={this.toogleAddAdmin}
                        >
                          Add new Admin
                        </button>
                      </Col>
                    </Row>
                    <DataTable
                      value={this.state.users}
                      sortMode="multiple"
                      paginator={true}
                      rows={5}
                      first={this.state.first}
                      onPage={e => this.setState({ first: e.first })}
                      style={{ textAlign: "center" }}
                    >
                      {/* {dynamicColumns} */}
                      <Column field="fullName" header="Full Name" sortable />
                      <Column
                        field="email"
                        header="Email"
                        sortable
                        style={{ width: "20em" }}
                      />
                      <Column field="status" header="Status" />
                      {/* <Column field="totalAssets" header="Total Asset" /> */}
                      <Column field="phone" header="Phone" />
                      {/* <Column field="isDeleted" header="Deleted?" /> */}
                      <Column
                        body={this.action.bind(this)}
                        header="Actions"
                        style={{ textAlign: "center", width: "14em" }}
                      />
                    </DataTable>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </React.Fragment>
      );
    }
  }
}
const mapStateToProps = state => ({
  auth: state.login,
  asset: state.assetList,
  adminUser: state.adminUser
});
const mapDispatchToProps = dispatch => {
  return {
    getAdminList: () => getAdminList(dispatch),
    deactivateAdmin: userData => deactivateAdmin(userData, dispatch)
    // deleteUser: (userData) => deleteUser(userData, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Admins);
