import React, { Component } from "react";
import { connect } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

import { getResources } from "../../../actions/resource";
import LinesEllipsis from "react-lines-ellipsis";
class ListResource extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddModal: false,
      resources: [],
      loading: true
    };
  }

  componentWillMount() {
    const { getResources } = this.props;
    getResources();
  }

  getActions = rowData => {
    return (
      <div>
        <Button
          type="button"
          icon="pi pi-eye"
          className="p-button-secondary"
          onClick={() => this.props.toggleModal(rowData)}
          style={{ marginRight: "5px" }}
        />
        <Button
          type="button"
          icon="pi pi-times-circle"
          className="p-button-danger"
          onClick={() => this.props.handleDelete(rowData)}
        />
      </div>
    );
  };

  showLink(rowData) {
    return (
      <a href={rowData.url} target="_blank" rel="noopener noreferrer">
        View Url
      </a>
    );
  }

  getLineElipsis = text => {
    return (
      <LinesEllipsis
        text={text}
        maxLine="3"
        ellipsis="..."
        trimRight
        basedOn="letters"
      />
    );
  };

  componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    // getResourcesSuccess: true, resources:
    if (this.state.loading && nextProps !== this.props) {
      const { resourceProp } = nextProps;
      if (resourceProp.getResourcesSuccess) {
        console.log("tomato ");
        this.setState({ loding: false, resources: resourceProp.resources });
      } else {
        this.setState({ loading: false, error: null });
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="content-section implementation">
          <DataTable
            value={this.state.resources}
            sortMode="multiple"
            paginator={true}
            rows={5}
            first={this.state.first}
            onPage={e => this.setState({ first: e.first })}
            style={{ textAlign: "center" }}
          >
            <Column
              body={body => this.getLineElipsis(body.title)}
              header="Title" /* sortable */
            />
            <Column
              body={body => this.getLineElipsis(body.description)}
              header="Description" /* sortable style={{ width: '20em' }} */
            />
            <Column body={this.showLink.bind(this)} header="URL" />
            <Column field="owner" header="Owner" />
            <Column
              body={body => (
                <img
                  src={body.thumbnail}
                  alt="thumbnail"
                  height="90"
                  width="90"
                />
              )}
              header="Thumbnail"
            />
            <Column
              body={body => {
                if (body.expiry) {
                  const e = new Date(body.expiry);
                  return e.toLocaleDateString("en-US");
                }
                return "";
              }}
              field="expiry"
              header="Expiry"
            />
            <Column field="location" header="Location" />
            <Column
              body={this.getActions}
              header="Actions"
              style={{ textAlign: "center", width: "14em" }}
            />
          </DataTable>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  resourceProp: state.resource
});
const mapDispatchToProps = dispatch => ({
  getResources: () => getResources({}, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListResource);
