import {
FORGOT_PASSWORD_SUCCESS,
FORGOT_PASSWORD_FAILURE,

RESET_PASSWORD_SUCCESS,
RESET_PASSWORD_FAILURE,
} from '../actions/auth/authActionTypes';

const initialState = {
  
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FORGOT_PASSWORD_SUCCESS:
      return { ...state, forgotPassSucc: true };
    case FORGOT_PASSWORD_FAILURE:
      return { ...state, forgotPassSucc: false, message:action.errResp.message };
    case RESET_PASSWORD_SUCCESS:
        return { ...state, resetPassSucc: true };
    case RESET_PASSWORD_FAILURE:
        return { ...state, resetPassSucc: false, message:action.errResp.message };
    
    default:
      return state;
  }
}
