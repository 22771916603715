import { put, call, takeEvery } from "redux-saga/effects";
import {
  FETCH_USER_LIST,
  FETCH_USER_LIST_SUCCESS,
  FETCH_USER_LIST_FAILURE,
  DEACTIVATE_USER_FAILURE,
  DEACTIVATE_USER_SUCCESS,
  DEACTIVATE_USER,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  ADD_USER,
  ADD_USER_FAILURE,
  ADD_USER_SUCCESS,
  GET_CMS_SUCCESS,
  GET_CMS_FAILURE,
  GET_CMS,
  PUT_CMS_SUCCESS,
  PUT_CMS_FAILURE,
  PUT_CMS,
  FETCH_ADMIN_LIST,
  FETCH_ADMIN_LIST_SUCCESS,
  FETCH_ADMIN_LIST_FAILURE,
  ADD_ADMIN,
  ADD_ADMIN_SUCCESS,
  ADD_ADMIN_FAILURE,
  DEACTIVATE_ADMIN,
  DEACTIVATE_ADMIN_SUCCESS,
  DEACTIVATE_ADMIN_FAILURE,
  FETCH_USER_INFO,
  FETCH_USER_INFO_SUCCESS,
  FETCH_USER_INFO_FAILURE,
  GET_INITIAL_FEEDBACK_SUCCESS,
  GET_INITIAL_FEEDBACK_FAILURE,
  GET_INITIAL_FEEDBACK,
  DELETE_SELECTED_FEEDBACK,
  CHANGE_FEEDBACK_IMP,
  REPLY_FEEDBACK,
  REPLY_FEEDBACK_SUCCESS,
  REPLY_FEEDBACK_FAILURE,
  GET_CLIENT_DETAIL,
  GET_CLIENT_DETAIL_SUCCESS,
  GET_CLIENT_DETAIL_FAILURE
} from "../actions/actionTypes";
import { AdminRoutes, User } from "../api/agent";

function* fetchUserList(action) {
  try {
    const payload = yield call(AdminRoutes.getUserList, action.payload);
    yield put({ type: FETCH_USER_LIST_SUCCESS, payload });
  } catch (err) {
    const errResp = err.response.body;
    yield put({ type: FETCH_USER_LIST_FAILURE, errResp });
  }
}

export function* watchGetUserList() {
  yield takeEvery(FETCH_USER_LIST, fetchUserList);
}

//add user
function* addUser(action) {
  try {
    const payload = yield call(AdminRoutes.addUser, action.payload);

    yield put({ type: ADD_USER_SUCCESS, payload });
  } catch (err) {
    const errResp = err.response.body;
    yield put({ type: ADD_USER_FAILURE, errResp });
  }
}

export function* watchAddUser() {
  yield takeEvery(ADD_USER, addUser);
}

//deactivate user
function* deactivateUser(action) {
  try {
    const payload = yield call(AdminRoutes.deactivateUser, action.payload);

    yield put({ type: DEACTIVATE_USER_SUCCESS, payload });
  } catch (err) {
    const errResp = err.response.body;
    yield put({ type: DEACTIVATE_USER_FAILURE, errResp });
  }
}

export function* watchDeactivateUser() {
  yield takeEvery(DEACTIVATE_USER, deactivateUser);
}

//delete user
function* deleteUser(action) {
  try {
    const payload = yield call(AdminRoutes.deleteUser, action.payload);

    yield put({ type: DELETE_USER_SUCCESS, payload });
  } catch (err) {
    const errResp = err.response.body;
    yield put({ type: DELETE_USER_FAILURE, errResp });
  }
}

export function* watchDeleteUser() {
  yield takeEvery(DELETE_USER, deleteUser);
}

//get cms content
function* getCMS(action) {
  try {
    const payload = yield call(AdminRoutes.getCMS);

    yield put({ type: GET_CMS_SUCCESS, payload });
  } catch (err) {
    const errResp = err.response.body.message.code;
    yield put({ type: GET_CMS_FAILURE, errResp });
  }
}

export function* watchGetCMS() {
  yield takeEvery(GET_CMS, getCMS);
}
//get cms content
function* putCMS(action) {
  try {
    const payload = yield call(AdminRoutes.putCMS, action.payload);

    yield put({ type: PUT_CMS_SUCCESS, payload });
    // yield put({ type: GET_CMS, payload});
  } catch (err) {
    const errResp = err.response.body.message.code;
    yield put({ type: PUT_CMS_FAILURE, errResp });
  }
}

export function* watchPutCMS() {
  yield takeEvery(PUT_CMS, putCMS);
}

//get admin list
function* fetchAdminList(action) {
  try {
    const payload = yield call(AdminRoutes.getAdminList);

    yield put({ type: FETCH_ADMIN_LIST_SUCCESS, payload });
  } catch (err) {
    const errResp = err.response.body;
    yield put({ type: FETCH_ADMIN_LIST_FAILURE, errResp });
  }
}

export function* watchGetAdminList() {
  yield takeEvery(FETCH_ADMIN_LIST, fetchAdminList);
}

//add admin
function* addAdmin(action) {
  try {
    const payload = yield call(AdminRoutes.addAdmin, action.payload);

    yield put({ type: ADD_ADMIN_SUCCESS, payload });
  } catch (err) {
    const errResp = err.response.body;
    yield put({ type: ADD_ADMIN_FAILURE, errResp });
  }
}

export function* watchAddAdmin() {
  yield takeEvery(ADD_ADMIN, addAdmin);
}

//deactivate admin
function* deactivateAdmin(action) {
  try {
    const payload = yield call(AdminRoutes.deactivateAdmin, action.payload);

    yield put({ type: DEACTIVATE_ADMIN_SUCCESS, payload });
  } catch (err) {
    const errResp = err.response.body;
    yield put({ type: DEACTIVATE_ADMIN_FAILURE, errResp });
  }
}

export function* watchDeactivateAdmin() {
  yield takeEvery(DEACTIVATE_ADMIN, deactivateAdmin);
}

function* fetchUserInfo(action) {
  try {
    const payload = yield call(AdminRoutes.getUserInfo, action.payload);

    yield put({ type: FETCH_USER_INFO_SUCCESS, payload });
  } catch (err) {
    const errResp = err.response.body;
    yield put({ type: FETCH_USER_INFO_FAILURE, errResp });
  }
}

export function* watchGetUserInfo() {
  yield takeEvery(FETCH_USER_INFO, fetchUserInfo);
}

//feedback
function* getInitialFeedback(action) {
  try {
    const payload = yield call(AdminRoutes.getInitialFeedback, action.payload);

    yield put({ type: GET_INITIAL_FEEDBACK_SUCCESS, payload });
  } catch (err) {
    const errResp = err.response.body;
    yield put({ type: GET_INITIAL_FEEDBACK_FAILURE, errResp });
  }
}

export function* watchGetInitialFeedback() {
  yield takeEvery(GET_INITIAL_FEEDBACK, getInitialFeedback);
}

//delete feedback
function* deleteSelectedFeedback(action) {
  try {
    const payload = yield call(
      AdminRoutes.deleteSelectedFeedback,
      action.payload
    );

    yield put({ type: GET_INITIAL_FEEDBACK_SUCCESS, payload });
  } catch (err) {
    const errResp = err.response.body;
    yield put({ type: GET_INITIAL_FEEDBACK_SUCCESS, errResp });
  }
}

export function* watchDeleteSelectedFeedback() {
  yield takeEvery(DELETE_SELECTED_FEEDBACK, deleteSelectedFeedback);
}

//change feedback importance
function* changeFeedbackImp(action) {
  try {
    const payload = yield call(AdminRoutes.changeFeedbackImp, action.payload);

    yield put({ type: GET_INITIAL_FEEDBACK_SUCCESS, payload });
  } catch (err) {
    const errResp = err.response.body;
    yield put({ type: GET_INITIAL_FEEDBACK_FAILURE, errResp });
  }
}

export function* watchChangeFeedbackImp() {
  yield takeEvery(CHANGE_FEEDBACK_IMP, changeFeedbackImp);
}

//reply to feedback
function* replyFeedback(action) {
  try {
    const payload = yield call(AdminRoutes.replyFeedback, action.payload);

    yield put({ type: REPLY_FEEDBACK_SUCCESS, payload });
  } catch (err) {
    const errResp = err.response.body;
    yield put({ type: REPLY_FEEDBACK_FAILURE, errResp });
  }
}

export function* watchReplyFeedback() {
  yield takeEvery(REPLY_FEEDBACK, replyFeedback);
}

//Get user detail
function* getClientDetail(action) {
  try {
    const payload = yield call(User.getClientDetail, action.payload);
    yield put({ type: GET_CLIENT_DETAIL_SUCCESS, payload });
  } catch (err) {
    const errResp = err.response.body;
    yield put({ type: GET_CLIENT_DETAIL_FAILURE, errResp });
  }
}

export function* watchGetClientDetail() {
  yield takeEvery(GET_CLIENT_DETAIL, getClientDetail);
}
