import {
  GET_ASSET_FINANCIALS,
  ADD_FINANCIAL_TO_ASSET
} from '../actionTypes';


export const getAssetFinancials = (data, dispatch) => {
  dispatch({ 
      type: GET_ASSET_FINANCIALS,
      payload: data, 
  })
}

export const addFinancialToAsset = (data, dispatch) =>{
  dispatch({
      type: ADD_FINANCIAL_TO_ASSET,
      payload: data,
  })
}