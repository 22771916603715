/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
import { connect } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatCurrency } from "../../helpers";
import { Col, Row, Container, Card, CardBody } from "reactstrap";

import {
  getSubmittedDeals,
  approveDeal,
  denyDeal,
  updateMonthlyDeal
} from "../../actions/submittedDeals";

class SubmittedDeals extends Component {
  url = process.env.REACT_APP_CLIENT_URL;

  constructor(props) {
    super(props);
    this.state = {
      defaultSelected: "all",
      filterByStatus: "all"
    };
  }

  componentWillMount() {
    const { getSubmittedDeals } = this.props;
    getSubmittedDeals();
  }

  getActions = rowData => (
    <div class="btn-group" role="group">
      <button
        id="btnGroupDrop1"
        type="button"
        class="btn btn-secondary dropdown-toggle"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        Dropdown
      </button>
      <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
        {Number(rowData.showAsDeal) === 1 && (
          <a
            class="dropdown-item"
            href="#"
            onClick={() => this.handleApproveDeal(rowData)}
          >
            Approve
          </a>
        )}
        {Number(rowData.showAsDeal) === 1 && (
          <a
            class="dropdown-item"
            href="#"
            onClick={() => this.handleDenyDeal(rowData)}
          >
            Decline
          </a>
        )}
        {Number(rowData.showAsDeal) === 2 && (
          <a
            class="dropdown-item"
            href="#"
            onClick={() => this.handleDenyDeal(rowData)}
          >
            Delete Deal
          </a>
        )}
        {Number(rowData.showAsDeal) === 2 && (
          <a
            class="dropdown-item"
            href="#"
            onClick={() => this.handleMonthlyDeal(rowData)}
          >
            Set Monthly Deal
          </a>
        )}
      </div>
    </div>
  );

  handleApproveDeal = deal => {
    this.props.approveDeal(deal);
  };

  handleDenyDeal = deal => {
    this.props.denyDeal(deal);
  };

  handleMonthlyDeal = deal => {
    this.props.updateMonthlyDeal(deal);
  };

  handleTypeChange = target => {
    this.setState({ defaultSelected: target.value });
  };

  handleStatusChange = target => {
    this.setState({ filterByStatus: target.value });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      //  console.log(nextProps.submittedDeals,"submitted deal");
      const { dealProp } = nextProps;
      if (
        dealProp.getSubmittedDealsSuccess === true ||
        dealProp.changeDealStatusSuccess === true
      )
        this.setState({ loading: false, logs: dealProp.submittedDeals });
      else if (
        dealProp.getSubmittedDealsSuccess === false ||
        dealProp.changeDealStatusSuccess === true
      )
        this.setState({ loading: false, error: null });
    }
  }

  render() {
    const monthlyDeal = this.props.dealProp.submittedDeals.find(
      deal => deal.monthlyDeal === true
    );
    let filteredDeals = [];
    if (this.state.defaultSelected !== "all")
      filteredDeals = this.props.dealProp.submittedDeals.filter(
        r => r.dealType === this.state.defaultSelected
      );
    else filteredDeals = this.props.dealProp.submittedDeals;
    return (
      <React.Fragment>
        <Container className="dashboard">
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xs="12" md={6} lg={4}>
                      <h4>Submitted Deals</h4>
                    </Col>
                    <Col xs="12" md={6} lg={4}>
                      <select
                        class="form-control"
                        id="exampleFormControlSelect1"
                        name="status"
                        value={this.state.filterByStatus}
                        onChange={({ target }) =>
                          this.handleStatusChange(target)
                        }
                      >
                        <option value="all">All</option>
                        <option value="2">Approved</option>
                        <option value="1">Unapproved</option>
                      </select>
                    </Col>
                    <Col xs="12" md={6} lg={4}>
                      <select
                        class="form-control"
                        id="exampleFormControlSelect1"
                        name="type"
                        value={this.state.defaultSelected}
                        onChange={({ target }) => this.handleTypeChange(target)}
                      >
                        <option value="all">All</option>
                        <option value="rental">Rentals</option>
                        <option value="note">Notes</option>
                        <option value="syndicate">Syndicates</option>
                      </select>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col xs="12" md={12} lg={12}>
                      <Card>
                        {monthlyDeal && (
                          <CardBody style={{ backgroundColor: "beige" }}>
                            <h4>
                              <u>Monthly Deal</u>
                            </h4>
                            <h5 className="mt-2">
                              Address: {monthlyDeal.address}
                            </h5>
                            <h5 className="mt-2">
                              Deal Type: {monthlyDeal.dealType.toUpperCase()}
                            </h5>
                            <h5 className="mt-2">
                              Sales Price:{" "}
                              {formatCurrency.format(
                                monthlyDeal.dealSalesPrice
                              )}
                            </h5>
                            <h5 className="mt-2">
                              <a href={`${this.url}/share/${monthlyDeal.slug}`}>
                                View Deal
                              </a>
                            </h5>
                          </CardBody>
                        )}
                        {!monthlyDeal && (
                          <CardBody>
                            <p>No monthly deal selected</p>
                          </CardBody>
                        )}
                      </Card>
                    </Col>
                  </Row>
                  <DataTable
                    value={filteredDeals}
                    paginator={true}
                    rows={20}
                    first={this.state.first}
                    onPage={e => this.setState({ first: e.first })}
                    style={{ textAlign: "center" }}
                  >
                    <Column field="address" header="Address" />
                    <Column
                      field="dealType"
                      header="Deal Type"
                      body={body => body.dealType.toUpperCase()}
                    />
                    <Column
                      field="dealSalesPrice"
                      body={body => formatCurrency.format(body.dealSalesPrice)}
                      header="Sales Price"
                    />
                    <Column
                      field="createdAt"
                      header="Submitted On"
                      body={body => {
                        const e = new Date(body.createdAt);
                        return e.toLocaleDateString("en-US");
                      }}
                    />
                    <Column
                      field=""
                      header="View Url"
                      body={body => {
                        return (
                          <a
                            href={`${this.url}/share/${body.slug}`}
                            target="_blank"
                          >
                            {" "}
                            View Deal
                          </a>
                        );
                      }}
                    />
                    <Column
                      body={this.getActions}
                      header="Actions"
                      style={{ textAlign: "center", width: "14em" }}
                    />
                  </DataTable>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  dealProp: state.submittedDeals
});
const mapDispatchToProps = dispatch => ({
  getSubmittedDeals: () => getSubmittedDeals(dispatch),
  approveDeal: deal => approveDeal(deal, dispatch),
  denyDeal: deal => denyDeal(deal, dispatch),
  updateMonthlyDeal: deal => updateMonthlyDeal(deal, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmittedDeals);
