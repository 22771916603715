import {
  ADD_ASSET,
  GET_ALL_ASSETS,
  GET_USER_ASSETS,
  GET_ASSET_DETAIL,
  GET_ASSET_BASIC_INFO,
  UPDATE_ASSET_BASIC_INFO,
  GET_ASSET_INVESTMENT_INFO,
  UPDATE_ASSET_INVESTMENT_INFO
} from "../actionTypes";

export const addAsset = (data, dispatch) => {
  dispatch({
    type: ADD_ASSET,
    payload: data
  });
};

export const getAllAssets = (data, dispatch) => {
  dispatch({
    type: GET_ALL_ASSETS,
    payload: data
  });
};

export const getUserAssets = (data, dispatch) => {
  dispatch({
    type: GET_USER_ASSETS,
    payload: data
  });
};

export const getAssetDetail = (data, dispatch) => {
  dispatch({
    type: GET_ASSET_DETAIL,
    payload: data
  });
};

export const getBasicInfo = (slug, dispatch) => {
  dispatch({
    type: GET_ASSET_BASIC_INFO,
    payload: slug
  });
};
export const updateBasicInfo = (data, dispatch) => {
  dispatch({
    type: UPDATE_ASSET_BASIC_INFO,
    payload: data
  });
};
export const getInvestmentInfo = (slug, dispatch) => {
  dispatch({
    type: GET_ASSET_INVESTMENT_INFO,
    payload: slug
  });
};

export const updateInvestmentInfo = (data, dispatch) => {
  dispatch({
    type: UPDATE_ASSET_INVESTMENT_INFO,
    payload: data
  });
};
