import isEmpty from '../validation/is-empty';
import {
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGIN_REQUEST,
    LOGOUT_REQUEST,
    SET_CURRENT_USER,
    // SET_CURRENT_USER_DETAIL,
    // UPDATE_USER_DETAIL_SUCCESS,
    // GET_USER_DETAIL,
    // UPDATE_USER_DETAIL
} from '../actions/auth/authActionTypes';
// import { setAuthToken } from '../api/agent';

const initialState = {
    loading: false,
    user: {
        token: '',
        picture: '',
        fullName: '',
    }
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                loading: true,
            }
        case LOGIN_SUCCESS:
            return {
                loading: false,
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload,
            }
        case LOGIN_FAILURE:
            return {
                loading: false,
                error: action.errResp,
            }
        case SET_CURRENT_USER:
            return {
                loading: false,
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload,
                serverResp: action.payload.message,
            }

        case LOGOUT_REQUEST:
            return {
                loading: false,
                isAuthenticated: false,
                user: {
                    token: '',
                    picture: '',
                    fullName: '',
                }
            }
        default:
            return state;
    }
}