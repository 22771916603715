import React from "react";
import { Card, CardBody, Col } from "reactstrap";

const PlanInfo = ({ plans }) => {
  return plans
    ? plans.map((plan, i) => (
        <Col index={i} md={6} xl={4} sm={12}>
          <Card className="pricing-card pricing-card--primary">
            <CardBody className="pricing-card__body">
              <h3 className="pricing-card__plan">{plan.planName}</h3>
              <hr />
              <p className="pricing-card__price">
                ${plan.planPrice}
                <span>/mo</span>
              </p>
              <p className="pricing-card__feature">
                <pre>{plan.planDescription}</pre>
              </p>
              <a
                className="pricing-card__button"
                color="primary"
                href={` ${plan.planUrl}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                View Plan
              </a>
            </CardBody>
          </Card>
        </Col>
      ))
    : null;
};

export default PlanInfo;
