import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getPartnerIntegrations } from "../../actions/partner";
import { TabContent, TabPane, Nav, NavItem } from "reactstrap";
import { NavLink as NavLinkStrap } from "reactstrap";
import clsx from "clsx";
import RentManager from "./RentManager";
import { isFetching } from "../../reducers/reducerUtils";

const Integrations = ({ userId, integrations, getPartnerIntegrations }) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    getPartnerIntegrations(userId);
  }, []);

  if (isFetching(integrations)) return <div />;

  return (
    <main className="container-fluid p-5">
      <div className="nav-tabs-first tabs-animated tabs-bordered tabs-animated-shadow">
        <Nav tabs>
          <NavItem>
            <NavLinkStrap
              className={clsx({ active: activeTab === "1" })}
              onClick={() => {
                toggle("1");
              }}
            >
              <span>Rent Manager</span>
            </NavLinkStrap>
          </NavItem>
        </Nav>
      </div>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <RentManager
            integrations={integrations}
            user={userId}
            data={integrations.data.find(i => i.type === "rent_manager")}
          />
        </TabPane>
      </TabContent>
    </main>
  );
};

const mapStateToProps = state => {
  return {
    integrations: state.partner.integrations
  };
};

const mapDispatchToProps = dispatch => ({
  getPartnerIntegrations: userId => getPartnerIntegrations(userId, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Integrations));
