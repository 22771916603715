import {
    FETCH_USER_LIST_SUCCESS,
    FETCH_USER_LIST_FAILURE,
    DEACTIVATE_USER_SUCCESS,
    DEACTIVATE_USER_FAILURE,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILURE,
    ADD_USER_SUCCESS,
    ADD_USER_FAILURE,
    GET_CMS_SUCCESS,
    GET_CMS_FAILURE,
    PUT_CMS_SUCCESS,
    PUT_CMS_FAILURE,

    FETCH_ADMIN_LIST_SUCCESS,
    FETCH_ADMIN_LIST_FAILURE,
    ADD_ADMIN_SUCCESS,
    ADD_ADMIN_FAILURE,
    DEACTIVATE_ADMIN_SUCCESS,
    DEACTIVATE_ADMIN_FAILURE,
    GET_INITIAL_FEEDBACK_SUCCESS,
    GET_INITIAL_FEEDBACK_FAILURE,
    DELETE_SELECTED_FEEDBACK_SUCCESS,
    DELETE_SELECTED_FEEDBACK_FAILURE,
    REPLY_FEEDBACK_FAILURE,
    REPLY_FEEDBACK_SUCCESS,
    FETCH_USER_INFO_SUCCESS,
    FETCH_USER_INFO_FAILURE,
} from '../actions/actionTypes';

const initialState = {
    loading: false,
}

export default function (state = initialState, action) {
    switch (action.type) {

        //user section
        case FETCH_USER_LIST_SUCCESS:
            return {
                ...state,
                deactivateSuccess: false,
                userInfoSuccess: false,
                userList: action.payload.data
            }
        case FETCH_USER_LIST_FAILURE:
            return {
                ...state,
                userList: '',
                userInfoSuccess: false,
                error: action.errResp.message
            }
        case FETCH_USER_INFO_SUCCESS:
            return {
                ...state,
                userInfoSuccess: true,
                userInfo: action.payload.user
            }
        case FETCH_USER_INFO_FAILURE:
            return {
                ...state,
                userInfoSuccess: false,
                error: action.errResp.message
            }
        case ADD_USER_SUCCESS:
            return {
                ...state,
                addUserSuccess: true
            }
        case ADD_USER_FAILURE:
            return {
                ...state,
                addUserSuccess: false,
                serverResp: action.errResp.message
            }
        case DEACTIVATE_USER_SUCCESS:
            return {
                ...state,
                deactivateSuccess: true
            }
        case DEACTIVATE_USER_FAILURE:
            return {
                ...state,
                deactivateSuccess: false,
            }
        case DELETE_USER_SUCCESS:
            return {
                ...state,
                deleteSuccess: true
            }
        case DELETE_USER_FAILURE:
            return {
                ...state,
                deleteSuccess: false,
            }

        case GET_CMS_SUCCESS:
            return {
                ...state,
                getCMSSuccess: true,
                cmsContent: action.payload.data,
                message: action.payload.message ?
                    action.payload.message : null
            }
        case GET_CMS_FAILURE:
            return {
                ...state,
                getCMSSuccess: false,
            }
        case PUT_CMS_SUCCESS:
            return {
                ...state,
                putCMSSuccess: true,
                cmsContent: action.payload.data,
                message: action.payload.message ?
                    action.payload.message : null
            }
        case PUT_CMS_FAILURE:
            return {
                ...state,
                putCMSSuccess: false,
            }

        //admin section
        case FETCH_ADMIN_LIST_SUCCESS:
            return {
                ...state,
                deactivateSuccess: false,
                adminList: action.payload.data
            }
        case FETCH_ADMIN_LIST_FAILURE:
            return {
                ...state,
                adminList: '',
                error: action.errResp.message
            }
        case ADD_ADMIN_SUCCESS:
            return {
                ...state,
                addAdminSuccess: true
            }
        case ADD_ADMIN_FAILURE:
            return {
                ...state,
                addAdminSuccess: false,
                serverResp: action.errResp.message,
            }
        case DEACTIVATE_ADMIN_SUCCESS:
            return {
                ...state,
                deactivateSuccess: true
            }
        case DEACTIVATE_ADMIN_FAILURE:
            return {
                ...state,
                deactivateSuccess: false,
            }
        //feedback
        case GET_INITIAL_FEEDBACK_SUCCESS:
            return {
                ...state,
                feedbacks: action.payload.data,
                deactivateSuccess: true,
            }
        case GET_INITIAL_FEEDBACK_FAILURE:
            return {
                ...state,
                deactivateSuccess: false,
            }

        //feedback delete
        case DELETE_SELECTED_FEEDBACK_SUCCESS:
            return {
                ...state,
                feedbacks: action.payload.data,
                deleteFeedbackSuccess: true,
            }
        case DELETE_SELECTED_FEEDBACK_FAILURE:
            return {
                ...state,
                deleteFeedbackSuccess: false,
            }
        //reply feedback
        case REPLY_FEEDBACK_SUCCESS:
            return {
                ...state,
                replyFeedbackSuccess: true,
            }
        case REPLY_FEEDBACK_FAILURE:
            return {
                ...state,
                replyFeedbackSuccess: false,
            }
        default:
            return state;
    }
}