import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import FormField from "../../../shared/components/form/FormField";
import renderDatePickerField from "../../../shared/components/form/DatePicker";
import { getBasicInfo, updateBasicInfo } from "../../../actions/asset";

let initialValues = {
  beds: "",
  baths: "",
  yearBuilt: "",
  areaSquareFeet: "",
  lotSizeSquareFeet: "",
  stories: "",
  units: "",
  trust: "",
  land: "",
  purchaseDate: ""
};

const validationSchema = Yup.object({
  beds: Yup.number()
    .typeError("Enter valid beds")
    .integer("Enter valid beds")
    .min(0, "Enter valid beds")
    .max(1000, "Bed size should be less than 1000"),
  baths: Yup.number()
    .typeError("Enter valid baths")
    .min(0, "Enter valid baths")
    .max(1000, "Baths size should be less than 1000"),
  yearBuilt: Yup.number()
    .typeError("Enter valid year built")
    .integer("Enter valid year built")
    .min(0, "Enter valid year built")
    .max(3000, "Enter valid year built"),
  areaSquareFeet: Yup.number()
    .typeError("Enter valid Area Sq.ft")
    .min(0, "Enter valid Area Sq.ft")
    .max(9999999999, "Area Sq.ft is very large"),
  lotSizeSquareFeet: Yup.number()
    .typeError("Enter valid Lot size")
    .min(0, "Enter valid Lot size")
    .max(9999999999, "Lot size Sq.ft is very large"),
  stories: Yup.number()
    .typeError("Enter valid stories")
    .integer("Enter valid stories")
    .min(0, "Enter valid stories")
    .max(1000, "Too many stories"),
  units: Yup.number()
    .typeError("Enter valid units")
    .integer("Enter valid units")
    .min(1, "Min 1 unit is required")
    .max(10, "Max 10 units can be added")
    .required("Enter units between 1-10"),
  trust: Yup.bool(true),
  land: Yup.number()
    .typeError("Enter valid land percentage")
    .integer("Enter valid land percentage")
    .min(0, "Enter valid land percentage")
    .max(100, "Enter valid land percentage"),
  purchaseDate: Yup.date()
    .typeError("Enter Valid date")
    .required("Purchase Date is Required")
});

class BasicInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null
    };
  }

  handleSubmit = values => {
    const { updateBasicInfo } = this.props;
    this.setState({ loading: true, error: null }, () => {
      values.slug = this.getSlug();
      updateBasicInfo(values);
    });
  };

  getSlug = () => {
    const { match } = this.props;
    return match.params.slug;
  };

  componentWillMount() {
    const { getBasicInfo } = this.props;
    const slug = this.getSlug();
    getBasicInfo(slug);
  }

  componentWillReceiveProps(nextProps) {
    const { assetProp } = nextProps;

    if (assetProp.getAssetBasicInfoSuccess === true)
      this.setState({ loading: false, error: null });
    else if (assetProp.getAssetBasicInfoSuccess === false)
      this.setState({ loading: false, error: "Error while updating" });

    if (assetProp.updateAssetBasicInfoSuccess === true)
      this.setState({ loading: false, error: null });
    else if (assetProp.updateAssetBasicInfoSuccess === false)
      this.setState({ loading: false, error: "Error while updating" });
  }

  render() {
    // const purchase_date = (this.props.asset && this.props.asset.purchase_date) ? new Date(this.props.asset.purchase_date) : "";
    const { basicInfo } = this.props.assetProp;

    initialValues = { ...initialValues, ...basicInfo /* purchase_date */ };
    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
        onReset={this.closeModal}
        render={props => {
          const { handleSubmit, handleReset, resetForm } = props;
          const { loading } = this.state;
          this.resetForm = resetForm;
          return (
            <form
              className="form"
              onSubmit={handleSubmit}
              onReset={handleReset}
            >
              {/* {this.state.error && <p style={{ color: 'red' }}>{this.state.error}</p>} */}
              {/* {errors.address && <p> All fields are required </p>} */}
              <div className="form__half">
                <div className="form__form-group">
                  <span className="form__form-group-label">Beds</span>
                  <div className="form__form-group-field">
                    <Field name="beds" component={FormField} type={"number"} />
                  </div>
                </div>

                <div className="form__form-group">
                  <span className="form__form-group-label">Baths</span>
                  <div className="form__form-group-field">
                    <Field name="baths" component={FormField} type={"number"} />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Year Built</span>
                  <div className="form__form-group-field">
                    <Field
                      name="yearBuilt"
                      component={FormField}
                      type={"number"}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Stories</span>
                  <div className="form__form-group-field">
                    <Field
                      name="stories"
                      component={FormField}
                      type={"number"}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Purchase Date</span>
                  <div className="form__form-group-field">
                    <Field
                      name="purchaseDate"
                      component={renderDatePickerField}
                    />
                  </div>
                </div>
              </div>

              <div className="form__half">
                <div className="form__form-group">
                  <span className="form__form-group-label">Units</span>
                  <div className="form__form-group-field">
                    <Field name="units" component={FormField} type={"number"} />
                  </div>
                </div>

                <div className="form__form-group">
                  <span className="form__form-group-label">
                    Land Percentage
                  </span>
                  <div className="form__form-group-field">
                    <Field name="land" component={FormField} type={"number"} />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Square Footage</span>
                  <div className="form__form-group-field">
                    <Field
                      name="areaSquareFeet"
                      component={FormField}
                      type={"number"}
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Lot Size</span>
                  <div className="form__form-group-field">
                    <Field
                      name="lotSizeSquareFeet"
                      component={FormField}
                      type={"number"}
                    />
                  </div>
                </div>

                <div className="btn-toolbar float-right" role="toolbar">
                  <div className="btn-group" role="group">
                    <button
                      className="btn btn-primary"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? "Saving" : "Save"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          );
        }}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getBasicInfo: slug => getBasicInfo(slug, dispatch),
  updateBasicInfo: data => updateBasicInfo(data, dispatch)
});

const mapStateToProps = state => ({
  assetProp: state.asset
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BasicInfo);
