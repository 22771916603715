import {
  GET_SUBMITTED_DEALS_FAILURE,
  GET_SUBMITTED_DEALS_SUCCESS,
  
  DENY_DEAL_FAILURE,
  DENY_DEAL_SUCCESS,

  APPROVE_DEAL_FAILURE,
  APPROVE_DEAL_SUCCESS,
  UPDATE_MONTHLY_DEAL_SUCCESS,
  UPDATE_MONTHLY_DEAL_FAILURE,

} from '../actions/actionTypes';

const initialState = {
  submittedDeals: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SUBMITTED_DEALS_SUCCESS:
      return {
        submittedDeals: action.payload.submittedDeals,
        getSubmittedDealsSuccess: true,
      }
    case GET_SUBMITTED_DEALS_FAILURE:
      return {
        submittedDeals: state.submittedDeals,
        error: action.err.message,
        getSubmittedDealsSuccess: true,
      }
    case APPROVE_DEAL_SUCCESS:
      const index = state.submittedDeals.findIndex((d) => d._id.toString() === action.payload.deal._id.toString());
      if (index > -1)
        state.submittedDeals[index] = action.payload.deal;
      return {
        submittedDeals: state.submittedDeals,
        approveDealSuccess: true,
      }
    case APPROVE_DEAL_FAILURE:
      return {
        submittedDeals: state.submittedDeals,
        approveDealSuccess: false,
        error: action.err.message
      }

    case DENY_DEAL_SUCCESS:
      const delIndex = state.submittedDeals.findIndex((d) => d._id.toString() === action.payload.deal._id.toString());

      if (delIndex > -1)
        state.submittedDeals.splice(delIndex, 1);
      return {
        submittedDeals: state.submittedDeals,
        denyDealSuccess: true,
      }
    case DENY_DEAL_FAILURE:
      return {
        submittedDeals: state.submittedDeals,
        denyDealSuccess: false,
        error: action.err.message
      }

      case UPDATE_MONTHLY_DEAL_SUCCESS:
        const resetDeals = state.submittedDeals.map((d)=>({...d, monthlyDeal: false}));
        const mdealIndex = resetDeals.findIndex((d) => d._id.toString() === action.payload.deal._id.toString());

        if (mdealIndex > -1)
          resetDeals[mdealIndex] = action.payload.deal;
        return {
          submittedDeals: resetDeals,
          updateMonthlyDealSuccess: true,
        }
      case UPDATE_MONTHLY_DEAL_FAILURE:
        return {
          submittedDeals: state.submittedDeals,
          updateMonthlyDealSuccess: false,
          error: action.err.message
        }

    default:
      return state;
  }
}