import React, { Component, Fragment } from 'react';
import { ConnectedRouter } from 'connected-react-router'
import jwt_decode from 'jwt-decode';
import { Provider } from 'react-redux';
import { connect } from 'react-redux';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Router from './Router';
import ScrollToTop from './ScrollToTop';


import { store, history } from './store';
import { setAuthToken, Auth } from './api/agent';
import { logoutMalformed } from './actions/auth/authAction';
import { LOGOUT_REQUEST } from './actions/auth/authActionTypes';
import { SET_CURRENT_USER } from './actions/auth/authActionTypes';

import './App.scss';

import { logout } from './actions/auth/authAction';


const validateToken = async (self, token) => {

    //setting auth headers
    setAuthToken(token);
    let resp = await Auth.validateToken();

    if (resp.success === true) {
        localStorage.setItem('token', resp.user.token);
        const decoded = jwt_decode(resp.user.token);
        store.dispatch({
            type: SET_CURRENT_USER, payload: {
                token: resp.user.token,
                picture: decoded.picture,
                fullName: decoded.fullName,
            }
        })
        self.setState({
            loading: false
        });
        return resp;
    } else {
        store.dispatch({
            type: SET_CURRENT_USER, payload: {}
        })
        return false;
    }
}



class App extends Component {
    constructor(props) {
        super();
        this.state = {
            loading: true,
        };
    }



    async   componentDidMount() {
        const token = localStorage.getItem('token');
        if (token) {
            var decoded = null;
            try {
                decoded = jwt_decode(token);

            }
            catch (e) {
                //token is malformed
                // this.props.logout();
                logoutMalformed();
                store.dispatch({
                    type: LOGOUT_REQUEST
                });
                window.location = '/login';
            }
            const currentTime = Date.now() / 1000;
            if (decoded && decoded.exp < currentTime) {
                logoutMalformed();
                store.dispatch({
                    type: LOGOUT_REQUEST
                });
                // history.push('/login');
                window.location = '/login';

            } else {
                //token is still alive.We check its integrity.
                try {
                    var yes = await validateToken(this, token);
                    if (!yes) {
                        this.props.logoutUser();
                        // this.props.history.push('/');
                        this.setState({ loading: false });
                        history.push('/login');
                    };

                    // store.dispatch({ type: SET_CURRENT_USER, payload: resp.user })
                }
                catch (err) {
                    this.props.logout();
                    this.setState({ loading: false });

                    history.push('/login');
                }
            }

        } else {
            this.setState({ loading: false });
            // history.push('/login');
        }
    }


    render() {
        const { loading } = this.state;
        return (
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <ScrollToTop>
                        <Fragment>
                            {loading
                                ? (
                                    <div className={`load${loading ? '' : ' loaded'}`}>
                                        <div className="load__icon-wrap">
                                            <svg className="load__icon">
                                                <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                                            </svg>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <ToastContainer />
                                        <Router />
                                    </div>
                                )
                            }
                        </Fragment>
                    </ScrollToTop>
                </ConnectedRouter>
            </Provider>
        );
    }

}
const mapStateToProps = state => ({
    auth: state.login
});
const mapDispatchToProps = dispatch => ({
    logout: () => { logout(dispatch) },
});
export default connect(mapStateToProps, mapDispatchToProps)(App);