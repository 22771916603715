import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import {
    Col,
    Row,
    Container, Card, CardBody
} from 'reactstrap';

import AddResource from './AddResource';
import ListResource from './ListResource';

import { deleteResource, updateResource } from '../../actions/resource';

class Resource extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAddModal: false,
            resourceToUpdate: null,
            defaultSelected: 'webinar',
            approvalType:true
        };
    }

    toggleAddResource = (row = null) => {
        this.setState((prevState) => ({ showAddModal: !prevState.showAddModal, resourceToUpdate: row }));
    }

    handleDelete = (row) => {
        this.props.deleteResource(row._id);
    }

    handleTypeChange = (target) => {
        this.setState({ defaultSelected: target.value });
    }
    handleStatusChange = (target) => {
        this.setState({ approvalType: target.value === 'true' ? true: false})
    }

    render() {

        if (this.state.isLoading) {
            return (<LoadingOverlay active={this.state.isLoading} spinner text='Please wait!' />)
        } else {
            return (
                <React.Fragment>
                    <Container className="dashboard">
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col xs="12" md={12} lg={3}>
                                                <h4>Resources </h4>
                                            </Col>
                                            <Col xs="12" md={6} lg={3} >
                                                <select class="form-control" id="exampleFormControlSelect1" name="status" value={this.state.filterByStatus} onChange={({ target }) => this.handleStatusChange(target)} >
                                                    {/* <option value={true}>All</option> */}
                                                    <option value={true}>Approved</option>
                                                    <option value={false}>Pending</option>
                                                </select>
                                            </Col>
                                            <Col xs="12" md={12} lg={3} >
                                                <select class="form-control" id="exampleFormControlSelect1" name="type" value={this.state.defaultSelected} onChange={({ target }) => this.handleTypeChange(target)} >
                                                    {/* <option value="All">All</option> */}
                                                    <option value="webinar">Webinar</option>
                                                    <option value="video">Video</option>
                                                    <option value="blog">Blog</option>
                                                    <option value="live">Live</option>
                                                </select>
                                            </Col>

                                            <Col xs="1" md={12} lg={3} className="text-right">
                                                <button className="btn btn-primary btn-small" onClick={() => this.toggleAddResource(null)}>Add new Resource</button>
                                            </Col>
                                        </Row>
                                        <ListResource handleDelete={this.handleDelete} handleUpdate={this.handleUpdate} toggleModal={this.toggleAddResource} 
                                        filter={this.state.defaultSelected} 
                                        approvalType={this.state.approvalType}
                                        />
                                        <AddResource modalOpen={this.state.showAddModal} closeModal={this.toggleAddResource} update={this.state.resourceToUpdate} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </React.Fragment>
            );
        }
    }
}
const mapStateToProps = state => ({
    resourceProp: state.resource,
});
const mapDispatchToProps = dispatch => ({
    deleteResource: (resourceId) => deleteResource(resourceId, dispatch),
    updateResource: (resourceId) => updateResource(resourceId, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Resource);