import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik, Field } from 'formik';
import * as Yup from "yup";
import { Modal } from 'reactstrap';

import { addAsset } from '../../actions/asset';
import FormField from '../../shared/components/form/FormField';



let initialValues = {
  address: '',
};

const validationSchema = Yup.object({
  address: Yup.string().required("Address is required"),
});

class AddAsset extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
    };
  }

  resetForm;
  handleSubmit = (values) => {
    this.setState({ loading: true, error: null }, () => {
      const { userId, addAsset } = this.props;
      let v = values;
      v._id = userId;
      addAsset(v);
    });
  }


  closeModal = () => {
    this.resetForm({});
    this.setState({ error: null })
    this.props.closeModal(null);
  }


  componentWillReceiveProps(nextProps) {
    if (this.state.loading) {
      const assetProp = nextProps.assetProp;
      if (assetProp.addAssetSuccess === true) {
        this.setState({ loading: false, error: null }, () => {
          this.closeModal();
        });
      } else if (assetProp.addAssetSuccess === false) {
        this.setState({ loading: false, error: assetProp.error });
      }
    }
  }

  render() {
    initialValues = { ...initialValues }
    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
        onReset={this.closeModal}
        render={props => {
          const { handleSubmit, handleReset, resetForm } = props;
          const { modalOpen } = this.props;
          const { loading } = this.state;
          this.resetForm = resetForm;
          return (
            <Modal isOpen={modalOpen} className={"modal-dialog--primary modal-dialog--header"}>
              <div className="modal__header">
                <h4 className="bold-text  modal__title">Add Asset</h4>
              </div>
              <div className="modal__body">
                {this.state.error && <p style={{ color: 'red' }}>{this.state.error}</p>}
                <form className="form" onSubmit={handleSubmit} onReset={handleReset}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Asset Address</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address"
                        component={FormField}
                        type={"text"}
                        placeholder={"Enter asset address"}
                      />
                    </div>
                  </div>
                  <div className="btn-toolbar float-right" role="toolbar" >
                    <div className="btn-group mr-2 text-right" role="group" >
                      <button className="btn btn-danger btn-sm" type="reset" disabled={loading}>Cancel</button>
                    </div>
                    <div className="btn-group" role="group" >
                      <button className="btn btn-primary btn-sm" type="submit" disabled={loading}>{loading ? 'Saving' : 'Save'}</button>
                    </div>
                  </div>
                </form>
              </div>
            </Modal>)
        }}></Formik>)
  }
}

const mapStateToProps = state => ({
  assetProp: state.asset,
});

const mapDispatchToProps = dispatch => ({
  addAsset: (data) => addAsset(data, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddAsset);