import React, { PureComponent } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Badge
} from "reactstrap";
import { connect } from "react-redux";
import classnames from "classnames";
import { getPartnerDetail, changeApproveStatus } from "../../actions/partner";
import holderImage from "../../images/property_placeholder.jpg";
import PlanInfo from "./PlanInfo";
import Questions from "./Questions";
import Integrations from "./Integrations";

import showToast from "../../shared/components/toasterNotification";

class PartnerInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.changeApproveStatus = this.changeApproveStatus.bind(this);
    this.state = {
      activeTab: "1",
      loading: "",
      error: null
    };
  }

  componentWillMount() {
    const { match, getPartnerDetails } = this.props;
    const { userId } = match.params;
    getPartnerDetails(userId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.partnerProp.getPartnerDetailSuccess === true)
      this.setState({ loading: false, error: null });
    else if (nextProps.partnerProp.getPartnerDetailFailure === false)
      this.setState({
        loading: false,
        error: "Problem while loading partners"
      });
    if (
      this.state.loading === true &&
      nextProps.partnerProp.changePartnerDetailSuccess === true
    )
      showToast("s", "Partner Request Approved Successfully");
    else if (
      this.state.loading === true &&
      nextProps.partnerProp.changePartnerDetailSuccess === false
    )
      showToast("e", "Partner Request Approved Successfully");
  }

  toggle(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  changeApproveStatus(status) {
    const { match, changeApproveStatus } = this.props;
    const { userId } = match.params;
    changeApproveStatus({ userId: userId, status });
    this.setState({ loading: true });
  }

  render() {
    const { activeTab } = this.state;
    const {
      questions,
      bio,
      tags,
      plans,
      isDraft,
      status,
      fullName,
      email,
      displayUrl
    } = this.props.partnerProp && this.props.partnerProp.partnerDetail;

    const { match } = this.props;
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12} lg={12} xl={12}>
            <Card>
              <CardBody className="profile__card">
                {this.state.error && (
                  <div className="profile__information">
                    <p>Error loading data</p>
                  </div>
                )}
                {!this.state.loading && !this.state.error && (
                  <React.Fragment>
                    <div className="profile__information">
                      <div className="profile__avatar">
                        <img
                          src={displayUrl}
                          alt="avatar"
                          onError={e => {
                            e.target.src = holderImage;
                          }}
                        />
                      </div>
                      <div className="profile__data">
                        {/* <p className="profile__name">{partnerDetail.asset_name}</p> */}
                        <p className="profile__contact">
                          Full Name: {fullName}
                        </p>
                        <p className="profile__contact">Email: {email}</p>
                        <p className="profile__contact">
                          Bio: <pre>{bio}</pre>
                        </p>
                        <p className="profile__contact">
                          Tags:{" "}
                          <pre>
                            {tags &&
                              tags.map(tag => {
                                return (
                                  <Badge color="primary" pill>
                                    {tag}
                                  </Badge>
                                );
                              })}
                          </pre>
                        </p>
                        <p className="profile__contact">Status: {status}</p>
                      </div>
                    </div>
                    {/* <div className="profile__stats">
                      <div className="profile__stat">
                        <p className="profile__stat-number">$ {partnerDetail.stats.cashflow.currMonth}</p>
                        <p className="profile__stat-title">Cashflow</p>
                      </div>
                      <div className="profile__stat">
                        <p className="profile__stat-number">$ {partnerDetail.stats.noi.currMonth}</p>
                        <p className="profile__stat-title">NOI</p>
                      </div>
                      <div className="profile__stat">
                        <p className="profile__stat-number">$ {partnerDetail.stats.cocROI}</p>
                        <p className="profile__stat-title">COC ROI</p>
                      </div>
                    </div> */}
                  </React.Fragment>
                )}
                {this.state.loading && (
                  <div className="profile__information">
                    <p>Loading</p>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12} xl={12}>
            <Card>
              <div className="profile__card tabs tabs--bordered-bottom">
                <div className="tabs__wrap">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          this.toggle("1");
                        }}
                      >
                        Plans
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          this.toggle("2");
                        }}
                      >
                        Questions
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "3" })}
                        onClick={() => {
                          this.toggle("3");
                        }}
                      >
                        Integrations
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Row>
                        <PlanInfo plans={plans} />
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Questions questions={questions} />
                    </TabPane>
                    <TabPane tabId="3">
                      <Integrations userId={match.params} />
                    </TabPane>
                    <Row style={{ float: "right" }}>
                      {isDraft ||
                      status === "pending" ||
                      status === "rejected" ? (
                        <Button
                          color="primary"
                          onClick={() => this.changeApproveStatus("active")}
                          type="text"
                        >
                          Mark Active
                        </Button>
                      ) : null}
                      {isDraft || status === "pending" ? (
                        <Button
                          color="primary"
                          onClick={() => this.changeApproveStatus("rejected")}
                          type="button"
                        >
                          Mark Reject
                        </Button>
                      ) : null}
                    </Row>
                  </TabContent>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  partnerProp: state.partner
});
const mapDispatchToProps = dispatch => ({
  getPartnerDetails: slug => getPartnerDetail(slug, dispatch),
  changeApproveStatus: slug => changeApproveStatus(slug, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PartnerInfo);
