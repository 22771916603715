import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import loginReducer from './loginReducer';
import userDetailReducer from './userDetailReducer';
import userReducer from './userReducer';
import resourceReducer from './resourceReducer';
import assetlogReducer from './assetlogReducer';
import assetReducer from './assetReducer';
import sidebarReducer from './sidebarReducer';
import assetFinancial from './financialReducer';
import passReducer from './passReducer';
import uploadReducer from './uploadReducer';
import submittedDealReducer from './submittedDealReducer';
import partnerReducer from './partnerReducer';

const rootReducer = (history) => combineReducers({
  login: loginReducer,
  router: connectRouter(history),
  userDetail: userDetailReducer,
  adminUser: userReducer,
  resource: resourceReducer,
  assetlog: assetlogReducer,
  asset: assetReducer,
  financial: assetFinancial,
  sidebar: sidebarReducer,
  pass: passReducer,
  upload: uploadReducer,
  submittedDeals: submittedDealReducer,
  partner: partnerReducer,
})

export default rootReducer

