import React from "react";
import PropTypes from "prop-types";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import CustomSearch from "../CustomSearch";
import cellEditFactory from "react-bootstrap-table2-editor";

const TableWithSearch = ({
  columns,
  data,
  page,
  keyField,
  sizePerPage,
  onTableChange,
  totalSize,
  onSelectRow,
  searchPlaceholder,
  noDataIndicator,
  selectedRows,
  enableCellEdit,
  location,
  resetQueryParams,
  showSearch
}) => {
  return (
    <PaginationProvider
      pagination={paginationFactory({
        totalSize,
        page,
        sizePerPage
      })}
      keyField={keyField}
      columns={columns}
      data={data}
    >
      {({ paginationProps, paginationTableProps }) => (
        <ToolkitProvider
          keyField={keyField}
          data={data}
          columns={columns}
          defaultSorted={[
            {
              id: "date",
              desc: true
            }
          ]}
          bootstrap4
          search
        >
          {props => {
            return (
              <div>
                {showSearch && (
                  <CustomSearch
                    placeholder={searchPlaceholder}
                    {...props.searchProps}
                    selectedRows={selectedRows}
                    resetQueryParams={resetQueryParams}
                    location={location}
                    normalize={true}
                  />
                )}
                <BootstrapTable
                  {...props.baseProps}
                  {...paginationTableProps}
                  remote={{
                    search: true,
                    pagination: true,
                    cellEdit: enableCellEdit
                  }}
                  wrapperClasses="contact_page"
                  onTableChange={onTableChange}
                  selectRow={onSelectRow}
                  noDataIndication={() => <div>{noDataIndicator}</div>}
                  cellEdit={
                    enableCellEdit &&
                    cellEditFactory({ mode: "click", blurToSave: true })
                  }
                />
              </div>
            );
          }}
        </ToolkitProvider>
      )}
    </PaginationProvider>
  );
};

TableWithSearch.propTypes = {
  searchPlaceholder: PropTypes.string,
  noDataIndicator: PropTypes.string,
  keyField: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  totalSize: PropTypes.number.isRequired,
  sizePerPage: PropTypes.number.isRequired,
  onTableChange: PropTypes.func.isRequired,
  onSelectRow: PropTypes.object,
  selectedRows: PropTypes.array,
  showSearch: PropTypes.bool
};

TableWithSearch.defaultProps = {
  searchPlaceholder: "Search Ledger...",
  noDataIndicator: "No transaction found!",
  enableCellEdit: false,
  showSearch: true
};

export default TableWithSearch;
