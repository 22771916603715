import superagentPromise from "superagent-promise";
import _superagent from "superagent";

const superagent = superagentPromise(_superagent, global.Promise);
const API_ROOT = process.env.REACT_APP_API_ROOT;

const responseBody = res => res.body;

let token = null;
const tokenPlugin = req => {
  if (token) {
    req.set("authorization", `Token ${token}`);
  }
};

const omitSlug = data => {
  delete data.slug;
  return data;
};

const requests = {
  del: url =>
    superagent
      .del(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .then(responseBody),
  get: url =>
    superagent
      .get(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .then(responseBody),
  put: (url, body) =>
    superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody),
  post: (url, body) => {
    return superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .then(responseBody);
  },
  postWithFile: (url, files) => {
    let req = superagent.post(`${API_ROOT}${url}`);

    const isFileList =
      Object.prototype.toString.call(files) === "[object FileList]";
    let filesArray = [];

    if (isFileList) filesArray = Object.values(files);
    else if (Array.isArray(files)) filesArray = files;
    else filesArray.push(files);

    filesArray.forEach(file => req.attach("image", file));

    return req.use(tokenPlugin).then(responseBody);
  }
  // putWithFile: (url, body, fieldname, file) => {
  //   let req = superagent.post(`${API_ROOT}${url}`);
  //   console.log("tatatat", url, body);
  //   req.field('title',"This is freaking title");
  //   // Object.keys(body).forEach(key => req.field(key, body[key]));
  //   // if(file)
  //     // req.attach(fieldname, file[0]);
  //   return req.use(tokenPlugin).then(responseBody);
  // }
};

const Auth = {
  current: () => requests.get("/user"),
  login: userData => requests.post("/login", userData),
  signup: userData => requests.post("/users/register", userData),
  validateToken: () => {
    return requests.get("/checkToken");
  },
  activateUser: userData => {
    return requests.post("/checkactivationcode", userData);
  },
  userDetail: () => {
    return requests.get("/detail");
  },
  updateUserDetail: userData => {
    return requests.put("/detail", userData);
  },

  forgotpassword: data => requests.post("/forgotpassword", data),
  resetpassword: data => requests.post("/resetpassword", data)
};

const AssetRequests = {
  assetListing: () => requests.get("/users/assets_list"),
  saveAsset: assetData => {
    return requests.post("/assets/addProperty", { data: assetData });
  },
  assetDetailing: asset_id =>
    requests.get(`/assets/fetchAssetDetail/${asset_id}`),
  getCount: () => requests.get(`/assets/getAssetCount`),
  getDashboardOverview: () => {
    return requests.get("/assets/dashboardOverview");
  },
  saveRentToAsset: asset_data => {
    return requests.post(`/assets/${asset_data.asset_name}/addrent`, {
      data: asset_data
    });
  },
  rentDetailing: asset_id =>
    requests.get(`/assets/fetchRentDetail/${asset_id}`),
  updateAssetDetails: assetData =>
    requests.put(`/assets/${assetData.slug}/edit`, {
      data: omitSlug(assetData)
    }),
  addAssetContact: assetData =>
    requests.post(`/assets/${assetData.slug}/addcontact`, {
      data: omitSlug(assetData)
    }),
  deleteAssetContact: contactData =>
    requests.post(`/assets/${contactData.slug}/deletecontact`, {
      data: omitSlug(contactData)
    }),

  deleteAsset: slug => requests.del(`/assets/${slug}`),
  suggestContacts: () => requests.get(`/assets/suggestContacts`),
  addInsurance: insuranceDetails =>
    requests.get(`/assets/${insuranceDetails.slug}/addInsurance`)
};

const Upload = {
  saveAttachments: (data, fieldname, file) =>
    requests.postWithFile(
      `/assets/${data.slug}/attachments`,
      { data: omitSlug(data) },
      fieldname,
      file
    )
};

const Rets = {
  getAsset: address => {
    return requests.get(`/assets/fetchFromRets?address=${address}`);
  }
};

const Account = {
  getAccounts: () => requests.get(`/accounts`),
  addAccount: plaidData => requests.post(`/accounts/add`, plaidData)
  // deleteAccount:()=>requests.del(`/acco`)
};

const Resource = {
  addResource: resource => {
    return requests.post(`/resources`, resource);
  },

  getResources: () => requests.get("/resources"),
  updateResource: resource => {
    return requests.put(`/resources/${resource._id}`, resource);
  },
  deleteResource: id => requests.del(`/resources/${id}`),
  uploadImage: data => requests.postWithFile("/uploadimage", data)
};

const AdminRoutes = {
  getUserList: queryParams => requests.get(`/getUserList?${queryParams}`),
  deactivateUser: userData => requests.post(`/deactivateUser`, userData),
  deleteUser: userData => requests.post(`/deleteUser`, userData),
  addUser: userData => requests.post(`/addUser`, userData),
  getCMS: () => requests.get(`/cms/getCms`),
  putCMS: content => requests.post(`/putCms`, content),

  getAdminList: () => requests.get(`/getAdminList`),
  addAdmin: userData => requests.post(`/addAdmin`, userData),
  deactivateAdmin: userData => requests.post(`/deactivateAdmin`, userData),
  getUserInfo: email => requests.post(`/getUserInfo`, { email: email }),

  getInitialFeedback: data => requests.post(`/getInitialFeedback`, data),
  deleteSelectedFeedback: data =>
    requests.post(`/deleteSelectedFeedback`, { feedbacks: data }),
  changeFeedbackImp: data =>
    requests.post(`/changeFeedbackImp`, { feedback: data }),
  replyFeedback: data => requests.post(`/replyFeedback`, { feedback: data })
};

const Log = {
  getAssetLogs: () => requests.get("/getAssetLogs"),
  reviewAssetLog: logID => requests.put(`/reviewAssetLog/${logID}`),
  sendAssetEmail: logID => requests.put(`/sendAssetEmail/${logID}`)
};

const Asset = {
  addAsset: data => requests.post("/addAsset", { ...data }),
  getAllAssets: () => requests.get("/assets"),
  getUserAssets: userId => requests.get(`/users/${userId}/assets`),
  getAssetDetail: slug => requests.get(`/assets/${slug}`),
  getInvestmentInfo: slug => requests.get(`/assets/${slug}/investmentInfo`),
  updateInvestmentInfo: data =>
    requests.put(`/assets/${data.slug}/investmentInfo`, { data }),

  getBasicInfo: slug => requests.get(`/assets/${slug}/basicInfo`),
  updateBasicInfo: data => {
    return requests.put(`/assets/${data.slug}/basicInfo`, { data });
  }
};

const Financial = {
  getAssetFinancials: slug => requests.get(`/assets/${slug}/financials`),
  addFinancial: data =>
    requests.post(`/assets/${data.slug}/financials`, { data })
};

const Deal = {
  getSubmittedDeals: () => requests.get(`/submitted-deals/`),
  approveDeal: data => requests.put(`/submitted-deals/${data.slug}/approve`),
  denyDeal: data => requests.put(`/submitted-deals/${data.slug}/deny`),
  updateMonthlyDeal: data =>
    requests.put(`/submitted-deals/${data.slug}/monthlyDeal`)

  // changeDealStatus: (data) => requests.post(`/changeDealStatus/${data.slug}/`,{...data}),
};

const Partner = {
  getPartnerDetail: userId => requests.get(`/partner/${userId}`),
  getPartnerIntegrations: data =>
    requests.get(`/partner/${data.userId}/integrations`),
  addPartnerIntegration: data =>
    requests.post(`/partner/${data.userId}/integrations`, data),
  changePartnersApproveStatus: data =>
    requests.post(`/partner/changePartnersApprove`, data),
  getPartnersList: () => requests.get(`/partner/getPartnersList`)
};

const User = {
  getClientDetail: id => requests.get(`/users/${id}`)
};

const setAuthToken = _token => {
  token = _token;
};

export {
  Auth,
  setAuthToken,
  AssetRequests,
  Rets,
  Upload,
  Account,
  AdminRoutes,
  Resource,
  Log,
  Asset,
  Financial,
  User,
  Deal,
  Partner
};
