import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
// import SidebarCategory from './SidebarCategory';

class SidebarContent extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  render() {
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          {/* <SidebarLink title="Dashboard" icon="home" route="/users" onClick={this.hideSidebar} /> */}
          <SidebarLink title="Assets" icon="apartment" route="/assets" onClick={this.hideSidebar} />
          <SidebarLink title="Admins" icon="user" route="/admins" onClick={this.hideSidebar} />
          <SidebarLink title="Users" icon="users" route="/users" onClick={this.hideSidebar} />
          <SidebarLink title="Resources" icon="exit" route="/resources" onClick={this.hideSidebar} />
          <SidebarLink title="Submitted Deals" icon="exit" route="/submitted-deals" onClick={this.hideSidebar} />

          {/* <SidebarLink title="Blogs" icon="exit" route="/blogs" onClick={this.hideSidebar} /> */}
          {/* <SidebarLink title="Videos" icon="exit" route="/videos" onClick={this.hideSidebar} /> */}
          {/* <SidebarLink title="Webinars" icon="exit" route="/webinars" onClick={this.hideSidebar} /> */}
          <SidebarLink title="CMS" icon="code" route="/cms" onClick={this.hideSidebar} />
          <SidebarLink title="Asset Logs" icon="list" route="/asset-logs" onClick={this.hideSidebar} />
          <SidebarLink title="Partners" icon="list" route="/partners" onClick={this.hideSidebar} />
          <SidebarLink title="Feedbacks" icon="envelope" route="/feedbacks" onClick={this.hideSidebar} />
        </  ul>
{/*         <ul className="sidebar__block">
          <SidebarCategory title="Example Pages" icon="diamond">
            <SidebarLink title="Page one" route="/pages/one" onClick={this.hideSidebar} />
            <SidebarLink title="Page two" route="/pages/two" onClick={this.hideSidebar} />
          </SidebarCategory>
        </ul> */}
      </div>
    );
  }
}

export default SidebarContent;
