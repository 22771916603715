import React from 'react';
import ReactDOM from 'react-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/css/bootstrap-grid.min.css';
import '../node_modules/bootstrap/dist/css/bootstrap-reboot.min.css';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './store';

import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';


// import registerServiceWorker from './registerServiceWorker';
require('dotenv').config();

ReactDOM.render(  <Provider store={store}><App/></Provider>, document.getElementById('root'));
// registerServiceWorker();

