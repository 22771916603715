export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const REMOVE_CURRENT_USER = 'REMOVE_CURRENT_USER';

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

export const GOOGLE_LOGIN_REQUEST = 'GOOGLE_LOGIN_REQUEST';
export const GOOGLE_LOADER_START = 'GOOGLE_LOADER_START';
export const GOOGLE_LOADER_END = 'GOOGLE_LOADER_END';



export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';



export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const ASSET_LIST_REQUEST = 'ASSET_LIST_REQUEST';
export const ASSET_LIST_SUCCESS = 'ASSET_LIST_SUCCESS';
export const ASSET_LIST_FAILURE = 'ASSET_LIST_FAILURE';

export const GET_USER_DETAIL = 'GET_USER_DETAIL';
export const SET_CURRENT_USER_DETAIL = 'SET_CURRENT_USER_DETAIL';

export const UPDATE_USER_DETAIL = 'UPDATE_USER_DETAIL';
export const UPDATE_USER_DETAIL_SUCCESS = 'UPDATE_USER_DETAIL_SUCCESS';
export const UPDATE_USER_DETAIL_FAILURE = 'UPDATE_USER_DETAIL_FAILURE';




