import React, {Component} from "react";
import Modal from "react-responsive-modal";
import {connect} from "react-redux";
import {Formik} from "formik";
import DatePicker from "react-datepicker";
import TagsInput from "react-tagsinput";
import moment from "moment-timezone";

import "./tags.css";
import * as Yup from "yup";

import {Row, Col} from "reactstrap";
import FileInput from "./FileInput";
import {
  addResource,
  updateResource,
  uploadImage
} from "../../../actions/resource";

import "../resource-style.css";

let defaultValues = {
  type: "webinar",
  title: "",
  description: "",
  url: "",
  thumbnail: "",
  owner: "",
  expiry: "",
  startTime: "",
  endTime: "",
  tags: [],
  location: "",
  isPending: false
};

const validationSchema = Yup.object({
  type: Yup.string("").required("Select type of resource"),
  title: Yup.string("").required("Title is required"),
  description: Yup.string("").required("Description is required"),
  url: Yup.string("").required("Link is required"),
  thumbnail: Yup.mixed().required("Select a thumbnail"),
  tags: Yup.mixed(),
  location: Yup.string().when("type", {
    is: type => type === "live",
    then: Yup.string().required("Venue is required"),
    otherwise: Yup.string().nullable()
  }),
  expiry: Yup.date().when("type", {
    is: type => type === "webinar",
    then: Yup.date().required("Expiry date is required"),
    otherwise: Yup.date().nullable()
  }),
  publishDate: Yup.date().when("type", {
    is: type => type === "blog" || type === "video",
    then: Yup.date().required("Publish date is required"),
    otherwise: Yup.date()
  }),

  durationInMinutes: Yup.number().when("type", {
    is: type => type === "webinar" || type === "live",
    then: Yup.date().required("Duration is required"),
    otherwise: 0
  }),
  isPending: Yup.bool()
});

class AddResource extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      uploadingImage: false,
      error: null,
      fileError: null,
      displayUrl: "",
      imageName: ""
    };
  }

  resetForm;
  setFieldValue;

  handleSubmit = values => {
    const update = this.props.update;
    const timezone = moment.tz.guess(true);
    values.timezone = timezone;

    if (update) {
      this.setState({loading: true, error: false});
      this.props.updateResource(values);
    } else {
      this.setState({loading: true, error: false});
      this.props.addResource(values);
    }
    return;
  };

  handleFileError = msg => {
    this.setState({fileError: msg});
  };

  handleTagChange = tags => {
    this.setState({tags});
  };

  closeModal = () => {
    this.resetForm({});
    this.setState({
      loading: false,
      error: null,
      fileError: null,
      displayUrl: null,
      imageName: null
    });
    this.props.closeModal(null);
  };

  handleUploadImage = files => {
    this.setState({uploadingImage: true, error: false, fileError: null});
    this.props.uploadImage(files);
  };

  componentWillReceiveProps(nextProps) {
    if (this.state.loading && nextProps !== this.props) {
      const {resourceProp} = nextProps;
      if (resourceProp.addResourceSuccess === true) {
        this.closeModal();
      } else if (resourceProp.addResourceSuccess === false) {
        this.setState({loading: false, error: resourceProp.error});
      }

      if (resourceProp.updateResourceSuccess) {
        this.closeModal();
      } else {
        this.setState({loading: false, error: resourceProp.error});
      }
    }

    if (this.state.uploadingImage && nextProps !== this.props) {
      const {uploadProp} = nextProps;
      if (uploadProp.uploadImageSuccess === true) {
        this.setFieldValue("thumbnail", uploadProp.imageName);
        this.setState({
          uploadingImage: false,
          error: null,
          displayUrl: uploadProp.displayUrl,
          imageName: uploadProp.imageName
        });
      } else if (uploadProp.uploadImageSuccess === false) {
        this.setState({
          uploadingImage: false,
          error: uploadProp.error,
          displayUrl: null,
          imageName: null
        });
      }
    }
  }

  render() {
    let {displayUrl, imageName, loading, uploadingImage} = this.state;
    const updateData = this.props.update;
    const isLoading = loading || uploadingImage;
    let initialValues = defaultValues;

    if (updateData) {
      initialValues = {...updateData};
    }

    let $imagePreview = null;
    if (displayUrl && imageName) {
      $imagePreview = (
        <img src={displayUrl} alt="Preview" width="100" height="150" />
      );
    } else if (updateData && updateData.displayUrl) {
      $imagePreview = (
        <img
          src={updateData.displayUrl}
          alt="Preview"
          width="100"
          height="150"
        />
      );
    } else {
      $imagePreview = <div>Please select an Image for Preview</div>;
    }

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
        onReset={this.closeModal}
        render={props => {
          const {
            values,
            errors,
            handleSubmit,
            handleChange,
            handleReset,
            setValues,
            setFieldValue,
            touched,
            resetForm
          } = props;
          const {modalOpen} = this.props;
          // need to reset the form, when form submitted successfully
          this.resetForm = resetForm;
          this.setFieldValue = setFieldValue;
          console.log(values, "------");
          console.log("errros", errors);
          return (
            <Modal
              open={modalOpen}
              center
              closeOnOverlayClick={false}
              showCloseIcon={false}
            >
              <h4>
                {updateData ? "Update" : "Add New"} {values.type}
              </h4>
              <p>{this.state.error}</p>
              <form onSubmit={handleSubmit} onReset={handleReset}>
                <Row>
                  <Col md={12} xs={12}>
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <label htmlFor="formGroupTitleInput">Title</label>
                          <input
                            type="text"
                            class="form-control"
                            id="formGroupTitleInput"
                            placeholder="Title"
                            name="title"
                            value={values.title}
                            onChange={handleChange}
                          />
                          <div className="error-message">
                            {touched.title && errors.title}
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label htmlFor="exampleFormControlSelect1">
                            Resource type
                          </label>
                          <select
                            class="form-control"
                            id="exampleFormControlSelect1"
                            name="type"
                            value={values.type}
                            onChange={handleChange}
                          >
                            <option value="webinar">Webinar</option>
                            <option value="video">Video</option>
                            <option value="blog">Blog</option>
                            <option value="live">Live</option>
                          </select>
                          <div className="error-message">
                            {touched.type && errors.type}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                {/* </Row> */}
                <Row>
                  <Col md={6} xs={6}>
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <label htmlFor="formGroupExampleInput">
                            Description
                          </label>
                          <textarea
                            type="text"
                            class="form-control"
                            rows="2"
                            placeholder="Description for the resource"
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                          />
                          <div className="error-message">
                            {touched.description && errors.description}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} lg={6}>
                    <div class="form-group">
                      <label htmlFor="formGroupTitleInput">Link</label>
                      <input
                        type="text"
                        class="form-control"
                        id="formGroupTitleInput"
                        placeholder="Link to the resource"
                        name="url"
                        value={values.url}
                        onChange={handleChange}
                      />
                      <div className="error-message">
                        {touched.url && errors.url}
                      </div>
                    </div>
                  </Col>
                  <Col md={6} lg={6}>
                    <div class="form-group">
                      <label htmlFor="formGroupTitleInput">Thumbnail</label>
                      <FileInput
                        onSuccess={files => {
                          this.handleUploadImage(files);
                        }}
                        onError={this.handleFileError}
                        extensions={["jpg", "png", "jpeg"]}
                        multiple={false}
                      />
                      <div className="error-message">
                        {errors.thumbnail || this.state.fileError}
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  {(values.type === "blog" || values.type === "video") && (
                    <Col md={6} lg={6}>
                      <div className="form-group">
                        <label htmlFor="formGroupTitleInput">
                          Publish Date
                        </label>
                        <DatePicker
                          className="form-control"
                          dateFormat="dd/MM/yyyy"
                          onChange={e => {
                            const newValues = {...values};
                            newValues["publishDate"] = e;
                            setValues(newValues);
                          }}
                          name="publishDate"
                          selected={
                            values.publishDate
                              ? new Date(values.publishDate)
                              : null
                          }
                          placeholderText={"Select date"}
                        />
                        <div className="error-message">
                          {touched.publishDate && errors.publishDate}
                        </div>
                      </div>
                    </Col>
                  )}
                  {(values.type === "webinar" || values.type === "live") && (
                    <Col md={6} lg={6}>
                      <div class="form-group">
                        <label htmlFor="formGroupTitleInput">Expiry Date</label>
                        <DatePicker
                          className="form-control"
                          dateFormat="dd/MM/yyyy h:mm aa"
                          onChange={e => {
                            const newValues = {...values};
                            newValues["expiry"] = e;
                            setValues(newValues);
                          }}
                          name="expiry"
                          selected={
                            values.expiry ? new Date(values.expiry) : null
                          }
                          placeholderText={"Select date"}
                          minDate={new Date()}
                        />
                        <div className="error-message">
                          {touched.expiry && errors.expiry}
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col md={6} lg={6}>
                    {$imagePreview}
                  </Col>
                </Row>
                {(values.type === "webinar" || values.type === "live") && (
                  <Row>
                    <Col md={6}>
                      <div class="form-group">
                        <label htmlFor="formGroupTitleInput">Venue</label>
                        <input
                          type="text"
                          class="form-control"
                          id="formGroupTitleInput"
                          placeholder="Location"
                          name="location"
                          value={values.location}
                          onChange={handleChange}
                        />
                        <div className="error-message">
                          {touched.location && errors.location}
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col md={6}>
                    <div class="form-group">
                      <label htmlFor="formGroupTitleInput">Tags</label>
                      <TagsInput
                        value={values.tags || []}
                        onChange={tags => {
                          const newValues = {...values};
                          newValues["tags"] = tags;
                          setValues(newValues);
                        }}
                        placeholder={"Press tab to add multiple tags"}
                      />
                    </div>
                  </Col>
                  {updateData && (
                    <Col md={6}>
                      <div class="form-group">
                        <label htmlFor="formGroupTitleInput">Status</label>
                        <div class="form-group">
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="isPending"
                              id="inlineRadio1"
                              value={"false"}
                              checked={
                                values.isPending === "false" ||
                                values.isPending === false
                              }
                              onChange={handleChange}
                            />
                            <label class="form-check-label" for="inlineRadio1">
                              Approve
                            </label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="isPending"
                              id="inlineRadio2"
                              value={"true"}
                              checked={
                                values.isPending === "true" ||
                                values.isPending === true
                              }
                              onChange={handleChange}
                            />
                            <label class="form-check-label" for="inlineRadio2">
                              Pending
                            </label>
                          </div>
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>
                <div className="btn-toolbar float-right" role="toolbar">
                  <div className="btn-group mr-2 text-right" role="group">
                    <button
                      className="btn btn-primary btn-sm"
                      type="reset"
                      disabled={isLoading}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="btn-group" role="group">
                    <button
                      className="btn btn-danger btn-sm"
                      type="submit"
                      disabled={isLoading}
                    >
                      {"Save"}
                    </button>
                  </div>
                </div>
              </form>
            </Modal>
          );
        }}
      />
    );
  }
}

const mapStateToProps = state => ({
  resourceProp: state.resource,
  uploadProp: state.upload
});

const mapDispatchToProps = dispatch => ({
  addResource: data => addResource(data, dispatch),
  updateResource: data => updateResource(data, dispatch),
  uploadImage: data => uploadImage(data, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddResource);
