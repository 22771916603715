import React, { PureComponent } from 'react';
import {
  Card, 
  Col, 
  Container, 
  Row, 
  CardBody, 
  Nav, 
  NavItem, 
  NavLink, 
  TabContent, 
  TabPane,
} from 'reactstrap';
import { connect } from 'react-redux';
import classnames from 'classnames';
import Financial from './Financial';
import InvestmentInfo from './InvestmentInfo';
import BasicInfo from './BasicInfo';
import { getAssetDetail } from '../../actions/asset';
import holderImage from '../../images/property_placeholder.jpg';

class Asset extends PureComponent {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
      loading: true,
      error: null,
    };
  }

  componentWillMount() {
    const { match, getAssetDetail } = this.props;
    const slug = match.params.slug;
    getAssetDetail(slug);
  }


  componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    if (nextProps.assetProp.getAssetDetailSuccess === true)
      this.setState({ loading: false, error: null });
    else if (nextProps.assetProp.getAssetDetailSuccess === false)
      this.setState({ loading: false, error: "Problem while loading assets" });
  }

  toggle(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const { activeTab } = this.state;
    const { assetDetail } = this.props.assetProp;
    return (
      <Container className="dashboard">
        <Row>
          <Col md={12} lg={12} xl={12}>
            <Card>
              <CardBody className="profile__card">
                { this.state.error && <div className="profile__information">
                  <p>Error loading data</p>
                </div> }
                {!this.state.loading && !this.state.error &&
                  <React.Fragment>
                    <div className="profile__information">
                      <div className="profile__avatar">
                        <img src={assetDetail.image} alt="avatar"  onError={e => {
                          e.target.src = holderImage;
                        }} />
                      </div>
                      <div className="profile__data">
                        <p className="profile__name">{assetDetail.asset_name}</p>
                        <p className="profile__contact">Address: {assetDetail.address}</p>
                        <p className="profile__contact">ParcelId: {assetDetail.parcelId}</p>
                        <p className="profile__contact">APN: {assetDetail.apn}</p>
                        <p className="profile__contact">Owners: {assetDetail.owners || "NA"}</p>
                      </div>
                    </div>
                    <div className="profile__stats">
                      <div className="profile__stat">
                        <p className="profile__stat-number">$ {assetDetail.stats.cashflow.currMonth}</p>
                        <p className="profile__stat-title">Cashflow</p>
                      </div>
                      <div className="profile__stat">
                        <p className="profile__stat-number">$ {assetDetail.stats.noi.currMonth}</p>
                        <p className="profile__stat-title">NOI</p>
                      </div>
                      <div className="profile__stat">
                        <p className="profile__stat-number">$ {assetDetail.stats.cocROI}</p>
                        <p className="profile__stat-title">COC ROI</p>
                      </div>
                    </div>
                  </React.Fragment>}
                {this.state.loading && <div className="profile__information">
                <p>Loading</p>
                </div>}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={12} xl={12}>
            <Card>
              <div className="profile__card tabs tabs--bordered-bottom">
                <div className="tabs__wrap">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => {
                          this.toggle('1');
                        }}
                      >
                        Basic Info
                  </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => {
                          this.toggle('2');
                        }}
                      >
                        Investment Info
                  </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === '3' })}
                        onClick={() => {
                          this.toggle('3');
                        }}
                      >
                        Financials
                  </NavLink>
                    </NavItem>

                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <BasicInfo {...this.props}/>
                    </TabPane>
                    <TabPane tabId="2">
                      <InvestmentInfo {...this.props}/>
                    </TabPane>
                    <TabPane tabId="3">
                      <Financial {...this.props}/>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  assetProp: state.asset,
});
const mapDispatchToProps = (dispatch) => ({
  getAssetDetail: (slug) => getAssetDetail(slug, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Asset);
