import { 
  GET_SUBMITTED_DEALS, 
  APPROVE_DEAL,
  DENY_DEAL,
  UPDATE_MONTHLY_DEAL,
} from '../actionTypes';

export const getSubmittedDeals = (dispatch) => {
  dispatch({
      type: GET_SUBMITTED_DEALS,
  });
};

export const denyDeal = (data, dispatch) => {
  dispatch({
    type: DENY_DEAL,
    payload: data,
  });
}

export const approveDeal = (data, dispatch) => {
  dispatch({
    type: APPROVE_DEAL,
    payload: data,
  });
}


export const updateMonthlyDeal = (data, dispatch) => {
  dispatch({
    type: UPDATE_MONTHLY_DEAL,
    payload: data,
  });
}