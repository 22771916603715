import {
  FETCH_USER_LIST,
  DEACTIVATE_USER,
  ADD_USER,
  ADD_ADMIN,
  FETCH_ADMIN_LIST,
  DEACTIVATE_ADMIN,
  FETCH_USER_INFO,
  DELETE_USER
} from "../actionTypes";

export const getUserList = (params, dispatch) => {
  dispatch({
    type: FETCH_USER_LIST,
    payload: params
  });
};

export const deactivateUser = (userData, dispatch) => {
  dispatch({
    type: DEACTIVATE_USER,
    payload: userData
  });
};
// On Hold: donot delete
export const deleteUser = (userData, dispatch) => {
  dispatch({
    type: DELETE_USER,
    payload: userData
  });
};

//add new user
export const addUser = (userData, dispatch) => {
  dispatch({
    type: ADD_USER,
    payload: userData
  });
};

//get admin list
export const getAdminList = dispatch => {
  dispatch({
    type: FETCH_ADMIN_LIST
  });
};

//add new admin
export const addAdmin = (userData, dispatch) => {
  dispatch({
    type: ADD_ADMIN,
    payload: userData
  });
};

export const deactivateAdmin = (userData, dispatch) => {
  dispatch({
    type: DEACTIVATE_ADMIN,
    payload: userData
  });
};

export const getUserInfo = (email, dispatch) => {
  dispatch({
    type: FETCH_USER_INFO,
    payload: email
  });
};
