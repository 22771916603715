import React, { Component, Fragment } from "react";
import { Formik, Field } from "formik";

import * as Yup from "yup";
import { ModalBody, ModalFooter, Button } from "reactstrap";
import { addPartnerIntegration } from "../../actions/partner";
import { connect } from "react-redux";
import FormField from "../../shared/components/form/FormField";

import { isFetching, isSuccess } from "../../reducers/reducerUtils";

const validationSchema = Yup.object({
  corpId: Yup.string("").required("Corp Id is required")
});

class RentManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      error: null
    };
  }

  saveIntegration = values => {
    this.setState({ saving: true, error: null }, () => {
      const { user, addPartnerIntegration } = this.props;
      addPartnerIntegration({
        type: "rent_manager",
        options: values,
        userId: user.userId
      });
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { integrations } = nextProps;
    if (
      prevState.saving &&
      (integrations.type === "ADD" || integrations.type === "UPDATE")
    ) {
      if (!isFetching(integrations)) {
        if (isSuccess(integrations)) {
          return {
            saving: false,
            error: ""
          };
        }
      }
    }

    return null;
  }

  render() {
    const { saving } = this.state;
    const { data } = this.props;
    let initialValues = {
      corpId: "",
      lastOwnerSync: "",
      ownerIds: "all"
    };
    if (data) initialValues = data.options;

    console.log("---111---->>>>", initialValues);

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={this.saveIntegration}
        render={props => {
          const { handleSubmit } = props;
          return (
            <Fragment>
              <ModalBody>
                <form onSubmit={handleSubmit}>
                  <div className="form-group inputblock">
                    <label htmlFor="exampleInputEmail1">Corp Id:</label>
                    <Field name="corpId" component={FormField} />
                  </div>
                  <ModalFooter>
                    <Button
                      color="primary"
                      className="btn savebtns btn-primary px-5  d-inline-block mx-2"
                      type="submit"
                      disabled={saving}
                    >
                      Update
                    </Button>
                  </ModalFooter>
                </form>
              </ModalBody>
            </Fragment>
          );
        }}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  addPartnerIntegration: data => addPartnerIntegration(data, dispatch)
  // updateIntegration: data => updateIntegration(dispatch, data)
});

export default connect(
  null,
  mapDispatchToProps
)(RentManager);
