import {
  UPLOAD_IMAGE_FAILURE,
  UPLOAD_IMAGE_SUCCESS,
} from '../actions/actionTypes';

const initialState = {
  url: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPLOAD_IMAGE_SUCCESS:
      return {
        displayUrl: action.payload.displayUrl,
        imageName: action.payload.imageName,
        uploadImageSuccess: true,
      }
    case UPLOAD_IMAGE_FAILURE:
      return {
        uploadImageSuccess: false,
        error:'Unable to upload Image'
      }
    default:
      return state;
  }
}