
import React from 'react';

const RadioButtonGroup = ({
  value,
  error,
  touched,
  id,
  label,
  className,
  children
}) => {
  return (
    <div className={"form__form-group-input-wrap form__form-group-input-wrap--error-above"}>
      <div className={"form__form-group"}>
        <span className={"form__form-group-label"}>{label}</span>
        {children}
        {touched &&
          error && <span className="form__form-group-error">{error}</span>}
      </div>
    </div>
  );
};

export default RadioButtonGroup;
