import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from "yup";

import { Modal, Row, Col } from 'reactstrap';

import { addAsset } from '../../actions/asset';


let initialValues = {
  email: '',
  address:""
};

const validationSchema = Yup.object({
  address: Yup.string().required("Address is required"),
});

class AddAsset extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
    };
  }

  resetForm;
  handleSubmit = (values) => {
    this.setState({ loading: true, error: null }, () => {
      let v = values;
      // const addr = v.addresses[0];
      // v.address = addr;
      this.props.addAsset(v);
    });
  }


  closeModal = () => {
    this.resetForm({});
    this.setState({error: null})
    this.props.closeModal(null);
  }

  addMoreAddress = () => {

  }

  componentWillReceiveProps(nextProps) {
    if (this.state.loading) {
      const assetProp = nextProps.assetProp;
      // console.log(assetProp,"0000000");
      if (assetProp.addAssetSuccess === true) {
        this.setState({ loading: false, error: null },()=>{
          this.closeModal();

        });
      } else if(assetProp.addAssetSuccess === false) {
        this.setState({ loading: false, error: assetProp.error });
      }
    }
  }

  render() {
    initialValues = {...initialValues, ...this.props.selectedItem}
    // console.log()
    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
        onReset={this.closeModal}
        render={props => {
          const { values, handleSubmit, handleChange, handleReset, resetForm, errors } = props;
          const { modalOpen } = this.props;
          const { loading } = this.state;
          this.resetForm = resetForm;
          return (
            <Modal isOpen={modalOpen} className={"modal-dialog--primary modal-dialog--header"}>
              <div className="modal__header">
                <h4 className="bold-text  modal__title">Add Asset</h4>
              </div>
              <div className="modal__body">
                {this.state.error && <p style={{color:'red'}}>{this.state.error}</p> }
                <form onSubmit={handleSubmit} onReset={handleReset}>
                  {errors.address && <p> All fields are required </p>}
                  <div className="form__form-group">
                    <span className="form__form-group-label">User Email:    {values.email}</span>
                  </div>
                  <span className="form__form-group-label">Asset Address</span>
                  <Row>
                    <Col md={12}>
                      <div className="form__form-group">
                        <div className="form__form-group-field">
                          <input type="text" className="form-control" placeholder="Address" name={"address"} value={values.address} onChange={handleChange} />
                        </div>
                      </div>
                    </Col>
                  
                  </Row>
                  <div className="btn-toolbar float-right" role="toolbar" >
                    <div className="btn-group mr-2 text-right" role="group" >
                      <button className="btn btn-danger" type="reset" disabled={loading}>Cancel</button>
                    </div>
                    <div className="btn-group" role="group" >
                      <button className="btn btn-primary" type="submit" disabled={loading}>{loading ? 'Saving' : 'Save'}</button>
                    </div>
                  </div>
                </form>
              </div>
            </Modal>)
        }}></Formik>)
  }
}

const mapStateToProps = state => ({
  assetProp: state.asset,
});

const mapDispatchToProps = dispatch => ({
  addAsset: (data) => addAsset(data, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddAsset);