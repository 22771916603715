// import PropTypes from 'prop-types';
import React from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const currencyMask = createNumberMask({
  prefix: '$',
  allowDecimal: true,
})

const CurrencyField = ({
  type, field, form: { touched, errors }, ...props
}) => (
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <MaskedInput
      {...field}
      mask={currencyMask}
      type={type}
      {...props}
      className="form-control"
    />
    {touched[field.name] &&
      errors[field.name] && <span className="form__form-group-error">{errors[field.name]}</span>}
  </div>
);

export default CurrencyField;
