
import { setAuthToken } from '../../api/agent';

import {
    LOGIN_REQUEST,
    SIGNUP_REQUEST,
    LOGOUT_REQUEST,
    GOOGLE_LOGIN_REQUEST,
    FORGOT_PASSWORD_REQUEST,
    RESET_PASSWORD_REQUEST,
    GET_USER_DETAIL,
    UPDATE_USER_DETAIL

} from './authActionTypes';

export const signup = (userData, dispatch) => {
    dispatch({
        type: SIGNUP_REQUEST,
        payload: userData,
    });
};

export const login = (userData, dispatch) => {
    dispatch({
        type: LOGIN_REQUEST,
        payload: userData,
    });
};

//call this function with dispatch(logout());
export const logout = (dispatch) => {
    // Remove token from localStorage
    localStorage.removeItem('token');
    // Remove auth header for future requests
    setAuthToken(null);
    // Set current user to {} which will set isAuthenticated to false
    dispatch({
        type: LOGOUT_REQUEST
    });
};

export const logoutMalformed = () => {
    // Remove token from localStorage
    localStorage.removeItem('token');
    // Remove auth header for future requests
    setAuthToken(null);
    // Set current user to {} which will set isAuthenticated to false
};

export const googleLogin = (gtoken, dispatch) => {

    dispatch({
        type: GOOGLE_LOGIN_REQUEST,
        payload: gtoken,
    });
};
export const forgotpassword = (userData, dispatch) => {
    dispatch({
        type: FORGOT_PASSWORD_REQUEST,
        payload: userData,
    });
};

export const resetpassword = (userData, dispatch) => {
    dispatch({
        type: RESET_PASSWORD_REQUEST,
        payload: userData,
    });
};

export const getUserDetail = (dispatch) => {
    dispatch({
        type: GET_USER_DETAIL,
    });
};

export const updateUser = (userData, dispatch) => {
    dispatch({
        type: UPDATE_USER_DETAIL,
        payload: userData
    })
};