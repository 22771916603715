import { fork, all } from "redux-saga/effects";

import {
  watchLogin,
  watchSignup,
  watchForgotPassword,
  watchResetPassword,
  watchGoogleLogin,
  watchUserDetail,
  watchUpdateUserDetail
} from "./authSagas";

import {
  watchGetUserList,
  watchDeactivateUser,
  watchDeleteUser,
  watchAddUser,
  watchPutCMS,
  watchGetCMS,
  watchGetAdminList,
  watchAddAdmin,
  watchDeactivateAdmin,
  watchGetUserInfo,
  watchGetInitialFeedback,
  watchDeleteSelectedFeedback,
  watchChangeFeedbackImp,
  watchReplyFeedback,
  watchGetClientDetail
} from "./userSagas";

import {
  watchAddResource,
  watchGetResources,
  watchUpdateResource,
  watchDeleteResource,
  watchUploadImage
} from "./resourceSaga";

import {
  watchGetAssetLogs,
  watchReviewAssetLog,
  watchSendAssetEmail
} from "./assetlogSagas";

import {
  watchAddAsset,
  watchGetAllAssets,
  watchGetUserAssets,
  watchGetAssetDetail,
  watchGetInvestmentInfo,
  watchUpdateInvestmentInfo,
  watchUpdateBasicInfo,
  watchGetBasicInfo,
  watchGetAssetFinancials,
  watchGetAddFinancial
} from "./assetSagas";

import {
  watchGetSubmittedDeals,
  watchApproveDeal,
  watchDenyDeal,
  watchUpdateMonthlyDeal
} from "./submittedDealsSagas";

import {
  watchGetPartnerDetail,
  watchChangePartnersApproveStatus,
  watchFetchPartnersList,
  watchAddPartnerIntegration,
  watchGetPartnerIntegrations
} from "./partnerSagas";

export default function* rootSaga() {
  yield all([
    fork(watchLogin),
    fork(watchSignup),
    fork(watchGoogleLogin),
    fork(watchForgotPassword),
    fork(watchResetPassword),

    //admin's user section
    fork(watchGetUserList),
    fork(watchDeactivateUser),
    fork(watchDeleteUser),
    fork(watchAddUser),
    fork(watchGetCMS),
    fork(watchPutCMS),

    fork(watchUserDetail),
    fork(watchUpdateUserDetail),
    //admin's admin section
    fork(watchGetAdminList),
    fork(watchAddAdmin),
    fork(watchDeactivateAdmin),
    fork(watchGetUserInfo),
    fork(watchGetInitialFeedback),
    fork(watchDeleteSelectedFeedback),
    fork(watchChangeFeedbackImp),
    fork(watchReplyFeedback),

    fork(watchAddResource),
    fork(watchGetResources),
    fork(watchDeleteResource),
    fork(watchUpdateResource),
    fork(watchGetAssetLogs),
    fork(watchReviewAssetLog),
    fork(watchSendAssetEmail),
    fork(watchAddAsset),
    fork(watchGetAllAssets),
    fork(watchGetUserAssets),
    fork(watchGetAssetDetail),
    fork(watchGetInvestmentInfo),
    fork(watchUpdateInvestmentInfo),
    fork(watchUpdateBasicInfo),
    fork(watchGetBasicInfo),
    fork(watchGetAssetFinancials),
    fork(watchGetAddFinancial),
    fork(watchGetClientDetail),
    fork(watchUploadImage),
    fork(watchGetSubmittedDeals),
    fork(watchDenyDeal),
    fork(watchApproveDeal),
    fork(watchUpdateMonthlyDeal),

    fork(watchGetPartnerDetail),
    fork(watchChangePartnersApproveStatus),
    fork(watchFetchPartnersList),

    fork(watchGetPartnerIntegrations),
    fork(watchAddPartnerIntegration)
  ]);
}
