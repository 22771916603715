import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import { connect } from 'react-redux';
import {
    Col,
    Row,
    Button,
    FormGroup,
    Label,
    Input,
    Form,
    FormText,
} from 'reactstrap';
import { addAdmin } from '../../../actions/admin/userActions';

class AddUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: {
                isValid: false,
                isTouched: false,
                value: ''
            },
            email: {
                isValid: false,
                isTouched: false,
                value: ""
            },
            password: {
                isValid: false,
                isTouched: false,
                value: ""
            },
            loading: false,
            serverResp: ''
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();
        console.log("ssss", this.state)
        let isFormValid = this.state.password.isValid &&
            this.state.name.isValid &&
            this.state.email.isValid;
        if (isFormValid) {
            let dataToUpdate = {
                name: this.state.name.value,
                email: this.state.email.value,
                password: this.state.password.value,
            };
            this.props.addAdmin(dataToUpdate);
            this.setState({ loading: true })
        } else {
            this.setState({
                name: {
                    ...this.state.name,
                    isValid: this.state.name.isValid,
                    isTouched: true,
                },
                email: {
                    ...this.state.email,
                    isValid: this.state.email.isValid,
                    isTouched: true,
                },
                password: {
                    ...this.state.password,
                    isValid: this.state.password.isValid,
                    isTouched: true
                }
            });
        }
    }

    handleOnChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ ...this.state,
             [name]: { ...this.state[name],
                 value: value, isTouched: true } }, () => {
            this.validateField(name, value);
        });

    }

    validateField(fieldName, value) {
        const currentFieldState = this.state[fieldName];
        switch (fieldName) {
            case 'name':
                currentFieldState.isValid = value ? value.trim().length > 0 : true;
                break;
            case 'email':
                currentFieldState.isValid = RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i).test(value);
                break;
            case 'password':
                currentFieldState.isValid = value ? value.trim().length >= 6 : true;
                break;
            default:
                break;
        }

        this.setState({
            [fieldName]: currentFieldState,
        });
    }
    componentWillReceiveProps(nextProps) {
        if (this.state.loading) {
            if (nextProps.adminUser.addAdminSuccess) {
                this.setState({ loading: false });
                this.props.getAdminList();
                this.props.toggleFxn();
                this.setState({
                    name: '',
                    email: '',
                    password: ''
                })
            }
            if (!nextProps.adminUser.addUserSuccess) {
                // this.props.
                this.setState({
                    loading: false,
                    serverResp: nextProps.adminUser.serverResp
                }, () => {
                });
            }
        }
    }

    closeModal = () =>{
        console.log("ssssssssssssss", this.state)
        this.setState({
            name: {
                isValid: false,
                isTouched: false,
                value: ''
            },
            email: {
                isValid: false,
                isTouched: false,
                value: ""
            },
            password: {
                isValid: false,
                isTouched: false,
                value: ""
            },
            loading: false,
            serverResp: ''
        },()=>{
            console.log("ssssss", this.state)

        })
        this.props.toggleFxn()
    }
    render() {
        const {
            email,
            password,
            name,
        } = this.state;
        return (
            <Modal open={this.props.isActive || false} onClose={this.closeModal} center>
                <h4>Add New Admin</h4>
                <hr />
                <Form>
                    <Row form>
                        <Col md={12} xs={12}>
                            {this.state.serverResp &&
                                <div className="error-msg text-center">
                                    <p className="error-msg">{this.state.serverResp}</p>
                                </div>
                            }
                            <FormGroup>
                                <Label for="exampleState">Name:</Label>
                                <Input type="text" name="name" onChange={this.handleOnChange} value={name.value} />
                                {(name.isTouched && !name.isValid) && <FormText color="danger">Name is Required</FormText>}

                            </FormGroup>
                        </Col>
                        <Col md={12} xs={12}>
                            <FormGroup>
                                <Label >Email:</Label>
                                <Input type="text" name="email" onChange={this.handleOnChange} value={email.value} />
                                {(email.isTouched && !email.isValid) && <FormText color="danger">Provide A valid Email</FormText>}
                            </FormGroup>
                        </Col>
                        <Col md={12} xs={12}>
                            <FormGroup>
                                <Label>Password:</Label>
                                <Input type="password" name="password" onChange={this.handleOnChange} value={password.value} />
                                {(password.isTouched && !password.isValid) && <FormText color="danger">Provide A password of minimum 6 characters</FormText>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Button onClick={this.handleSubmit} disabled={this.state.loading}>{this.state.loading ? 'Loading' : 'Add'}</Button>
                </Form>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    // auth: state.login,
    asset: state.asset,
    adminUser: state.adminUser

});

const mapDispatchToProps = dispatch => {
    return {
        addAdmin: (data) => addAdmin(data, dispatch),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(AddUser);