import capAllWords from './capAllWords';
import capFirstLetter from './capFirstLetter';
import unmaskCurrency from './unmaskCurrency';
const formatCurrency =   new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});

export  {
  formatCurrency,
  capAllWords,
  capFirstLetter,
  unmaskCurrency,
}
 
  