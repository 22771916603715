import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//datatable primereact
import { Button } from "primereact/button";

import { Badge } from "reactstrap";

import { Col, Row, CardBody, Card, Container } from "reactstrap";
import {
  getUserList,
  deactivateUser,
  getUserInfo,
  deleteUser
} from "../../../actions/admin/userActions";
import "./AssetListing.css";
import AddUser from "./AddUser";
import UserInfoModal from "./UserInfoModal";
import queryString from "query-string";

import TableWithSearch from "../../../shared/components/TableWithSearch";

class Users extends Component {
  columnProps = [
    {
      dataField: "email",
      text: "Email",
      sort: true,
      style: {
        overflow: "hidden"
      }
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      style: {
        overflow: "hidden"
      }
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: (cell, row) => {
        return (
          <Badge color={row.status === "Active" ? "success" : "danger"}>
            {row.status}
          </Badge>
        );
      }
    },
    {
      text: "Assets",
      dataField: "totalAssets",
      formatter: (cell, row) => {
        return (
          <Badge color={row.totalAssets > 0 ? "success" : "danger"}>
            {row.totalAssets}
          </Badge>
        );
      }
    }
  ];
  constructor(props) {
    super(props);
    let queryParams = new URLSearchParams(
      "offset=0&limit=10&sortBy=date&orderBy=desc"
    );
    this.state = {
      queryUser: false,
      loading: false,
      users: [],
      first: 0,
      emailInAction: "",
      status: false,
      openAddUserModel: false,
      addUserToggle: false,
      userOnSearchClear: [],
      notSearching: false,
      searchTogglesActivated: false,
      userInfoModal: false,
      userinfo: "",
      userInfoRequired: true,
      currentPage: 0,
      page: 1,
      sizePerPage: 10,
      userCount: 0,
      queryParams: queryParams.toString()
    };
  }

  componentDidMount() {
    this.queryUser();
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    let { userList } = nextProps.adminUser;
    if (prevState.queryUser) {
      return {
        loading: true,
        queryUser: false
      };
    }

    if (prevState.loading && userList !== null) {
      return {
        loading: false,
        users: userList.users,
        userCount: userList.totalCount
      };
    }
    return true;
  }

  componentWillUnmount() {
    this.setState({ userInfoRequired: false }, () => {});
  }

  //search user
  searchParticularUser = e => {
    let searchedUser = e.target.value;
    let totalUsers = this.state.userOnSearchClear;

    //resetting asset back to normal
    if (searchedUser.length < 1) {
      this.setState({
        ...this.state,
        users: totalUsers,
        notSearching: true
      });
    }
    //when there is data
    if (searchedUser.trim()) {
      this.setState(
        {
          ...this.state,
          users: [],
          searchTogglesActivated: true,
          notSearching: false
        },
        async () => {
          var totalSearchedUsers = [];
          //checking each asset
          await totalUsers.forEach((user, i) => {
            if (user.email.toLowerCase().includes(searchedUser)) {
              totalSearchedUsers.push(user);
            }
          });
          this.setState({
            ...this.state,
            users: totalSearchedUsers ? totalSearchedUsers : []
          });
        }
      );
    }
  };

  userInfo = ({ email }) => {
    this.setState({ userInfoRequired: true });
    // this.toggleUserInfoModal();
    this.props.getUserInfo(email);
  };
  action(rowData, column) {
    return (
      <div>
        {/* <Button type="button" icon="pi pi-pencil" tooltip="Edit" className="p-button-primary" onClick={() => this.editUser(rowData)} ></Button> */}
        <Button
          type="button"
          icon="pi pi-info"
          className="p-button-warning"
          onClick={() => this.userInfo({ email: rowData.email })}
        />
        {rowData.status === "Active" && (
          <Button
            type="button"
            icon="pi pi-eye"
            className="p-button-primary"
            onClick={() => this.deactivateUser({ rowData, flag: false })}
          />
        )}
        {rowData.status === "Inactive" && (
          <Button
            type="button"
            icon="pi pi-times-circle"
            className="p-button-secondary"
            onClick={() => this.deactivateUser({ rowData, flag: true })}
          />
        )}
        {!rowData.isDeleted && (
          <Button
            type="button"
            icon="pi pi-minus"
            tooltip="Delete"
            className="p-button-danger"
            onClick={() => this.deleteUser(rowData)}
          />
        )}
        {rowData.isDeleted && (
          <Button
            type="button"
            icon="pi pi-refresh"
            tooltip="Restore"
            className="p-button-success"
            onClick={() => this.deleteUser(rowData)}
          />
        )}
      </div>
    );
  }
  deactivateUser = rowData => {
    // this.handleOnClick();
    this.setState({
      userInfoRequired: false,
      emailInAction: rowData.rowData.email
    });
    this.props.deactivateUser(rowData);
    this.queryUser();
  };

  // ONHOLD: do not delete
  deleteUser = rowData => {
    this.setState({ emailInAction: rowData.email, isLoading: true });
    this.props.deleteUser(rowData);
  };

  toggleAddUserModal = () =>
    this.setState({ openAddUserModel: !this.state.openAddUserModel });
  toogleAddUser = () =>
    this.setState({ addUserToggle: !this.state.addUserToggle });

  toggleUserInfoModal = () => {
    this.setState({
      userInfoModal: !this.state.userInfoModal,
      userInfoRequired: true
    });
  };

  handleTableChange = (
    type,
    { page, sizePerPage, sortField, sortOrder, searchText, data, cellEdit }
  ) => {
    let queryParams = new URLSearchParams(this.state.queryParams);
    queryParams.set("offset", page - 1);
    queryParams.set("limit", sizePerPage);
    let currentSearchText = this.state.searchText;
    if (searchText) currentSearchText = searchText;
    if (sortField) queryParams.set("sortBy", sortField);
    if (sortOrder) queryParams.set("orderBy", sortOrder);
    if (currentSearchText) queryParams.set("search", currentSearchText);

    this.setState(
      {
        page: page,
        sizePerPage: sizePerPage,
        queryParams: queryParams.toString()
      },
      () => {
        this.queryUser();
      }
    );
  };

  queryUser = () => {
    this.setState(
      {
        queryUser: true
      },
      () => {
        let queryParams = new URLSearchParams(this.state.queryParams);
        this.props.getUserList(queryParams.toString());
      }
    );
  };
  getUserUrl = row => {
    return <Link to={`/users/${row._id}`}>User</Link>;
  };
  render() {
    const { location } = this.props;
    const searchParams = queryString.parse(this.props.location.search).search;
    return (
      <React.Fragment>
        <UserInfoModal
          isActive={this.state.userInfoModal}
          toggleUserInfoModal={this.toggleUserInfoModal}
          userinfo={this.state.userinfo}
        />
        <Container className="dashboard">
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xs="12" md={12} lg={8}>
                      <h4>Users List({this.state.users.length})</h4>
                    </Col>
                    <Col xs="5" md={12} lg={4} className="text-right">
                      <button
                        className="btn btn-primary btn-small"
                        onClick={this.toogleAddUser}
                      >
                        Add new user
                      </button>
                    </Col>
                  </Row>
                  <AddUser
                    isActive={this.state.addUserToggle}
                    getUserList={this.props.getUserList}
                    toggleFxn={this.toogleAddUser}
                  />
                  <TableWithSearch
                    keyField="_id"
                    data={this.state.users}
                    totalSize={this.state.userCount}
                    page={this.state.page}
                    sizePerPage={this.state.sizePerPage}
                    columns={this.columnProps}
                    onTableChange={this.handleTableChange}
                    enableCellEdit={false}
                    resetQueryParams={this.handleResetQueryParams}
                    location={location}
                    searchParams={searchParams}
                    searchPlaceholder={"Search user..."}
                    noDataIndicator={"No user found"}
                    showSearch={false}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.login,
  asset: state.assetList,
  adminUser: state.adminUser
});
const mapDispatchToProps = dispatch => {
  return {
    getUserList: params => getUserList(params, dispatch),
    getUserInfo: email => getUserInfo(email, dispatch),
    deactivateUser: userData => deactivateUser(userData, dispatch),
    deleteUser: userData => deleteUser(userData, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Users);
