import { put, call, takeEvery } from 'redux-saga/effects';
import { Resource } from '../api/agent';
import {
    ADD_RESOURCE,
    ADD_RESOURCE_SUCCESS,
    ADD_RESOURCE_FAILURE,
    GET_RESOURCES,
    GET_RESOURCES_SUCCESS,
    GET_RESOURCES_FAILURE,
    DELETE_RESOURCE_SUCCESS,
    DELETE_RESOURCE_FAILURE,
    DELETE_RESOURCE,
    UPDATE_RESOURCE_SUCCESS,
    UPDATE_RESOURCE_FAILURE,
    UPDATE_RESOURCE,
    UPLOAD_IMAGE,
    UPLOAD_IMAGE_SUCCESS,
    UPLOAD_IMAGE_FAILURE,
} from '../actions/actionTypes';

//  ADD_RESOURCE

function* addResource(action) {
    try {
      console.log(action.payload,"from saga");
      const payload = yield call(Resource.addResource, action.payload);
      yield put({ type: ADD_RESOURCE_SUCCESS, payload });
    } catch (error) {
        const errResp = error.response.body;
        yield put({ type: ADD_RESOURCE_FAILURE, errResp });
    }
}

export function* watchAddResource() {
    yield takeEvery(ADD_RESOURCE, addResource);
}

//  GET_RESOURCES

function* getResources(action) {
  try {
    const payload = yield call(Resource.getResources, action.payload, );
    yield put({ type: GET_RESOURCES_SUCCESS, payload });
  } catch (error) {
      const errResp = error.response.body;
      yield put({ type: GET_RESOURCES_FAILURE, errResp });
  }
}

export function* watchGetResources() {
  yield takeEvery(GET_RESOURCES, getResources);
}

//  DELETE_RESOURCE

function* deleteResource(action) {
  try {
    const payload = yield call(Resource.deleteResource, action.payload, );
    yield put({ type: DELETE_RESOURCE_SUCCESS, payload });
  } catch (error) {
      const errResp = error.response.body;
      yield put({ type: DELETE_RESOURCE_FAILURE, errResp });
  }
}

export function* watchDeleteResource() {
  yield takeEvery(DELETE_RESOURCE, deleteResource);
}

//   UPDATE_RESOURCE

function* updateResource(action) {
  try {
    console.log("in updata saga", action)
    const payload = yield call(Resource.updateResource, action.payload);
    yield put({ type: UPDATE_RESOURCE_SUCCESS, payload });
  } catch (error) {
      const errResp = error.response.body;
      yield put({ type: UPDATE_RESOURCE_FAILURE, errResp });
  }
}

export function* watchUpdateResource() {
  yield takeEvery(UPDATE_RESOURCE, updateResource);
}


//   UPLOAD_IMAGE

function* uploadImage(action) {
  try {
    console.log("in UPLOAD saga", action)
    const payload = yield call(Resource.uploadImage, action.payload);
    yield put({ type: UPLOAD_IMAGE_SUCCESS, payload });
  } catch (error) {
      // const errResp = error.response.body;
      // console.log(error);
      const errResp = {message:'Error while uploading image'};
      yield put({ type: UPLOAD_IMAGE_FAILURE, errResp });
  }
}

export function* watchUploadImage() {
  yield takeEvery(UPLOAD_IMAGE, uploadImage);
}
