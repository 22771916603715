import React from 'react';

import { Auth } from '../../api/agent';
import queryString from 'query-string';
import { connect } from 'react-redux';

class Activateuser extends React.Component {

    constructor(props) {
        

        super(props)
        this.state = ({
            loading : true
        })
        const queryParams = queryString.parse(this.props.location.search);
        Auth.activateUser({
            activation: queryParams.activation,
            email: queryParams.email
        }).then(res => {
            if (res.success){
                this.props.history.push('/login')
            }else{
                this.props.history.push('/forgotpassword');
            }
        }).catch(err=>{
            this.props.history.push('/forgotpassword');
        });
    }
    render() {
        return null;
    }
}
const mapStateToProps = state => ({
    auth: state.login
});



export default connect(mapStateToProps)(Activateuser)