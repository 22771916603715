import {
  ADD_RESOURCE_SUCCESS,
  ADD_RESOURCE_FAILURE,

  GET_RESOURCES_SUCCESS,
  GET_RESOURCES_FAILURE,

  UPDATE_RESOURCE_SUCCESS,
  UPDATE_RESOURCE_FAILURE,

  DELETE_RESOURCE_SUCCESS,
  DELETE_RESOURCE_FAILURE,

} from '../actions/actionTypes';

const initialState = {
  resources:[],
};

export default function (state = initialState, action) {
  switch (action.type) {
    
    case ADD_RESOURCE_SUCCESS:
      state.resources.push(action.payload.resource);
      return {
        resources: state.resources,
        addResourceSuccess: true,
      }
    case ADD_RESOURCE_FAILURE:
      return {
        addResourceSuccess: false,
        resources: state.resources,
        error: action.payload.message
      }
    case GET_RESOURCES_SUCCESS:
      return {
        getResourcesSuccess: true,
        resources: action.payload.resources,
      }
    case GET_RESOURCES_FAILURE:
      return {
        getResourcesSuccess: false,
        resources: state.resources,
        error: action.payload.message
      }
    case UPDATE_RESOURCE_SUCCESS:
      const updatedResource = action.payload.resource;
      const updatedIndex = state.resources.findIndex((r) => r._id === updatedResource._id );
      state.resources[updatedIndex] = updatedResource;
      return {
        updateResourceSuccess: true,
        resources: state.resources,
      }
    case UPDATE_RESOURCE_FAILURE:
      return {
        updateResourceSuccess: false,
        resources: state.resources,
        error: action.payload.message
      }
    case DELETE_RESOURCE_SUCCESS:
      const deletedResoure = action.payload.resource;
      const deletedIndex = state.resources.findIndex((r) => r._id === deletedResoure._id);
      state.resources.splice(deletedIndex,1);
      return {
        deleteResourceSuccess: true,
        resources: state.resources,
      }
    case DELETE_RESOURCE_FAILURE:
      return {
        deleteResourceSuccess: false,
        resources: state.resources,
        error: action.payload.message
      }
    default:
      return state;
  }
}