import PropTypes from 'prop-types';
import React from 'react';

const FormField = ({
  type, field, form: { touched, errors }, ...props
}) => (
  
  <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
    <input  {...field} {...props} type={type} className="form-control"/>
    {touched[field.name] &&
      errors[field.name] && <span className="form__form-group-error">{errors[field.name]}</span>}
  </div>
);

// const FormField = ({
//   type, field, form: { touched, errors }, ...props
// }) => {
//   return ( <div className="form__form-group-input-wrap form__form-group-input-wrap--error-above">
//   <input {...props} {...field} type={type}  />
//   {touched[field.name] &&
//     errors[field.name] && <span className="form__form-group-error">{errors[field.name]}</span>}
// </div>);
// }

FormField.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  value: PropTypes.string,
};

FormField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
};

export default FormField;
