import React, { PureComponent } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import { connect } from 'react-redux';

import TopbarMenuLink from './TopbarMenuLink';
import { logout } from '../../../actions/auth/authAction';


import Ava from '../../../images/ava.png';

class TopbarProfile extends PureComponent {
  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState(prevState => ({ collapse: !prevState.collapse }));
  };

  render() {
    const { collapse } = this.state;

    return (
      <div className="topbar__profile">
        <button type="button" className="topbar__avatar" onClick={this.toggle}>
          <img className="topbar__avatar-img" src={Ava} alt="avatar" />
          <p className="topbar__avatar-name">Admin</p>
          <DownIcon className="topbar__icon" />
        </button>
        {collapse && <button type="button" className="topbar__back" onClick={this.toggle} />}
        <Collapse isOpen={collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            <TopbarMenuLink title="Settings" icon="inbox" path="/settings" />
            <div className="topbar__menu-divider" />
            <TopbarMenuLink  title="Log Out" icon="exit" path="/login" onClick={this.props.logout}/>
          </div>
        </Collapse>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  auth: state.login
});

const mapDispatchToProps = dispatch => ({
  logout: () => logout(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopbarProfile);