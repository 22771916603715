import { put, call, takeEvery } from 'redux-saga/effects';
import { Log } from '../api/agent';
import {
    GET_ASSET_LOGS,
    GET_ASSET_LOGS_FAILURE,
    GET_ASSET_LOGS_SUCCESS,

    REVIEW_ASSET_LOG,
    REVIEW_ASSET_LOG_SUCCESS,
    REVIEW_ASSET_LOG_FAILURE,
    SEND_ASSET_EMAIL_SUCCESS,
    SEND_ASSET_EMAIL_FAILURE,
    SEND_ASSET_EMAIL,
} from '../actions/actionTypes';


// get asset logs
function* getAssetLogs(action) {
  try {
    const payload = yield call(Log.getAssetLogs);
    yield put({ type: GET_ASSET_LOGS_SUCCESS, payload });
  } catch (error) {
      const err = error.response.body;
      yield put({ type: GET_ASSET_LOGS_FAILURE, err });
  }
}

export function* watchGetAssetLogs() {
  yield takeEvery(GET_ASSET_LOGS, getAssetLogs);
}

//  review asset log
function* reviewAssetLog(action) {
  try {
    const payload = yield call(Log.reviewAssetLog, action.payload);
    yield put({ type: REVIEW_ASSET_LOG_SUCCESS, payload });
  } catch (error) {
      const err = error.response.body;
      yield put({ type: REVIEW_ASSET_LOG_FAILURE, err });
  }
}

export function* watchReviewAssetLog() {
  yield takeEvery(REVIEW_ASSET_LOG, reviewAssetLog);
}


//send email with correct address
function* sendAssetEmail(action) {
  try {
    const payload = yield call(Log.sendAssetEmail, action.payload);
    yield put({ type: SEND_ASSET_EMAIL_SUCCESS, payload });
  } catch (error) {
      const err = error.response.body;
      yield put({ type: SEND_ASSET_EMAIL_FAILURE, err });
  }
}

export function* watchSendAssetEmail() {
  yield takeEvery(SEND_ASSET_EMAIL, sendAssetEmail);
}

