import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getAllAssets } from "../../actions/asset";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const formatCurrency = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0
});

class AssetListing extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      assets: [],
      showUpdateModal: false,
      showDeleteModal: false,
      selectedAsset: {}
    };
  }

  componentWillMount() {
    const { assetSlug, getAllAssets } = this.props;
    this.setState({ loading: true }, () => {
      getAllAssets(assetSlug);
    });
  }

  getActions = rowData => {
    return (
      <div className="btn-group" role="group" aria-label="First group">
        <Link className="btn btn-link" to={`/assets/${rowData.slug}`}>
          View
        </Link>
      </div>
    );
  };

  componentWillReceiveProps(nextProps) {
    console.log(nextProps, "next propsssss");
    if (this.props !== nextProps) {
      const { assetProp } = nextProps;
      if (assetProp.getAllAssetSuccess === true) {
        this.setState({ loading: false, assets: assetProp.assets });
      } else if (assetProp.getAllAssetSuccess === false) {
        this.setState({
          loading: false,
          assets: assetProp.assets,
          error: assetProp.error
        });
      }
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="content-section implementation">
          <DataTable
            value={this.state.assets}
            paginator={true}
            rows={20}
            first={this.state.first}
            onPage={e => this.setState({ first: e.first })}
            style={{ textAlign: "center" }}
          >
            <Column field="asset_name" header="Asset Name" />
            <Column field="address" header="Address" />
            <Column field="parcelID" header="Parcel ID" />
            <Column
              body={body => formatCurrency.format(body.market_rent)}
              header="Market Rent"
            />
            <Column
              body={body => formatCurrency.format(body.market_value)}
              header="Market Value"
            />
            <Column
              body={body => formatCurrency.format(body.purchase_price)}
              header="Purchase Price"
            />
            <Column
              body={body => {
                if (body.purchase_date) {
                  const e = new Date(body.purchase_date);
                  return e.toLocaleDateString("en-US");
                }
                return "--";
              }}
              header="Purchase Date"
            />
            <Column
              body={this.getActions}
              header="Actions"
              style={{ textAlign: "center", width: "14em" }}
            />
          </DataTable>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  assetProp: state.asset
});

const mapDispatchToProps = dispatch => ({
  getAllAssets: data => getAllAssets(data, dispatch)
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetListing);
