import {
  GET_PARTNER_DETAIL_SUCCESS,
  GET_PARTNER_DETAIL_FAILURE,
  CHANGE_PARTNER_DEAL_STATUS_SUCCESS,
  CHANGE_PARTNER_DEAL_STATUS_FAILURE,
  FETCH_PARTNERS_LIST_SUCCESS,
  FETCH_PARTNERS_LIST_FAILURE,
  GET_PARTNER_INTEGRATIONS,
  GET_PARTNER_INTEGRATIONS_SUCCESS,
  GET_PARTNER_INTEGRATIONS_FAILURE,
  ADD_PARTNER_INTEGRATION,
  ADD_PARTNER_INTEGRATION_SUCCESS,
  ADD_PARTNER_INTEGRATION_FAILURE
} from "../actions/actionTypes";

import {
  setLoadingState,
  setSuccessResponse,
  setErrorResponse
} from "./reducerUtils";

const initialState = {
  assets: [],
  userAssets: [],
  partnerDetail: {},
  investmentInfo: {},
  integrations: {
    data: null,
    state: "INIT",
    error: null
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PARTNER_INTEGRATIONS:
      return setLoadingState(state, "integrations", "GET");
    case GET_PARTNER_INTEGRATIONS_SUCCESS:
      return setSuccessResponse(state, action, "integrations", "GET");
    case GET_PARTNER_INTEGRATIONS_FAILURE:
      return setErrorResponse(state, action, "integrations", "GET");

    case ADD_PARTNER_INTEGRATION:
      return setLoadingState(state, "integrations", "ADD");
    case ADD_PARTNER_INTEGRATION_SUCCESS:
      return setSuccessResponse(state, action, "integrations", "ADD");
    case ADD_PARTNER_INTEGRATION_FAILURE:
      return setErrorResponse(state, action, "integration", "ADD");

    case GET_PARTNER_DETAIL_SUCCESS:
      return {
        ...state,
        getPartnerDetailSuccess: true,
        partnerDetail: action.payload.partner,
        investmentInfo: state.investmentInfo
      };
    case GET_PARTNER_DETAIL_FAILURE:
      return {
        ...state,
        getPartnerDetailSuccess: false,
        partnerDetail: state.partnerDetail,
        investmentInfo: state.investmentInfo
      };
    // changePartnersApproveStatus
    case CHANGE_PARTNER_DEAL_STATUS_SUCCESS:
      return {
        ...state,
        changePartnerDetailSuccess: true,
        partnerDetail: action.payload.partner
      };
    case CHANGE_PARTNER_DEAL_STATUS_FAILURE:
      return {
        ...state,
        changePartnerDetailSuccess: false,
        partnerDetail: action.payload.partner
      };
    case FETCH_PARTNERS_LIST_SUCCESS:
      return {
        ...state,
        fetchPartnersSuccess: true,
        partnersList: action.payload.data
      };
    case FETCH_PARTNERS_LIST_FAILURE:
      return {
        ...state,
        fetchPartnersSuccess: false,
        partnersList: action.payload.data
      };
    default:
      return state;
  }
}
