import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
  Col,
  Row,
  Button,
  Container, Card, CardBody
} from 'reactstrap'

import { getAssetLogs, reviewAssetLog } from '../../actions/assetlogs';
import SendAssetEmail from './SendAssetEmail';
import AddAsset from './AddAsset';

// class SendEmail 
class AssetLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendEmailModal: false,
      showAddAsset: false,
      selectedItem: {},

      logs: [],
      userDetails: {

      },
      loading: true,

    };
  }

  componentWillMount() {
    const { getAssetLogs } = this.props;
    getAssetLogs();
  }


  getActions = (rowData) => (
    <div class="btn-group mr-2" role="group" aria-label="First group">
      <Button className="btn btn-primary" onClick={() => this.reviewAssetLog(rowData._id)}>Reviewed</Button>
      <Button className="btn btn-primary" onClick={() => this.toggleAddAsset(rowData)}>Add Asset</Button>
    </div>
  );

  reviewAssetLog = (logID) => {
    console.log(logID);
    this.props.reviewAssetLog(logID);
  }

  sendEmail = (data) => {
    console.log("send email");
  }

  toggleSendEmailModal = (item) => {
    this.setState({ sendEmailModal: !this.state.sendEmailModal, selectedItem: item })
  }

  toggleAddAsset = (data) => {
    this.setState({ showAddAsset: !this.state.showAddAsset, userDetails: data });
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps !== this.props) {
      const { assetlogProp } = nextProps;
      if (assetlogProp.getAssetLogsSuccess === true || assetlogProp.reviewAssetLogSuccess === true)
        this.setState({ loading: false, logs: assetlogProp.assetLogs });
      else if (assetlogProp.getAssetLogsSuccess === false || assetlogProp.reviewAssetLogSuccess === false)
        this.setState({ loading: false, error: null });
    }
  }

  render() {
    const { sendEmailModal, selectedItem, showAddAsset, userDetails } = this.state;
    return (
      <React.Fragment>
        <Container className="dashboard">
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <Row >
                    <Col xs="12" md={12} lg={4}>
                      <h4>Asset Logs </h4>
                    </Col>
                  </Row>
                    <DataTable value={this.state.logs} paginator={true} rows={20}
                      first={this.state.first}
                      onPage={(e) => this.setState({ first: e.first })}
                      style={{ textAlign: 'center' }}>
                      <Column field="address" header="Address" />
                      <Column field="email" header="Email" />
                      <Column body={(body) => body === true ? 'Yes' : 'No'} field="resolved" header="Resolved" />
                      <Column body={this.getActions} header="Actions" style={{ textAlign: 'center', width: '14em' }} />
                    </DataTable>
                  <SendAssetEmail modalOpen={sendEmailModal} closeModal={this.toggleSendEmailModal} selectedItem={selectedItem} />
                  <AddAsset modalOpen={showAddAsset} closeModal={this.toggleAddAsset} selectedItem={userDetails} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  assetlogProp: state.assetlog,
});
const mapDispatchToProps = dispatch => ({
  getAssetLogs: () => getAssetLogs(dispatch),
  reviewAssetLog: (logID) => reviewAssetLog(logID, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AssetLog);