import React, { Component } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import {
    Col,
    FormGroup,
    Label,
    Input,
} from 'reactstrap';
import './feedback.css'
import { replyFeedback } from '../../actions/admin/cmsActions';
const customStyles = {
    content: {
        width: '40em',
        height: '30em',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
    overlay: {
        zIndex: '5'
    },
};
class Feedbackinfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            feedback: ""
        };
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps) {
        // this.setState({})
        if (nextProps.feedback) {
            this.setState({ feedback: nextProps.feedback })
        }
        

    }
    render() {
        const { email, subject, message } = this.state.feedback;
        return (

            <Modal ariaHideApp={false}
                isOpen={this.props.isActive || false}
                style={customStyles}
                onClose={this.props.toggleFeedbackModal} center
            >
                <h4>Feedback</h4>
                <hr />
                <form>
                    <FormGroup row>
                        <Label sm={2}>From:</Label>
                        <Col sm={10}>
                            <Input type="text"  name="email" value={email} disabled={true} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={2}>Subject:</Label>
                        <Col sm={10}>
                            <Input type="text"  name="email" value={subject} disabled={true} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={2}>Message:</Label>
                        <Col sm={10}>
                    <textarea className="form-control" type="text" name="message" value={message} disabled={true} rows="6"/>
                        </Col>
                    </FormGroup>
                </form>

                <div className="btn-toolbar float-right" role="toolbar" style={{ marginTop: 1 + 'em' }}>
                    <div className="btn-group mr-2 text-right" role="group" >
                        <button className="btn btn-primary btn-sm" onClick={(e) => { e.preventDefault(); this.props.toggleFeedbackModal() }} >Cancel</button>
                    </div>
                    {/* <div className="btn-group" role="group" >
                        <button className="btn btn-danger btn-sm" onClick={this.sendReply}>Reply</button>
                    </div> */}
                </div>
            </Modal>
        );
    }

}


const mapDispatchToProps = dispatch => {
    return {
        replyFeedback: (data) => replyFeedback(data, dispatch),
    }
};
export default connect(null, mapDispatchToProps)(Feedbackinfo);