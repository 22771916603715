import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { Modal } from "reactstrap";

let initialValues = {
  address: ""
};

const validationSchema = Yup.object({
  address: Yup.string("").required("Address is required")
});

class SendAssetEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null
    };
  }

  resetForm;
  handleSubmit = e => {};

  closeModal = () => {
    this.resetForm({});
    this.props.closeModal(null);
  };

  render() {
    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
        onReset={this.closeModal}
        render={props => {
          const {
            values,
            handleSubmit,
            handleChange,
            handleReset,
            resetForm
          } = props;
          const { modalOpen } = this.props;
          const { loading } = this.state;
          // need to reset the form, when form submitted successfully
          this.resetForm = resetForm;
          // const loading = uploading || saving;
          return (
            <Modal
              isOpen={modalOpen}
              className={
                "modal-dialog--primary modal-dialog--header"
              } /* center closeOnOverlayClick={false} showCloseIcon={false} onClose={()=>{}} */
            >
              {/* <h4>Send email</h4> */}
              <div className="modal__header">
                <h4 className="bold-text  modal__title">Send email</h4>
              </div>
              <div className="modal__body">
                <form onSubmit={handleSubmit} onReset={handleReset}>
                  {/* <Row>
                  <Col md={12} xs={12}>
                    <div className="row">
                      <div className="col">
                        <div className="form-group">
                          <label htmlFor="formGroupTitleInput">Address</label>
                          <input type="text" className="form-control" id="formGroupTitleInput" placeholder="Address" name="address" value={values.title} onChange={handleChange} />
                          <div className="error-message">{touched.title && errors.title}</div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row> */}
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Correct Address
                    </span>
                    <div className="form__form-group-field">
                      <input
                        type="text"
                        className="form-control"
                        id="formGroupTitleInput"
                        placeholder="Address"
                        name="address"
                        value={values.title}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="btn-toolbar float-right" role="toolbar">
                    <div className="btn-group mr-2 text-right" role="group">
                      <button
                        className="btn btn-primary btn-sm"
                        type="reset"
                        disabled={loading}
                      >
                        Cancel
                      </button>
                    </div>
                    <div className="btn-group" role="group">
                      <button
                        className="btn btn-danger btn-sm"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? "Saving" : "Save"}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </Modal>
          );
        }}
      />
    );
  }
}

const mapStateToProps = state => ({
  resourceProp: state.resource
});

const mapDispatchToProps = dispatch => ({
  // sendAssetEmail: (data) => sendAssetEmail(data, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SendAssetEmail);
