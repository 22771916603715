import React, { Component } from "react";
import Modal from "react-responsive-modal";
import { connect } from "react-redux";
import { Formik } from "formik";
import DatePicker from "react-datepicker";

import * as Yup from "yup";

import { Row, Col } from "reactstrap";
import FileInput from "./FileInput";
import { addResource, updateResource } from "../../../actions/resource";
import "../resource-style.css";

let initialValues = {
  title: "",
  description: "",
  thumbnail: "",
  owner: "",
  type: "recommendation",
  location: "",
  expiry: "",
  url: "",
  capRate: "",
  offerDueDate: ""
};

const validationSchema = Yup.object({
  title: Yup.string("").required("Title is required"),
  description: Yup.string("").required("Description is required"),
  owner: Yup.string("").required("Owner is required"),
  url: Yup.string("").required("Link is required"),
  type: Yup.string("").required("Select type of resource"),
  thumbnail: Yup.mixed().required("Select a thumbnail"),
  location: Yup.string().when("type", {
    is: type => type === "event",
    then: Yup.string().required("Event location is required"),
    otherwise: Yup.string()
  }),
  expiry: Yup.date().when("type", {
    is: type => type === "event" || type === "recommendation",
    then: Yup.date().required("Expiry date is required"),
    otherwise: Yup.date()
  }),

  capRate: Yup.number().when("type", {
    is: type => type === "recommendation",
    then: Yup.number().required("Input cap rate"),
    otherwise: Yup.number()
  }),
  offerDueDate: Yup.date().when("type", {
    is: type => type === "recommendation",
    then: Yup.date().required("Offer due date is required"),
    otherwise: Yup.date()
  })
});

class AddResource extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      fileError: null
    };
  }

  resetForm;
  handleSubmit = e => {
    console.log(e, "eeeeeeeee");
    console.log(e.thumbnail, "submitttt");
    const update = this.props.update;
    // this.resetForm = resetForm;
    if (update) {
      //Perform updating
      console.log("update", e);
      this.setState({ loading: true, error: false });
      this.props.updateResource(e);
    } else {
      // Perform Insert
      console.log("insert", e);
      if (e.thumbnail) {
        this.setState({ loading: true, error: false });
        this.props.addResource(e);
      }
      // this.setState({fileError: })
      else {
        console.log("FILE NOT SELECTEDDDD----------------");
      }
    }
    return;
  };

  handleFileError = msg => {
    this.setState({ fileError: msg });
  };

  closeModal = () => {
    this.resetForm({});
    this.setState({ loading: false, error: null, fileError: null });
    this.props.closeModal(null);
  };

  componentWillReceiveProps(nextProps) {
    if (this.state.loading && nextProps !== this.props) {
      const resProp = nextProps.resourceProp;
      if (resProp.addResourceSuccess) {
        //close modal
        this.closeModal();
      } else {
        this.setState({ loading: false, error: resProp.error });
      }

      if (resProp.updateResourceSuccess) {
        this.closeModal();
      } else {
        this.setState({ loading: false, error: resProp.error });
      }
    }
  }

  render() {
    // initialValues = {}
    const updateData = this.props.update;
    console.log(updateData);
    if (updateData) {
      initialValues = { ...updateData };
    } else {
      initialValues = {
        title: "",
        description: "",
        thumbnail: "",
        owner: "",
        type: "recommendation",
        location: "",
        expiry: "",
        url: "",
        capRate: "",
        offerDueDate: ""
      };
    }

    return (
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={this.handleSubmit}
        onReset={this.closeModal}
        render={props => {
          const {
            values,
            errors,
            handleSubmit,
            handleChange,
            handleReset,
            handleBlur,
            setValues,
            touched,
            resetForm
          } = props;
          const { modalOpen } = this.props;
          const { loading } = this.state;
          // need to reset the form, when form submitted successfully
          this.resetForm = resetForm;
          // const loading = uploading || saving;
          return (
            <Modal
              open={modalOpen}
              center
              closeOnOverlayClick={false}
              showCloseIcon={false}
            >
              <h4>{updateData ? "Update" : "Add New"} Resource</h4>
              {/* <hr /> */}
              <p>{this.state.error}</p>
              <form onSubmit={handleSubmit} onReset={handleReset}>
                <Row>
                  <Col md={12} xs={12}>
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <label for="formGroupTitleInput">Title</label>
                          <input
                            type="text"
                            class="form-control"
                            id="formGroupTitleInput"
                            placeholder="Title"
                            name="title"
                            value={values.title}
                            onChange={handleChange}
                            handleBlur={handleBlur}
                          />
                          <div className="error-message">
                            {touched.title && errors.title}
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label for="exampleFormControlSelect1">
                            Resource type
                          </label>
                          <select
                            class="form-control"
                            id="exampleFormControlSelect1"
                            name="type"
                            value={values.type}
                            onChange={handleChange}
                          >
                            <option value="event">Event</option>
                            <option value="recommendation">
                              Recommendation
                            </option>
                            <option value="video">Video</option>
                          </select>
                          <div className="error-message">
                            {touched.type && errors.type}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col md={12} xs={12}>
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <label for="formGroupExampleInput">Description</label>
                          <textarea
                            type="text"
                            class="form-control"
                            rows="2"
                            placeholder="Description for the resource"
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                          />
                          <div className="error-message">
                            {touched.description && errors.description}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={4} lg={4}>
                    <div class="form-group">
                      <label for="formGroupTitleInput">Link</label>
                      <input
                        type="text"
                        class="form-control"
                        id="formGroupTitleInput"
                        placeholder="Link to the resource"
                        name="url"
                        value={values.url}
                        onChange={handleChange}
                      />
                      <div className="error-message">
                        {touched.url && errors.url}
                      </div>
                    </div>
                  </Col>
                  <Col md={4} lg={4}>
                    <div class="form-group">
                      <label for="formGroupTitleInput">Owner</label>
                      <input
                        type="text"
                        class="form-control"
                        id="formGroupTitleInput"
                        placeholder="Owner name"
                        name="owner"
                        value={values.owner}
                        onChange={handleChange}
                      />
                      <div className="error-message">
                        {touched.owner && errors.owner}
                      </div>
                    </div>
                  </Col>
                  <Col md={4} lg={4}>
                    <div class="form-group">
                      <label for="formGroupTitleInput">Thumbnail</label>
                      <FileInput
                        onSuccess={files => {
                          // console.log(files[0]);
                          this.setState({ fileError: null });
                          const newValues = { ...values };
                          newValues["thumbnail"] = files;
                          setValues(newValues);
                        }}
                        onError={this.handleFileError}
                        extensions={["jpg", "png", "jpeg"]}
                        multiple={false}
                      />
                      <div className="error-message">
                        {this.state.fileError ||
                          (touched.thumbnail && errors.thumbnail)}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={4} lg={4}>
                    {(values.type === "event" ||
                      values.type === "recommendation") && (
                      <div class="form-group">
                        <label for="formGroupTitleInput">Expiry Date</label>
                        <DatePicker
                          className="form-control"
                          dateFormat="dd/MM/yyyy"
                          onChange={e => {
                            console.log(e);
                            const newValues = { ...values };
                            newValues["expiry"] = e;
                            setValues(newValues);
                          }}
                          name="expiry"
                          selected={
                            values.expiry ? new Date(values.expiry) : null
                          }
                          placeholderText={"Select date"}
                          minDate={new Date()}
                        />
                        <div className="error-message">
                          {touched.expiry && errors.expiry}
                        </div>
                      </div>
                    )}
                  </Col>
                  <Col md={4} lg={4}>
                    {values.type === "recommendation" && (
                      <div class="form-group">
                        <label for="formGroupTitleInput">Cap rate</label>
                        <input
                          type="text"
                          class="form-control"
                          id="formGroupTitleInput"
                          placeholder="Enter cap rate"
                          name="capRate"
                          value={values.capRate}
                          onChange={handleChange}
                        />
                        <div className="error-message">
                          {touched.capRate && errors.capRate}
                        </div>
                      </div>
                    )}
                    {values.type === "event" && (
                      <div class="form-group">
                        <label for="formGroupTitleInput">Location</label>
                        <input
                          type="text"
                          class="form-control"
                          id="formGroupTitleInput"
                          placeholder="Enter location"
                          name="location"
                          value={values.location}
                          onChange={handleChange}
                        />
                        <div className="error-message">
                          {touched.location && errors.location}
                        </div>
                      </div>
                    )}
                  </Col>
                  <Col md={4} lg={4}>
                    {values.type === "recommendation" && (
                      <div class="form-group">
                        <label for="formGroupTitleInput">Offer Due Date</label>
                        <DatePicker
                          className="form-control"
                          dateFormat="dd/MM/yyyy"
                          onChange={e => {
                            // console.log(e);
                            const newValues = { ...values };
                            newValues["offerDueDate"] = e;
                            setValues(newValues);
                          }}
                          name="offerDueDate"
                          selected={
                            values.offerDueDate
                              ? new Date(values.offerDueDate)
                              : null
                          }
                          placeholderText={"Select offer due date"}
                          minDate={new Date()}
                        />
                        <div className="error-message">
                          {touched.offerDueDate && errors.offerDueDate}
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
                <div className="btn-toolbar float-right" role="toolbar">
                  <div className="btn-group mr-2 text-right" role="group">
                    <button
                      className="btn btn-primary btn-sm"
                      type="reset"
                      disabled={loading}
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="btn-group" role="group">
                    <button
                      className="btn btn-danger btn-sm"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? "Saving" : "Save"}
                    </button>
                  </div>
                </div>
              </form>
            </Modal>
          );
        }}
      />
    );
  }
}

const mapStateToProps = state => ({
  resourceProp: state.resource
});

const mapDispatchToProps = dispatch => ({
  addResource: data => addResource(data, dispatch),
  updateResource: data => updateResource(data, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddResource);
