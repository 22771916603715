import CKEditor from "react-ckeditor-component";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { putCMS, getCMS } from '../../actions/admin/cmsActions'
import {
    Col,
    Row,
    Button,
    FormGroup,
    Input, CardBody, Card, Container
} from 'reactstrap';
class ControlledEditor extends Component {
    constructor(props) {
        super(props);
        this.updateContent = this.updateContent.bind(this);
        this.state = {
            content: '',
            page: `faq`,
            currentContent: "",
            serverResp: ''
        }
    }
    componentDidMount() {
        this.props.getCMS()
    }

    updateContent(newContent) {
        this.setState({ content: newContent })
    }

    onChange = (evt) => {
        var newContent = evt.editor.getData();
        if (newContent) {
            this.setState({
                serverResp: '',
                currentContent: newContent
            })
        }
    }
    handleOnChange = (e) => {
        this.setState({
            ...this.state,
            serverResp: '',
            page: e.target.value
        }, () => {
            this.selectContentOption(this.state.page)
        }, () => {
        });
    }
    selectContentOption = (option) => {

        if (option === 'faq') {
            this.setState({
                currentContent:
                    this.state.content.faq
            })
        }
        if (option === 'policy') {
            this.setState({
                currentContent:
                    this.state.content.policy
            })
        }
        if (option === 'terms') {
            this.setState({
                currentContent: this.state.content.terms
            })
        }
    }

    onBlur(evt) {
    }

    handleSubmit = () => {
        const { currentContent, page } = this.state
        this.setState({ serverResp: '' });
        if (this.isFormValid) {
            this.props.putCMS({ currentContent, page })
        }
    }

    isFormValid = () => this.state.content && this.state.page

    afterPaste(evt) {
    }

    componentWillReceiveProps(nextProps) {
        let cmsContent = nextProps.adminUser.cmsContent;
        if (cmsContent) {
            this.setState({
                content: cmsContent,
                currentContent: cmsContent[this.state.page],
                serverResp: nextProps.adminUser.message
            });
        }
    }
    render() {
        return (
            <React.Fragment>
                <Container className="dashboard">
                    <Row>
                        <Col md={12}>
                            <Card>
                                <CardBody>
                                    <Row form>
                                        <Col md={12} xs={12}>
                                            <CKEditor
                                                activeClass="p10"
                                                content={this.state.currentContent}
                                                events={{
                                                    "blur": this.onBlur,
                                                    "afterPaste": this.afterPaste,
                                                    "change": this.onChange
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <hr />
                                    {this.state.serverResp &&
                                        <div className="error-msg">
                                            <p>{this.state.serverResp}</p>
                                        </div>
                                    }
                                    <Row>
                                        <Col md={4} xs={4} sm={{ align: 'right' }}>
                                            <FormGroup>
                                                <Input type="select" defaultValue={this.state.page} name="content" onChange={this.handleOnChange} >
                                                    <option value="faq" defaultChecked={this.state.page === 'faq'}>FAQs</option>
                                                    <option value="policy" defaultChecked={this.state.page === 'policy'}>Policy</option>
                                                    <option value="terms" defaultChecked={this.state.page === 'terms'}>Terms</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md={2} xs={2}>
                                            <Button style={{ align: "right" }} onClick={this.handleSubmit}>Add Content</Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    adminUser: state.adminUser
});
const mapDispatchToProps = dispatch => ({
    getCMS: () => getCMS(dispatch),
    putCMS: (data) => putCMS(data, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(ControlledEditor);
