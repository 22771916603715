import { put, call, takeEvery } from 'redux-saga/effects';
import jwt_decode from 'jwt-decode';
import { Auth, setAuthToken } from '../api/agent';
import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    SIGNUP_REQUEST,
    SIGNUP_SUCCESS,
    SIGNUP_FAILURE,
    GOOGLE_LOGIN_REQUEST,
    GOOGLE_LOADER_START,
    GOOGLE_LOADER_END,

    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE,
    FORGOT_PASSWORD_REQUEST,

    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    RESET_PASSWORD_REQUEST,
    GET_USER_DETAIL,
    SET_CURRENT_USER_DETAIL,
    UPDATE_USER_DETAIL_SUCCESS,
    UPDATE_USER_DETAIL_FAILURE,
    UPDATE_USER_DETAIL,
    SET_CURRENT_USER,
} from '../actions/auth/authActionTypes';

function* login(userData) {
    try {
        const payload = yield call(Auth.login, userData.payload);
        setAuthToken(payload.token)
        localStorage.setItem('token', payload.token)
        const decoded = jwt_decode(payload.token);
        yield put({ type: LOGIN_SUCCESS, payload: decoded });
    } catch (err) {
        /* 
        *TODO:to check whether it'a server error or validation error
        *and put the action respectively
        */
        const errResp = err.response.body;
        yield put({ type: LOGIN_FAILURE, errResp });
    }
}

export function* watchLogin() {
    yield takeEvery(LOGIN_REQUEST, login);
}

function* signup(userData) {
    try {
        const payload = yield call(Auth.signup, userData.payload);
        
        yield put({ type: SIGNUP_SUCCESS, payload });
    } catch (err) {
        const errResp = err.response.body;
        yield put({ type: SIGNUP_FAILURE, errResp });
    }
}

export function* watchSignup() {
    yield takeEvery(SIGNUP_REQUEST, signup);
}

function* googleLogin(action) {
    try {
        //to show loader
        // yield put({type:SIGNUP_REQUEST})
        yield put({ type: GOOGLE_LOADER_START });
        const payload = yield call(Auth.googleLogin, action.payload);
        
        setAuthToken(payload.token)
        localStorage.setItem('token', payload.token);
        const decoded = jwt_decode(payload.token);
        
        yield put({ type: GOOGLE_LOADER_END });
        yield put({ type: LOGIN_SUCCESS, payload: decoded });
    } catch (err) {
        // 
        //to disable loader
        // yield put({type:GOOGLE_SIGNUP_FAILURE})
        const errResp = err.response.body;
        yield put({ type: LOGIN_FAILURE, errResp });
    }
}

export function* watchGoogleLogin() {
    yield takeEvery(GOOGLE_LOGIN_REQUEST, googleLogin);
}

export function* forgotpassword(userData) {
    try {
        const payload = yield call(Auth.forgotpassword, userData.payload);
        yield put({ type: FORGOT_PASSWORD_SUCCESS, payload });
    } catch (error) {
        const errResp = error.response.body;
        yield put({ type: FORGOT_PASSWORD_FAILURE, errResp });
    }
}

export function* watchForgotPassword() {
    yield takeEvery(FORGOT_PASSWORD_REQUEST, forgotpassword)
}

export function* resetpassword(userData) {
    try {
        const payload = yield call(Auth.resetpassword, userData.payload);
        yield put({ type: RESET_PASSWORD_SUCCESS, payload });
    } catch (error) {
        const errResp = error.response.body;
        yield put({ type: RESET_PASSWORD_FAILURE, errResp });
    }
}

export function* watchResetPassword() {
    yield takeEvery(RESET_PASSWORD_REQUEST, resetpassword)
}

export function* userDetail() {
    try {
        const payload = yield call(Auth.userDetail);
        
        yield put({ type: SET_CURRENT_USER_DETAIL, payload: payload.data });
    } catch (error) {
        const err = error.response.body;
        yield put({ type: LOGIN_FAILURE, err });
    }
}


export function* watchUserDetail() {
    yield takeEvery(GET_USER_DETAIL, userDetail)
}

export function* updateUserDetail(userData) {
    try {
        const payload = yield call(Auth.updateUserDetail, userData.payload);
        
        yield put({ type: SET_CURRENT_USER, payload : payload.data });
        yield put({ type: UPDATE_USER_DETAIL_SUCCESS, payload  });
    } catch (error) {
        const err = error.response.body;
        yield put({ type: UPDATE_USER_DETAIL_FAILURE, err });
    }
}

export function* watchUpdateUserDetail() {
    yield takeEvery(UPDATE_USER_DETAIL, updateUserDetail)
}