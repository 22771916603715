//admin user list

//fetching user list
export const FETCH_USER_LIST = "FETCH_USER_LIST";
export const FETCH_USER_LIST_SUCCESS = "FETCH_USER_LIST_SUCCESS";
export const FETCH_USER_LIST_FAILURE = "FETCH_USER_LIST_FAILURE";

//add user
export const ADD_USER = "ADD_USER";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAILURE = "ADD_USER_FAILURE";

//deactivate and activate
export const DEACTIVATE_USER = "DEACTIVATE_USER";
export const DEACTIVATE_USER_SUCCESS = "DEACTIVATE_USER_SUCCESS";
export const DEACTIVATE_USER_FAILURE = "DEACTIVATE_USER_FAILURE";

//delete user
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

//fetching admin list
export const FETCH_ADMIN_LIST = "FETCH_ADMIN_LIST";
export const FETCH_ADMIN_LIST_SUCCESS = "FETCH_ADMIN_LIST_SUCCESS";
export const FETCH_ADMIN_LIST_FAILURE = "FETCH_ADMIN_LIST_FAILURE";

//add admin
export const ADD_ADMIN = "ADD_ADMIN";
export const ADD_ADMIN_SUCCESS = "ADD_ADMIN_SUCCESS";
export const ADD_ADMIN_FAILURE = "ADD_ADMIN_FAILURE";

//deactivate and activate
export const DEACTIVATE_ADMIN = "DEACTIVATE_ADMIN";
export const DEACTIVATE_ADMIN_SUCCESS = "DEACTIVATE_ADMIN_SUCCESS";
export const DEACTIVATE_ADMIN_FAILURE = "DEACTIVATE_ADMIN_FAILURE";

//fetching user info
export const FETCH_USER_INFO = "FETCH_USER_INFO";
export const FETCH_USER_INFO_SUCCESS = "FETCH_USER_INFO_SUCCESS";
export const FETCH_USER_INFO_FAILURE = "FETCH_USER_INFO_FAILURE";

//admin CMS
export const GET_CMS = "GET_CMS";
export const GET_CMS_SUCCESS = "GET_CMS_SUCCESS";
export const GET_CMS_FAILURE = "GET_CMS_FAILURE";

export const PUT_CMS = "PUT_CMS";
export const PUT_CMS_SUCCESS = "PUT_CMS_SUCCESS";
export const PUT_CMS_FAILURE = "PUT_CMS_FAILURE";

//initial feedbacks
export const GET_INITIAL_FEEDBACK = "GET_INITIAL_FEEDBACK";
export const GET_INITIAL_FEEDBACK_SUCCESS = "GET_INITIAL_FEEDBACK_SUCCESS";
export const GET_INITIAL_FEEDBACK_FAILURE = "GET_INITIAL_FEEDBACK_FAILURE";

//delete feedbacks
export const DELETE_SELECTED_FEEDBACK = "DELETE_SELECTED_FEEDBACK";
export const DELETE_SELECTED_FEEDBACK_SUCCESS =
  "DELETE_SELECTED_FEEDBACK_SUCCESS";
export const DELETE_SELECTED_FEEDBACK_FAILURE =
  "DELETE_SELECTED_FEEDBACK_FAILURE";

//change feedbacks importance
export const CHANGE_FEEDBACK_IMP = "CHANGE_FEEDBACK_IMP";
export const CHANGE_FEEDBACK_IMP_SUCCESS = "CHANGE_FEEDBACK_IMP_SUCCESS";
export const CHANGE_FEEDBACK_IMP_FAILURE = "CHANGE_FEEDBACK_IMP_FAILURE";

//reply feedback
export const REPLY_FEEDBACK = "REPLY_FEEDBACK";
export const REPLY_FEEDBACK_SUCCESS = "REPLY_FEEDBACK_SUCCESS";
export const REPLY_FEEDBACK_FAILURE = "REPLY_FEEDBACK_FAILURE";

//Add contacts actions
export const TOGGLE_CONTACT_ASSET_MODAL = "TOGGLE_CONTACT_ASSET_MODAL";
export const ADD_ASSET_CONTACT = "ADD_ASSET_CONTACT";
export const ADD_ASSET_CONTACT_SUCCESS = "ADD_ASSET_CONTACT_SUCCESS";
export const ADD_ASSET_CONTACT_FAILURE = "ADD_ASSET_CONTACT_FAILURE";
export const ASSET_DETAIL_SUCCESS_UPDATE = "ASSET_DETAIL_SUCCESS_UPDATE";

//edit contacts actions
export const TOGGLE_CONTACT_EDIT_MODAL = "TOGGLE_CONTACT_EDIT_MODAL";
export const TOGGLE_CONTACT_DELETE_MODAL = "TOGGLE_CONTACT_DELETE_MODAL";
export const TOGGLE_CONTACT_DELETE_MODAL_CLOSE =
  "TOGGLE_CONTACT_DELETE_MODAL_CLOSE";
export const HANDLE_CONTACT_DELETE = "HANDLE_CONTACT_DELETE";
export const CONTACT_DELETE_SUCCESS = "CONTACT_DELETE_SUCCESS";
export const CONTACT_DELETE_FAILURE = "CONTACT_DELETE_FAILURE";

//auth action

//fileupload actions
export const TOGGLE_FILE_UPLOAD_MODAL = "TOGGLE_FILE_UPLOAD_MODAL";

//GET ACCOUNTS ACTIONS
export const GET_ACCOUNTS = "GET_ACCOUNTS";
export const GET_ACCOUNTS_SUCCESS = "GET_ACCOUNTS_SUCCESS";
export const GET_ACCOUNTS_FAILURE = "GET_ACCOUNTS_FAILUIRE";

//ADD ACCOUNT ACTIONS
export const ADD_ACCOUNT = "ADD_ACCOUNT";
export const ADD_ACCOUNT_SUCCESS = "ADD_ACCOUNT_SUCCESS";
export const ADD_ACCOUNT_FAILURE = "ADD_ACCOUNT_FAILURE";

//suggest contacts
export const SUGGEST_CONTACTS = "SUGGEST_CONTACTS";

//insurance addition
export const TOGGLE_INSURANCE_MODAL = "SUGGEST_CONTACTS";
export const INSURANCE_ADDITION = "INSURANCE_ADDITION";
export const INSURANCE_ADDITION_SUCCESS = "INSURANCE_ADDITION_SUCCESS";
export const INSURANCE_ADDITION_FAILURE = "INSURANCE_ADDITION_FAILURE";

//RESOURCES

export const ADD_RESOURCE = "ADD_RESOURCE";
export const ADD_RESOURCE_SUCCESS = "ADD_RESOURCE_SUCCESS";
export const ADD_RESOURCE_FAILURE = "ADD_RESOURCE_FAILURE";

export const GET_RESOURCES = "GET_RESOURCES";
export const GET_RESOURCES_SUCCESS = "GET_RESOURCES_SUCCESS";
export const GET_RESOURCES_FAILURE = "GET_RESOURCES_FAILURE";

export const UPDATE_RESOURCE = "UPDATE_RESOURCE";
export const UPDATE_RESOURCE_SUCCESS = "UPDATE_RESOURCE_SUCCESS";
export const UPDATE_RESOURCE_FAILURE = "UPDATE_RESOURCE_FAILURE";

export const DELETE_RESOURCE = "DELETE_RESOURCE";
export const DELETE_RESOURCE_SUCCESS = "DELETE_RESOURCE_SUCCESS";
export const DELETE_RESOURCE_FAILURE = "DELETE_RESOURCE_FAILURE";

export const GET_ASSET_LOGS = "GET_ASSET_LOGS";
export const GET_ASSET_LOGS_SUCCESS = "GET_ASSET_LOGS_SUCCESS";
export const GET_ASSET_LOGS_FAILURE = "GET_ASSET_LOGS_FAILURE";

export const REVIEW_ASSET_LOG = "REVIEW_ASSET_LOG";
export const REVIEW_ASSET_LOG_SUCCESS = "REVIEW_ASSET_LOG_SUCCESS";
export const REVIEW_ASSET_LOG_FAILURE = "REVIEW_ASSET_LOG_FAILURE";

export const SEND_ASSET_EMAIL = "SEND_ASSET_EMAIL";
export const SEND_ASSET_EMAIL_SUCCESS = "SEND_ASSET_EMAIL_SUCCESS";
export const SEND_ASSET_EMAIL_FAILURE = "SEND_ASSET_EMAIL_FAILURE";

export const ADD_ASSET = "ADD_ASSET";
export const ADD_ASSET_SUCCESS = "ADD_ASSET_SUCCESS";
export const ADD_ASSET_FAILURE = "ADD_ASSET_FAILURE";

export const GET_ALL_ASSETS = "GET_ALL_ASSETS";
export const GET_ALL_ASSETS_SUCCESS = "GET_ALL_ASSETS_SUCCESS";
export const GET_ALL_ASSETS_FAILURE = "GET_ALL_ASSETS_FAILURE";

export const CHANGE_SIDEBAR_VISIBILITY = "CHANGE_SIDEBAR_VISIBILITY";
export const CHANGE_MOBILE_SIDEBAR_VISIBILITY =
  "CHANGE_MOBILE_SIDEBAR_VISIBILITY";

export const GET_USER_ASSETS = "GET_USER_ASSETS";
export const GET_USER_ASSETS_SUCCESS = "GET_USER_ASSETS_SUCCESS";
export const GET_USER_ASSETS_FAILURE = "GET_USER_ASSETS_FAILURE";

export const GET_ASSET_DETAIL = "GET_ASSET_DETAIL";
export const GET_ASSET_DETAIL_SUCCESS = "GET_ASSET_DETAIL_SUCCESS";
export const GET_ASSET_DETAIL_FAILURE = "GET_ASSET_DETAIL_FAILURE";

//GET_BASIC_INFO
export const GET_ASSET_BASIC_INFO_SUCCESS = "GET_ASSET_BASIC_INFO_SUCCESS";
export const GET_ASSET_BASIC_INFO_FAILURE = "GET_ASSET_BASIC_INFO_FAILURE";
export const GET_ASSET_BASIC_INFO = "GET_ASSET_BASIC_INFO";

//UPDATE BASIC INFO
export const UPDATE_ASSET_BASIC_INFO = "UPDATE_ASSET_BASIC_INFO";
export const UPDATE_ASSET_BASIC_INFO_SUCCESS =
  "UPDATE_ASSET_BASIC_INFO_SUCCESS";
export const UPDATE_ASSET_BASIC_INFO_FAILURE =
  "UPDATE_ASSET_BASIC_INFO_FAILURE";

//GET_INVESTMENT_INFO
export const GET_ASSET_INVESTMENT_INFO_SUCCESS =
  "GET_ASSET_INVESTMENT_INFO_SUCCESS";
export const GET_ASSET_INVESTMENT_INFO_FAILURE =
  "GET_ASSET_INVESTMENT_INFO_FAILURE";
export const GET_ASSET_INVESTMENT_INFO = "GET_ASSET_INVESTMENT_INFO";

//UPDATE INVESTMENT INFO
export const UPDATE_ASSET_INVESTMENT_INFO_SUCCESS =
  "UPDATE_ASSET_INVESTMENT_INFO_SUCCESS";
export const UPDATE_ASSET_INVESTMENT_INFO_FAILURE =
  "UPDATE_ASSET_INVESTMENT_INFO_FAILURE";
export const UPDATE_ASSET_INVESTMENT_INFO = "UPDATE_ASSET_INVESTMENT_INFO";

//financials
export const GET_ASSET_FINANCIALS = "GET_ASSET_FINANCIALS";
export const GET_ASSET_FINANCIALS_SUCCESS = "GET_ASSET_FINANCIALS_SUCCESS";
export const GET_ASSET_FINANCIALS_FAILURE = "GET_ASSET_FINANCIALS_FAILURE";

export const ADD_FINANCIAL_TO_ASSET = "ADD_FINANCIAL_TO_ASSET";
export const ADD_FINANCIAL_TO_ASSET_SUCCESS = "ADD_FINANCIAL_TO_ASSET_SUCCESS";
export const ADD_FINANCIAL_TO_ASSET_FAILURE = "ADD_FINANCIAL_TO_ASSET_FAILURE";

export const GET_CLIENT_DETAIL = "GET_CLIENT_DETAIL";
export const GET_CLIENT_DETAIL_SUCCESS = "GET_CLIENT_DETAIL_SUCCESS";
export const GET_CLIENT_DETAIL_FAILURE = "GET_CLIENT_DETAIL_FAILURE";

export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_FAILURE = "UPLOAD_IMAGE_FAILURE";

export const GET_SUBMITTED_DEALS = "GET_SUBMITTED_DEALS";
export const GET_SUBMITTED_DEALS_SUCCESS = "GET_SUBMITTED_DEALS_SUCCESS";
export const GET_SUBMITTED_DEALS_FAILURE = "GET_SUBMITTED_DEALS_FAILURE";

export const APPROVE_DEAL = "APPROVE_DEAL";
export const APPROVE_DEAL_SUCCESS = "APPROVE_DEAL_SUCCESS";
export const APPROVE_DEAL_FAILURE = "APPROVE_DEAL_FAILURE";

export const DENY_DEAL = "DENY_DEAL";
export const DENY_DEAL_SUCCESS = "DENY_DEAL_SUCCESS";
export const DENY_DEAL_FAILURE = "DENY_DEAL_FAILURE";

export const UPDATE_MONTHLY_DEAL = "UPDATE_MONTHLY_DEAL";
export const UPDATE_MONTHLY_DEAL_SUCCESS = "UPDATE_MONTHLY_DEAL_SUCCESS";
export const UPDATE_MONTHLY_DEAL_FAILURE = "UPDATE_MONTHLY_DEAL_FAILURE";

export const GET_PARTNER_DETAIL = "GET_PARTNER_DETAIL";
export const GET_PARTNER_DETAIL_SUCCESS = "GET_PARTNER_DETAIL_SUCCESS";
export const GET_PARTNER_DETAIL_FAILURE = "GET_PARTNER_DETAIL_FAILURE";

export const CHANGE_PARTNER_DEAL_STATUS = "CHANGE_PARTNER_DEAL_STATUS";
export const CHANGE_PARTNER_DEAL_STATUS_SUCCESS =
  "CHANGE_PARTNER_DEAL_STATUS_SUCCESS";
export const CHANGE_PARTNER_DEAL_STATUS_FAILURE =
  "CHANGE_PARTNER_DEAL_STATUS_FAILURE";

export const FETCH_PARTNERS_LIST = "FETCH_PARTNERS_LIST";
export const FETCH_PARTNERS_LIST_SUCCESS = "FETCH_PARTNERS_LIST_SUCCESS";
export const FETCH_PARTNERS_LIST_FAILURE = "FETCH_PARTNERS_LIST_FAILURE";

export const ADD_PARTNER_INTEGRATION = "ADD_PARTNER_INTEGRATION";
export const ADD_PARTNER_INTEGRATION_SUCCESS =
  "ADD_PARTNER_INTEGRATION_SUCCESS";
export const ADD_PARTNER_INTEGRATION_FAILURE =
  "ADD_PARTNER_INTEGRATION_FAILURE";

export const GET_PARTNER_INTEGRATIONS = "GET_PARTNER_INTEGRATIONS";
export const GET_PARTNER_INTEGRATIONS_SUCCESS =
  "GET_PARTNER_INTEGRATIONS_SUCCESS";
export const GET_PARTNER_INTEGRATIONS_FAILURE =
  "GET_PARTNER_INTEGRATIONS_FAILURE";
